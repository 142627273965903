import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_MOVE_OUT_DETAILS',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/move-out-details-tasks`, data).then(r => get(r,'data.data.move_out_details_task')),
  reducers: {
    success: (state, { response: details }) => {
      return {
        ...state,
        moveOutDetails: {
          ...state.moveOutDetails,
          [details.id]: details,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCreateMoveDetailsPending,
} = generate.redux(creator);
