import { merge } from 'lodash';

import * as create from './create';
import * as get from './get';
import * as update from './update'
import * as upload from './upload'

export const reducers = [
  create.reducer,
  get.reducer,
  update.reducer,
  upload.reducer,
];

export const initialState = merge(
  get.initialState,
  update.initialState,
  upload.initialState,
);
