import { petscreeningVerification as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';
import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class PetscreeningVerification extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.usePetscreeningVerificationTask,
        ...selectors,
      }
    });
  }

  static label = 'PetScreening Verification';
  static slug = 'petscreening-verification';
  static icon = 'Search';
  static helpers = helpers;
  static config = {
    supportsArtifacts: true,
  };

  _flowBuilder(taskData) {
    return [
      this.screens['instructions'],
      this.screens['summary'],
    ];
  }

  /* START OVERRIDES */
  getRecommendedScreen(taskData) {
    const activeRequest = this.helpers.getActiveRequest(taskData);
    // if profile already completed show summary screen
    if (activeRequest?.status === 'complete') return this._flow[this._flow.length-1];
    return super.getRecommendedScreen(taskData);
  }

  canAccessScreen(taskData, screenSlug) {
    // allow skipping to summary screen if has completed profile
    const activeRequest = this.helpers.getActiveRequest(taskData);
    if(screenSlug === 'summary' && activeRequest?.status === 'complete') return true;
    return super.canAccessScreen(taskData, screenSlug);
  }

  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
