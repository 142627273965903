import React from 'react';
import classNames from 'classnames';

import CSS from './styles/SurveyProgress.module.scss';

export const SurveyProgress = ({ steps = [], current }) => {
  const currentIndex = steps.indexOf(current);
  return (
    <div className={CSS.train}>
      <div className={classNames(CSS.rail, { [CSS.final]: ((currentIndex+1) === steps.length) })}>
        {steps.map((step,idx) => (
          <div
            className={classNames(CSS.station, { [CSS.complete]: (idx<=steps.indexOf(current)) })}
            key={step}
          />
        ))}
      </div>
    </div>
  );
};
