import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_BUILDING_CALENDAR',
  request: (id) => request.get(`/building-reservation-tasks/${id}/calendar`).then(r => get(r,'data.data.building_calendar')),
  selector: (state, id) => get(state,`buildingCalendars.${id}`),
  reducers: {
    success: (state, { response: calendar, params: [id] }) => ({
      ...state,
      buildingCalendars: {
        ...state.buildingCalendars,
        [id]: calendar,
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useBuildingCalendar,
  usePending: useBuildingCalendarPending,
} = generate.redux(creator);
