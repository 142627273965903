import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_TRACK_TASK_CLICK',
  request: (taskId) => request.post(`/residents/personal-tasks/${taskId}/link-clicks`),
};

export const {
  action: trackTaskClick,
} = generate.redux(creator);
