import React from 'react';

import { format } from '@moved/services';

export const getRequestFields = request => {
  if(!request) return [];
  return [
    request?.utility_provider?.name && {
      label: 'Provider',
      value: `${request?.utility_provider?.name}${request?.public_grid_opt_in ? ' (via Public Grid)' : ''}`,
      icon: { symbol: 'Building', library: 'home' },
    },
    {
      label: 'Utility account number',
      value: request?.account_number ?? (request?.public_grid_opt_in && 'Pending'),
      icon: { symbol: 'File', library: 'files' },
    },
    request?.public_grid_opt_in === true && {
      label: 'Public Grid account number',
      value: request?.public_grid_account_number,
      icon: { symbol: 'File-done', library: 'files' },
    },
    {
      label: 'Confirmation number',
      value: request?.confirmation_number,
      icon: { symbol: 'File-done', library: 'files' },
    },
    request?.file_url && {
      label: 'Submitted documents',
      value: (
        <span>
          <a href={request?.file_url} target="_blank" rel="noopener noreferrer">View file</a>
        </span>
      ),
      icon: { symbol: 'Uploaded-file', library: 'files' },
    },
    request?.consent_to_share_data_with_program_provider != null && {
      label: 'Get GridRewards notifications',
      value: request?.consent_to_share_data_with_program_provider ? 'Yes' : 'No',
      icon: { symbol: 'Done-circle', library: 'code' }
    },
    request?.consent_to_share_data_with_property != null && {
      label: 'Share utility data for carbon emissions tracking',
      value: request?.consent_to_share_data_with_property ? 'Yes' : 'No',
      icon: { symbol: 'Done-circle', library: 'code' }
    },
    {
      label: 'Provided by',
      value: request?.submitted_by?.firstname ? format.fullname(request.submitted_by) : null,
      icon: { symbol: 'User', library: 'general' },
    },
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    }
  ].filter(v=>v);
};
