import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { bookMovers } from '@moved/product';
import { CompleteCard, FieldList } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { Snippet } from '../../../../snippets';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions,
} from '../../shared';

export const ConfirmLongDistance = ({screen, taskDetails, taskDefinition, moveId, origin, goToScreen, onClose }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const updatePending = taskDefinition.selectors.useUpdateMoverBookingPending();
  const resetPending = taskDefinition.selectors.useResetMoverBookingTaskPending?.();
  const pending = updatePending || resetPending;

  const isBooked = taskDefinition.isBooked(moverBooking);

  const changeSelection = () => {
    // Revert mover_booking data
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, {
      requested_pickup_date: null,
      stops: [],
      contact_email: null,
    }))
      // then reset the task (only if not direct app)
      .then(r => taskDefinition.actions.reset ? dispatch(taskDefinition.actions.reset(taskDetails.id)) : r)
      // refresh the move because reset affects MBT and MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then(r => goToScreen(taskDefinition.getStartOverScreen(r)))
      .catch(err => Notify.error(format.error(err)));
  };


  const fields = [
    ...bookMovers.getSummaryFields(moverBooking),
    !isBooked && {
      value: (
        <div
          className={classNames('btn-gray btn--small',{'loading':pending})}
          disabled={pending}
          onClick={changeSelection}
        >
          Change selections
        </div>
      )
    }
  ].filter(v=>v);

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <CompleteCard
          status={'pending'}
          title={'Gathering quotes'}
          flavor={(
            <Snippet tag={'tasks.book-movers.summary.long-distance'}>
              Thanks for submitting your information. You will be contacted about your move soon!
            </Snippet>
          )}
        >
          <FieldList list={fields} />
        </CompleteCard>
      </ScreenContent>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
    </ScreenLayout>
  )
};
