import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_PET_REQUEST',
  request: (taskID, id, data={}) => request.post(`/residents/pet-registration-tasks/${taskID}/requests/${id}/files`, data).then(r => r.data.data.pet_registration_task),
  reducers: {
    success: (state, { response: petRegistration }) => {
      return {
        ...state,
        petRegistrations: {
          ...state.petRegistrations,
          [petRegistration.id]: petRegistration,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdatePetRequestPending,
} = generate.redux(creator);

export const initialState = {};
