import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'USER_RESET_PASSWORD',
  request: (data) => request.post(`/users/password-reset`, data, {withCredentials:true}).then(response => get(response,'data.data')),
};

export const {
  action,
  reducer,
  usePending: useResetPasswordPending,
} = generate.redux(creator);

export const initialState = {};
