import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_UPDATE_BADGE_UPLOAD',
  request: (id, data={}) => request.patch(`/residents/badge-upload-tasks/${id}/badge-upload-requests`, data).then(r => get(r,'data.data.badge_upload_task')),
  reducers: {
    success: (state, { response: details }) => {
      return {
        ...state,
        moveOutDetails: {
          ...state.moveOutDetails,
          [details.id]: details,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useUpdateBadgeUploadPending,
} = generate.redux(creator);

export const initialState = {};
