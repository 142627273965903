import React from 'react';
import { get } from 'lodash';
import { DateTime } from 'luxon';

import { useUser } from '@moved/services';
import { CompleteCard, Accordion, FieldList } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { useActiveBuildingMoveTask } from '../../../contexts/BuildingMoveTaskContext';
import { ScreenLayout } from './ScreenLayout';
import { ScreenTitle } from './ScreenTitle';
import { ScreenContent } from './ScreenContent';
import { CompleteScreenActions } from './CompleteScreenActions';
import { PastRequest } from './PastRequest';
import { OnHoldArtifact } from './OnHoldArtifact';
import { ServiceProviderAd } from './ServiceProviderAd';

export const SummaryScreen = ({
  origin,
  screen,
  moveId,
  onClose,
  extraAttributes=[], // extra attributes to append to the list of field attributes
  extraContent, // arbitrary content to render at the bottom inside the complete card
  noteboxContent, // arbitrary content to render above the complete card
}) => {

  const { user } = useUser();
  const {
    activeTaskDefinition,
    activeTaskDetails,
    activeTaskSummary,
    activeBuildingMoveTask,
  } = useActiveBuildingMoveTask();

  const { helpers, slug: taskType, getStatusCopy } = activeTaskDefinition;

  // fetch necessary records
  const artifacts = helpers.getArtifacts(activeTaskSummary) || [];
  const activeArtifact = artifacts[0];
  const overrides = helpers.getOverrides(activeTaskSummary) || [];
  const activeOverride = overrides[0];
  const activeRequest = helpers.getActiveRequest(activeTaskDetails);

  // display decision helpers
  let isRequestPriority = (
    activeTaskDefinition?.config?.supportsOnHoldArtifact &&
    ['pending-approval','rejected'].includes(activeRequest?.status)
  );
  let isActiveRequestHistory = activeRequest?.status === 'cancelled';

  // disambiguate when rejected activeRequest and artifact/override are present
  // is artifact on-hold from before rejection or is rejected request replaced by artifact?
  if ((activeArtifact || activeOverride) && get(activeRequest,'status') === 'rejected') {
    const overrideOrArtifact = activeArtifact || activeOverride;
    const artifactTimestamp = overrideOrArtifact?.created_at && DateTime.fromISO(overrideOrArtifact.created_at);
    const requestTimestamp = DateTime.fromISO(activeRequest.responded_at);
    // if activeRequest timestamp is newer, show activeRequest for summary (default to this above)
    // else show artifact summary and append the activeRequest to the history if the artifact was not created by user
    if (artifactTimestamp > requestTimestamp) {
      isRequestPriority = false;
      // edge case with history (add back in the activeRequest if it didn't lead to the current artifact / override)
      isActiveRequestHistory = overrideOrArtifact?.created_by_user?.id !== user.id;
    }
  }

  const requestHistory = [
    isActiveRequestHistory && activeRequest,
    ...(helpers.getRequestHistory(activeTaskDetails) || []),
  ].filter(v=>v);

  let status, statusCopy, attributes=[], notes;
  if (activeOverride && !isRequestPriority) {
    status = 'approved';
    statusCopy = getStatusCopy({ status: 'override' }, activeTaskDetails);
    attributes = helpers.getOverrideFields(activeOverride);
  }
  else if (activeArtifact && !isRequestPriority) {
    status = 'approved';
    statusCopy = getStatusCopy({ status: 'approved' }, activeTaskDetails);
    attributes = helpers.getArtifactFields(activeArtifact);
  }
   else if (activeRequest) {
    status = get(activeRequest,'status');
    statusCopy = getStatusCopy(activeRequest, activeTaskDetails);
    attributes = helpers.getRequestFields(activeRequest);
    notes = get(activeRequest,'notes');
  }

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={activeTaskDefinition} />
      <ScreenContent screen={screen}>
        { noteboxContent }
        <CompleteCard
          status={status}
          title={(
            <Snippet tag={`tasks.${taskType}.summary.${status}.title`}>
              { get(statusCopy,'summary.title') }
            </Snippet>
          )}
          color={get(statusCopy,'summary.color')}
          flavor={(
            <Snippet tag={`tasks.${taskType}.summary.${status}.flavor`}>
              { get(statusCopy,'summary.flavor') }
            </Snippet>
          )}
          notes={notes}
          detailsTitle={get(statusCopy,'summary.detailsTitle')}
        >
          <FieldList list={[
            ...attributes,
            ...extraAttributes,
          ]} />
          { extraContent }
        </CompleteCard>

        {/* if there is a pending or rejected request, show any artifacts as on hold */}
        {['pending-approval','rejected'].includes(get(activeRequest,'status')) &&
          activeTaskDefinition.config.supportsOnHoldArtifact && (
          artifacts.map(artifact => (
            <OnHoldArtifact
              artifact={artifact}
              task={activeTaskDefinition}
              key={artifact.id}
            />
          ))
        )}

        <ServiceProviderAd
          className="marginBottom-48"
          ad={activeTaskDetails?.service_provider_ads?.at(0)}
          buildingMoveTaskId={activeBuildingMoveTask.id}
        />

        { requestHistory.length > 0 && (
          <Accordion
            panels={[
              {
                label: (<h5 className='mt-0 mb-0'>History</h5>),
                content: requestHistory.map(request => (
                  <PastRequest
                    key={request.id}
                    request={request}
                  />
                )),
              },
            ]}
          />
        )}

      </ScreenContent>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />

    </ScreenLayout>
  )
};
