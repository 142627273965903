import { getActiveRequest as defaultActiveRequest } from '../base/getActiveRequest';

export const getActiveRequest = (taskable) => {
  const activeRequest = defaultActiveRequest(taskable);
  // the requests don't have the ledger values so append them from the taskable
  return taskable && activeRequest && {
    costs_by_category: taskable?.costs_by_category ?? [],
    payments: taskable?.payments ?? [],
    remaining_unpaid_amount: taskable?.remaining_unpaid_amount ?? 0,
    total_amount_owed: taskable?.total_amount_owed ?? 0,
    total_amount_paid: taskable?.total_amount_paid ?? 0,
    ...activeRequest,
  };
};
