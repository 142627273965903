import { merge } from 'lodash';

import * as create from './create';
import * as get from './get';
import * as update from './update';

import * as cancelRequest from './cancelRequest';
import * as createRequest from './createRequest';
import * as submitRequest from './submitRequest';
import * as updateRequest from './updateRequest';

import * as cancelAppointment from './cancelAppointment';

import * as getCalendarOptions from './getCalendarOptions';

export const reducers = [
  // Key Task
  create.reducer,
  get.reducer,
  update.reducer,
  // Appointment Request
  cancelRequest.reducer,
  createRequest.reducer,
  updateRequest.reducer,
  submitRequest.reducer,
  // Appointment
  cancelAppointment.reducer,
  // Calender
  getCalendarOptions.reducer,
];

export const initialState = merge(
  get.initialState,
  getCalendarOptions.initialState,
);
