import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useNotify, userService } from '@moved/services';

import { resetPassword } from '../actions';
import { useResetPasswordPending } from '../actions/selectors';
import { SetPasswordForm } from './SetPasswordForm';

export const PasswordReset = (props) => {
  const { token } = useParams();
  const history = useHistory();
  const Notify = useNotify();
  const pending = useResetPasswordPending();

  const onComplete = (user) => {
    Notify.default(`Successfully updated your password. Welcome back, ${user.firstname}!`);
    userService.redirect(user, history);
  };

  return (
    <SetPasswordForm
      title='Reset Your Password'
      subtitle='Enter a new password below.'
      cta='Update Password'
      token={token}
      pending={pending}
      onSubmit={resetPassword}
      onComplete={onComplete}
    />
  );
}
