import React from 'react';

export const AVBConsentModal = (props) => {

  return (<>
    <h3>SECURITY DEPOSIT ELECTRONIC CONSENT</h3>
    <p style={{marginTop:'15px'}}>
      By confirming your consent below, you agree to receive all payment-related communications from AvalonBay Communities, Inc. or its designee (“Avalon,” “we,” “us,” “our”), including, but not limited to, information, documents, notices, records, and receipts via electronic delivery.
    </p>
    <p>
      Duration of Consent. This consent will be effective immediately and will remain in effect unless you or we revoke it. You may revoke your consent at any time by notifying Avalon in writing at the address provided below. Your revocation will be effective no more than three (3) business days after actual receipt by Avalon.
    </p>
    <p>
      Right to Paper Copies. You have the right to request paper delivery of any communication required by law to be provided to you in writing. We may charge a reasonable service fee for paper delivery of any communication that would otherwise have been provided to you electronically. Your revocation of consent for electronic delivery or request for paper delivery will not affect the legal effectiveness or validity of any electronic communication provided while your consent for electronic delivery was in effect.
    </p>
    <p>
      Delivery Methods. We will deliver electronic communications to you by making each communication viewable to you in a web interface known as Avalon Access. We recommend that you print a paper copy of any electronic communication for your records. You may request that we send you via email any communication previously provided for up to two (2) years after we originally provided it. You are responsible for maintaining a valid up-to-date email address on file with us and using software and hardware to receive, read, and send email. You agree to promptly inform us of any change to your email address.
    </p>
    <p>
      Hardware and Software Requirements. You understand that in order to receive electronic communications via Avalon Access, you must have internet access, a supported operating system, a supported web browser, a valid email address, and an active Avalon Access account. Supported operating systems include the following operating systems or more recent versions thereof: Windows 7, OS X High Sierra, Android 7.0, or iOS 11.0. Supported web browsers include the most recent versions of Edge, Firefox, Chrome, and Safari. If the hardware or software requirements to receive or access electronic communications from us materially change in the future, we will notify you by sending an email to the email address we have on file for you.
    </p>
    <p>
      Security. You acknowledge that the Internet is not a secure network and that communications transmitted over the Internet may be accessed by unauthorized or unintended third parties. Although we have implemented reasonable technical safeguards to protect our communication systems from unauthorized access, we cannot guarantee network security and you accept that receiving or sending communications via the Internet is at your own risk.
    </p>
    <p>
      To revoke your consent or update your email address, please write to us at:
    </p>
    <p>
      AvalonBay Communities, Inc.<br/>
      Attn: Electronic Consent Cancellation<br/>
      4040 Wilson Blvd.<br/>
      Suite 1000<br/>
      Arlington, VA 22203
    </p>
    <p>
      Please include your name, address, phone number, and account number in your correspondence. You may also update your email address from within your Avalon Access interface.
    </p>
    <p>
      Last revised: March 31, 2019
    </p>
  </>);

};
