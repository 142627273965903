import { generate, request } from '@moved/services';

const creator = {
  namespace: 'SURVEYS_UPDATE_SATISFACTION_LEVEL',
  request: (token, response) => request.get(`/residents/satisfaction-survey-responses/${token}/update/${response}`).then(r => r.data.data.satisfaction_survey_response),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
          satisfactionResponses: {
          ...state.satisfactionResponses,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateSatisfactionLevelPending,
} = generate.redux(creator);

export const initialState = {};
