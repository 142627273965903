import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  DragAndDropArea,
  DragAndDropContext,
  DragAndDropItem,
  EmptyContent,
  Icon,
} from '@moved/ui';

import { reorderTaskList } from '../actions/reorderTaskList';

import { CreateCustomTaskModal } from './CreateCustomTaskModal';
import { TaskListItem } from './TaskListItem';
import { ExploreTasksModal } from './ExploreTasksModal';

import CSS from './styles/TaskList.module.scss';

// CONSTANTS for task status strings
const STATUS = {
  TODO: 'todo',
  COMPLETE: 'complete',
};

export const TaskList = ({ taskList, title, subtitle }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const { moveId } = useParams();

  const personalTasks = useMemo(() => taskList?.personal_tasks ?? [], [taskList]);

  // we track the task list as a single list, but need to display it in two stateful sets separated by status
  // the state is needed to allow reordering to persist while async action to save the new order is in flight
  const [activeTasks, setActiveTasks] = useState(personalTasks.filter(task => task.status === STATUS.TODO));
  const [completeTasks, setCompleteTasks] = useState(personalTasks.filter(task => task.status === STATUS.COMPLETE));

  // keep the stateful lists up to date with redux changes
  useEffect(() => {
    setActiveTasks(personalTasks.filter(task => task.status === STATUS.TODO));
    setCompleteTasks(personalTasks.filter(task => task.status === STATUS.COMPLETE));
  }, [personalTasks]);

  const exploreTasks = () => {
    Modal.open(
      <ExploreTasksModal taskListId={taskList.id} moveId={moveId} />,
      { glass: true }
    );
  };

  const createCustomTask = () => {
    Modal.open(
      <CreateCustomTaskModal taskListId={taskList.id} moveId={moveId} />,
      { sondheim: true }
    );
  }

  const handleTaskReorder = ({ source, destination }) => {
    // quick exit if not a valid reorder
    if (
      destination?.index === source?.index ||
      destination?.droppableId !== source?.droppableId
    ) return;
    // build the new ordered list (combined 'todo' and 'complete')
    // and update state along the way to ensure the order is correct
    // even during async saving delay.
    const taskListArray = [
      {
        status: STATUS.TODO,
        tasks: activeTasks,
        setTasks: setActiveTasks,
      },
      {
        status: STATUS.COMPLETE,
        tasks: completeTasks,
        setTasks: setCompleteTasks,
      }
    ];
    const newOrder = taskListArray
      .map(list => {
        // for each list, update the order or pass it through if not changed
        if(source.droppableId === list.status) {
          const reorderedList = [...list.tasks];
          const [reorderedItem] = reorderedList.splice(source.index,1);
          reorderedList.splice(destination.index, 0, reorderedItem);
          list.setTasks(reorderedList);
          return reorderedList;
        }
        else return list.tasks;
      })
      .flat() // combine into a single array of tasks
      .map(task => task.id); // convert to an array of only ids

    dispatch(reorderTaskList(taskList.id, { personal_task_ids: newOrder }))
      .catch(err => Notify.error(format.error(err)));
  }

  return (
    <div className='stackVertical-24'>
      <div className='stackHorizontal-24 justify-between stackVertical--mobile'>
        <div className='stackVertical'>
          { title && (<h3 className='displayS contentPrimary'>{title}</h3>) }
          { subtitle && (<p className='labelM contentSecondary'>{subtitle}</p>) }
        </div>
        <div className='stackHorizontal-12 stackVertical--mobile'>
           <Button
            text='Create custom task'
            color='secondary'
            icon={{library:'navigation',symbol:'Plus'}}
            onClick={createCustomTask}
          />
          <Button
            text='Add personal tasks'
            color='primary'
            icon={{library:'navigation',symbol:'Plus'}}
            onClick={exploreTasks}
          />
        </div>
      </div>
      { personalTasks.length > 0 ? (
        <DragAndDropContext onDragEnd={handleTaskReorder}>
          <div className='stackVertical-28'>
            { activeTasks.length > 0 ? (
              <DragAndDropArea id='todo' type='todo' className='stackVertical'>
                { activeTasks.map((task,index) => (
                  <DragAndDropItem key={task.id} id={`${task.id}`} index={index} className={CSS.listItem}>
                    <TaskListItem taskSummary={task} taskListId={taskList.id} />
                  </DragAndDropItem>
                ))}
              </DragAndDropArea>
            ) : (
              <div className={classNames('stackVertical padding-32 items-center',CSS.congratulations)}>
                <Icon symbol='Approved' library='general' size='60px' color='green' />
                <div className='labelL contentPrimary'>Congratulations, you've completed all of your tasks!</div>
              </div>
            )}
            { completeTasks.length > 0 && (
              <div className='stackVertical-20'>
                <div className='headingS contentPrimary'>Completed</div>
                <DragAndDropArea id='complete' type='complete' className='stackVertical'>
                  { completeTasks.map((task,index) => (
                    <DragAndDropItem key={task.id} id={`${task.id}`} index={index} className={CSS.listItem}>
                      <TaskListItem taskSummary={task} taskListId={taskList.id} />
                    </DragAndDropItem>
                  ))}
                </DragAndDropArea>
              </div>
            )}
          </div>
        </DragAndDropContext>
      ) : (
        <EmptyContent
          message='You do not have any tasks.'
          description='Explore our list of suggested tasks or add a custom task to get started.'
          className='width-full paddingTop-64 paddingBottom-64'
          actions={(
            <Button
              text='Explore tasks'
              size='small'
              color='tertiary'
              icon={{library:'general',symbol:'Binocular'}}
              onClick={exploreTasks}
            />
          )}
        />
      )}
    </div>
  );
}
