import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useModal } from '@moved/services';
import {
  Button,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@moved/ui';

import { ResidentContentRenderer } from '../../common';

import { updateTaskList } from '../actions/updateTaskList';

import introContent from './assets/introContent.json';

export const IntroModal = ({ taskListId }) => {
  const Modal = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    // update the task list to set the latest progress
    dispatch(updateTaskList(taskListId, { video: 1 }));
  },[taskListId, dispatch]);

  return (
    <div style={{maxWidth:'650px'}}>
      <ModalTitle>Welcome to your personalized dashboard</ModalTitle>
      <ModalContent className='paddingTop-24 paddingBottom-24'>
        <ResidentContentRenderer content={introContent} />
      </ModalContent>
      <ModalActions>
        <Button
          text='Ok'
          onClick={Modal.close}
        />
      </ModalActions>
    </div>
  );
}
