import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_RESERVATION',
  request: (taskID,data={}) => request.post(`/move-tasks/${taskID}/building-reservation-tasks`, data).then(r => r.data.data.building_reservation_task),
  reducers: {
    success: (state, { response: reservation }) => {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          [reservation.id]: reservation,
        },
      }
    }
  }
});
