import * as create from './create';
import * as get from './get';
import * as complete from './complete';

export const reducers = [
  create.reducer,
  get.reducer,
  complete.reducer,
];

export const initialState = {
  utilities: {},
};
