import React from 'react';

import { Icon } from '@moved/ui/src/sondheim/components/Icon';
import CSS from './InputButton.module.scss';


export const InputButton = ({
  text,
  icon,
  onDelete,
}) => {
  return (
    <div className={CSS.inputButtonContainer}>
      <button
        className={CSS.inputButton}
        type='button'
      >
        <Icon
          className={CSS.buttonIcon}
          library={icon.library}
          symbol={icon.symbol}
          size='20px'
        />
        <span className='labelM contentPrimary'>{text}</span>
      </button>
      {onDelete && (
        <Icon
          library='navigation'
          symbol='Close'
          onClick={(e) => { e.stopPropagation(); onDelete() }}
          className={CSS.deleteIcon}
          size='20px'
        />
      )}
    </div>
  );
};
