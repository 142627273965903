import React from 'react';

export const getSummaryFields = taskable => {
  return (taskable.accept_consent) ? [
    {
      icon: { symbol: 'Done-circle', library: 'code', },
      label: 'I understand that setting up utilities for my apartment is my responsibility ahead of moving in.',
      value: 'I agree'
    },
  ] : [];
}
