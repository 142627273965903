import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_COI_SUBMISSION',
  request: (id) => request.get(`/residents/coi-submissions/${id}`).then(r => get(r, 'data.data.coi_submission')),
  selector: (state, id) => get(state, `coiSubmissions.${id}`),
  reducers: {
    success: (state, { response: coiSubmission }) => {
      return {
        ...state,
        coiSubmissions: {
          ...state.coiSubmissions,
          [coiSubmission.id]: coiSubmission,
        },
      }
    }
  }
}

export const {
  action,
  reducer,
  useResource: useCoiSubmission,
  usePending: useCoiSubmissionPending,
} = generate.redux(creator);
