import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_KEY_PICKUP_CALENDAR_OPTIONS',
  request: (id) => request.get(`/key-pickup-tasks/${id}/calendars`).then(r => r.data.data.building_calendars),
  selector: (state, id) => { return id ? get(state,`keyCalendars`).find(cal => cal.id === id) : get(state,'keyCalendars')},
  reducers: {
    success: (state, { response: keyCalendars }) => {
      return {
        ...state,
        keyCalendars: keyCalendars,
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useKeyCalendar,
  usePending: useKeyCalendarPending,
} = generate.redux(creator);

export const initialState = {
  keyCalendars: [],
};
