import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_BADGE_UPLOAD_REQUEST',
  request: (id, data={}) => request.post(`/residents/badge-upload-tasks/${id}/badge-upload-requests`, data).then(r => r.data.data.badge_upload_task),
  reducers: {
    success: (state, { response: badge }) => {
      return {
        ...state,
        badges: {
          ...state.badges,
          [badge.id]: badge,
        },
      }
    },
  },
};

export const {
  action,
  reducer,
  usePending: useUploadBadgeImagePending,
} = generate.redux(creator);

export const initialState = {};
