import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_PET_REGISTRATION',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/pet-registration-tasks`, data).then(r => get(r,'data.data.pet_registration_task')),
  reducers: {
    success: (state, { response: petRegistration }) => {
      return {
        ...state,
        petRegistrations: {
          ...state.petRegistrations,
          [petRegistration.id]: petRegistration,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCreatePetRegistrationPending,
} = generate.redux(creator);

export const initialState = {};
