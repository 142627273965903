import { merge } from 'lodash';

import * as getClaimCode from './getClaimCode';
import * as claimAccount from './claimAccount';
import * as deleteAccount from './deleteAccount';
import * as getPartnerBuildings from './getPartnerBuildings';
import * as resetPassword from './resetPassword';
import * as setPassword from './setPassword';
import * as registerDirect from './registerDirect';
import * as updateUser from './updateUser';

export const reducers = [
  getClaimCode.reducer,
  claimAccount.reducer,
  deleteAccount.reducer,
  getPartnerBuildings.reducer,
  resetPassword.reducer,
  setPassword.reducer,
  registerDirect.reducer,
  updateUser.reducer,
];

export const initialState = merge(
  getClaimCode.initialState,
  claimAccount.initialState,
  getPartnerBuildings.initialState,
  resetPassword.initialState,
  setPassword.initialState,
  registerDirect.initialState,
);
