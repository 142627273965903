import { DeclineEmailCoupon } from './components/DeclineEmailCoupon';

export const routes = [
  // wonky custom route to handle a specific action from an email CTA
  {
    path: `/decline-email-coupon`,
    name: 'declineCoupon',
    component: DeclineEmailCoupon,
    exact: true,
  },
];
