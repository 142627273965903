import React from 'react';
import classNames from 'classnames';

import { TagRenderer } from '../../../TagRenderer';
import CSS from './List.module.scss';

export const ListDisplay = ({ data, tool, tags }) => {
  const { items=[], heading=' ', style, columns, showHeading } = data;
  const WrapperTag = style === 'unordered' ? 'ul' : 'ol';
  return (
    <>
      { showHeading && (
        <TagRenderer as='div' className={CSS.heading} content={heading} tags={tags}/>
      )}
      <WrapperTag className={classNames(CSS.wrapper,CSS[`columns-${columns}`])}>
        { items.map((item,index) => (
          <TagRenderer as='li' key={index} className={CSS.item} content={item} tags={tags}/>
        ))}
      </WrapperTag>
    </>
  );
};
