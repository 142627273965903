import * as create from './create';
import * as get from './get';
import * as payment from './payment';

export const reducers = [
  create.reducer,
  get.reducer,
  payment.reducer,
];

export const initialState = {
  securityDeposits: {},
};
