import { get } from 'lodash';

import { UtilitiesProviders, Complete } from './components';

export const screens = {

  'utilities-confirm': {
    slug: 'confirm',
    label: 'Utilities',
    title: 'Set up utilities',
    subtitle: 'Be sure to set up your accounts prior to your move to ensure continuity of service.',
    component: UtilitiesProviders,
    isCompleted: (data) => get(data,'accept_consent'),
    maxWidth: 800,
  },
  'utilities-complete': {
    slug: 'complete',
    component: Complete,
    label: 'Complete',
    body: 'Your utilities should be ready for you when you move in!',
    isCompleted: (data) => get(data,'accept_consent'),
  },
};
