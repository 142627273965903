import React from 'react';
import { get } from 'lodash';

import { format, useModal } from '@moved/services';
import mapIcon from '../../../../dashboard/components/assets/map-icon.png';

import { useActiveMoveStep } from '../../../../dashboard';

import { KeyPickupInstructionsModal } from './KeyPickupInstructionsModal';

import CSS from './styles/KeyPickupLocation.module.scss';

export const KeyPickupLocation = ({ request }) => {
  const Modal = useModal();
  const activeMoveStep = useActiveMoveStep();

  const address = get(request, 'building_calendar.address')
  const instructions = get(request, 'building_calendar.instructions')

  if (!address && (get(instructions, 'blocks.length', 0) === 0)) return null;

  return (
    <div className={CSS.container}>
      <div className={CSS.location_data}>
        <div className={CSS.address_block}>
          <a
            href={`https://www.google.com/maps/place/?q=place_id:${get(address,'google_place_id', get(activeMoveStep,'lease.address.google_place_id'))}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className={CSS.location_map} style={{backgroundImage:`url(${mapIcon})`}}></div>
          </a>
          <div className={CSS.location_address}>
            <label className={CSS.label}>Pick Up Location</label>
            <div className={CSS.display_value}>{ address ? format.address(address) : 'Key pick up at your community' }</div>
          </div>
        </div>
          {
            (get(instructions, 'blocks.length', 0) > 0) && (
              <div className={CSS.instructions_link}>
                <button className={CSS.button} onClick={() => Modal.open(<KeyPickupInstructionsModal instructions={instructions}/>, { sondheim: true })}>View pick up instructions</button>
              </div>
            )
          }
      </div>
    </div>
  );
};
