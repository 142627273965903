import React from 'react';
import classNames from 'classnames';
import CSS from './styles/ScreenLayout.module.scss';

export const ScreenLayout = ({ children, className }) => (
  <div className={classNames(CSS.holder,className)}>
    <div className={CSS.task}>
      { children }
    </div>
  </div>
);
