import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Card, Form, FormAddress, Icon } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

import CSS from './styles/Addresses.module.scss';

export const Addresses = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();
  const move = taskDefinition.selectors.useMove(moveId);
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const pending = taskDefinition.selectors.useUpdateMoverBookingPending();

  const initialOrigin = (
    moverBooking?.stops?.at?.(0)?.address ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-out')?.lease?.address ?? // prepopulated from the lease data
    (move?.move_steps ?? []).find(step => step.type === 'move-in')?.tenant?.prior_address // prepopulated from the tenant data
  );
  const initialDestination = (
    moverBooking?.stops?.at?.(-1)?.address ?? // previously entered value
    (move?.move_steps ?? []).find(step => step.type === 'move-in')?.lease?.address // prepopulated from the lease data
  );

  const submit = (data) => {
    const stops = [
      { address: data.origin },
      { address: data.destination },
    ];
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, { stops }))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => Notify.error(format.error(err)));
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <Form
          name={'addresses-form'}
          onSubmit={submit}
        >
          <div className={classNames('stackVertical gap-32', CSS.stopsList)}>
            <div className='stackHorizontal gap-20 items-center'>
              <Card className={CSS.stopIcon}>
                <Icon
                  library='map'
                  symbol='Origin'
                />
              </Card>
              <FormAddress
                name='origin'
                label='Origin address'
                value={initialOrigin?.google_place_id ? initialOrigin : {}}
                className='width-full'
              />
            </div>
            <div className='stackHorizontal gap-20 items-center'>
              <Card className={CSS.stopIcon}>
                <Icon
                  library='map'
                  symbol='Destination'
                />
              </Card>
              <FormAddress
                name='destination'
                label='Destination address'
                value={initialDestination?.google_place_id ? initialDestination : {}}
                className='width-full'
              />
            </div>
          </div>
        </Form>
      </ScreenContent>
      <ScreenActions>
        <Button
          text='Next'
          form={`addresses-form`}
          disabled={pending}
        />
      </ScreenActions>
    </ScreenLayout>
  );
}
