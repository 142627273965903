import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_BUILDING_RESERVATION_REQUEST',
  request: (id, data={}) => request.post(`/building-reservation-tasks/${id}/requests`, data).then(r => get(r,'data.data.building_reservation_task')),
  reducers: {
    success: (state, { response: reservation }) => {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          [reservation.id]: reservation,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateRequestPending,
} = generate.redux(creator);
