import React from 'react';

import { DynamicForm } from '@moved/ui';

export const ClaimTokenRecoveryForm = ({ sendRecovery, pending }) => {

  const fields = [
    { label: 'Email', type: 'email', name: 'email', required: true },
  ];

  return (
    <>
      <p>
        Are you a current or future resident? You should have received an
        invitation email with a signup code to sign up and complete the
        tasks for your move. If you'd like the invite resent, enter your
        email address below.
      </p>
      <p className='italic'>
        Note: please make sure to use the email address you provided to
        property management.
      </p>
      <DynamicForm id="authentication-form"
        formStyle={'underline'}
        fields={fields}
        onSubmit={sendRecovery}
        disabled={pending}
      />
    </>
  );

};
