import { Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const DragAndDropArea = ({ id, type, className, activeClassName, children }) => {
  return (
    <Droppable droppableId={id} type={type}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={classNames(className,{ [activeClassName]: snapshot?.isDraggingOver })}
        >
          { children }
          { provided.placeholder }
        </div>
      )}
    </Droppable>
  );
}


DragAndDropArea.propTypes = {
  /** Unique identifier for this drop area (will be included in the dragEnd callback data) */
  id: PropTypes.string.isRequired,
  /** Optional to give this drop area a category for limiting cross area drag and drops */
  type: PropTypes.string,
  /** ClassName to be applied to the wrapper */
  className: PropTypes.string,
  /** ClassName to be applied to the wrapper if there is an active drag item over the area */
  activeClassName: PropTypes.string,
  /** Drop area children (should include a flat list of DragItems) */
  children: PropTypes.node,
};
