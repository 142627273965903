import { AddonInterests } from './addon-interests/definition'
import { BadgeUpload } from './badges/definition';
import { BookMovers } from './book-movers/definition';
// import { BookMoversDirect } from './book-movers/definition';
import { BookMoversStorage } from './book-movers-for-storage/definition';
import { Coi } from './coi/definition';
import { CableInternet } from './cable-internet/definition';
import { Deposit } from './deposit/definition';
import { Documents } from './documents/definition';
// import { FaqsDirect } from './faqs-for-direct-deprecated/definition';
import { HappycoInspection } from './happyco-inspection/definition';
import { Insurance } from './insurance/definition';
import { Keys } from './keys/definition';
import { MoveOutDetails } from './move-out-details/definition';
import { MoveOutInspection } from './move-out-inspection/definition';
import { MovingPlan } from './moving-plan/definition';
import { Orientation } from './orientation/definition';
import { PayMoveInCosts } from './pay-move-in-costs/definition';
import { PetRegistration } from './pet-registration/definition';
import { PetscreeningVerification } from './petscreening-verification/definition';
import { Reserve } from './reserve/definition';
import { Utilities } from './utilities/definition';
import { UtilitySetup } from './utility-setup/definition';
import { VehicleRegistration } from './vehicles/definition';

export const taskDefinitions = {
  [AddonInterests.slug]: AddonInterests,
  [BadgeUpload.slug]: BadgeUpload,
  [BookMovers.slug]: BookMovers,
  // [BookMoversDirect.slug]: BookMoversDirect,
  [BookMoversStorage.slug]: BookMoversStorage,
  [Coi.slug]: Coi,
  [CableInternet.slug]: CableInternet,
  [Documents.slug]: Documents,
  [Deposit.slug]: Deposit,
  // [FaqsDirect.slug]: FaqsDirect,
  [HappycoInspection.slug]: HappycoInspection,
  [Insurance.slug]: Insurance,
  [Keys.slug]: Keys,
  [MoveOutDetails.slug]: MoveOutDetails,
  [MoveOutInspection.slug]: MoveOutInspection,
  [MovingPlan.slug]: MovingPlan,
  [Orientation.slug]: Orientation,
  [PayMoveInCosts.slug]: PayMoveInCosts,
  [PetRegistration.slug]: PetRegistration,
  [PetscreeningVerification.slug]: PetscreeningVerification,
  [Reserve.slug]: Reserve,
  [Utilities.slug]: Utilities,
  [UtilitySetup.slug]: UtilitySetup,
  [VehicleRegistration.slug]: VehicleRegistration,
};
