import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_UPDATE_MOVE_OUT_DETAILS',
  request: (id, data={}) => request.patch(`/move-out-details-tasks/${id}`, data).then(r => get(r,'data.data.move_out_details_task')),
  reducers: {
    success: (state, { response: details }) => {
      return {
        ...state,
        moveOutDetails: {
          ...state.moveOutDetails,
          [details.id]: details,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useUpdateMoveDetailsPending,
} = generate.redux(creator);
