import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'DASHBOARD_GET_MARKETPLACE_ADS',
  request: (id) => request.get(`/residents/moves/${id}/partner-marketplace-ads`).then(r => get(r,'data.data.sponsored_marketplace_ads')),
  selector: (state, id) => get(state,`marketplaceAds.${id}`),
  cache: true,
  reducers: {
    success: (state, { response: marketplaceAds, params: [id] }) => ({
      ...state,
      marketplaceAds: {
        ...state.marketplaceAds,
        [id]: marketplaceAds,
      },
    }),
    failure: (state, { params: [id] }) => ({
      ...state,
      marketplaceAds: {
        ...state.marketplaceAds,
        [id]: [],
      },
    })
  }
}

export const {
  action,
  reducer,
  useResource: useMarketplaceAds,
  usePending: useMarketplaceAdsPending,
} = generate.redux(creator);

export const initialState = {
  marketplaceAds: [],
};
