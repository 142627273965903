/*
* Custom hook for invoking drawer instances leveraging the overlay
*
* `useModal` provides the following functions:
*   open, close
*
*   open: (contents, options)
*     contents:           valid jsx
*     options:            configuration object
*       onClose(result):  (function) callback function on modal close that recieves
*                         the result provided by the modal if it sends one (undefined if dismissed)
*/
import React from "react";
import { Drawer } from '@moved/ui';

import { useOverlay, useLayer } from '../contexts/OverlayContext';

export const useDrawer = () => {
  const Overlay = useOverlay();
  const Layer = useLayer();

  const open = (content, options={}) => {
    Overlay.open((
      <Drawer>{ content }</Drawer>
    ), options);
  };
  const close = Layer ? Layer.close : Overlay.closeTop;

  return { open, close };
};
