import React from 'react';
import { useDispatch } from 'react-redux';

import { DynamicForm } from '@moved/ui';
import { useNotify, useUser, format } from '@moved/services';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';
import { QuoteTicket } from './QuoteTicket';


import CSS from './styles/LongDistance.module.scss';

export const LongDistance = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const Notify = useNotify();
  const { user } = useUser();
  const dispatch = useDispatch();
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const updateTaskPending = taskDefinition.selectors.useUpdateMoverBookingPending();

  //Submit form function
  const submit = (data) => {
    dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, data))
      .then(r => dispatch(taskDefinition.actions.longDistanceEmail(moverBooking.id)).then(() => r))
      .then(nextScreen)
      .catch(err => Notify.error(format.error(err)));
  };

  const fields = [
    {
      name: 'contact_email',
      label: 'Email address',
      value: moverBooking?.contact_email ?? user?.email,
      type: 'email',
      required: true,
      className: CSS.preference_field,
    },
    {
      name: 'contact_phone',
      label: 'Phone number',
      value: moverBooking?.contact_phone,
      type: 'tel',
      required: true,
      className: CSS.preference_field,
    },
  ];

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>

        <div className='stackHorizontal gap-40 stackVertical--mobile items-start'>

          <div className={CSS.form}>

            <p className={'mb-50'}>
              We've detected that the moving distance is longer than what we
              consider a 'local' move. Confirm your contact information below
              and a Moved specialist will source and send you hand selected
              quotes from our partners who best fit your moving locations.
              Don't worry - this won't be shared with anyone but your Moved
              concierge.
            </p>

            <DynamicForm id='long-distance-form'
              fields={fields}
              formStyle={'underline'}
              onSubmit={submit}
              disabled={updateTaskPending}
            />
          </div>

          <QuoteTicket taskDefinition={taskDefinition} moveId={moveId} />

        </div>

      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor="long-distance-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full' + (updateTaskPending  ? ' loading' : '')}
          disabled={updateTaskPending}
        >
          Next
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
}
