import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { format, useNotify, useScroller } from '@moved/services';
import { DynamicForm, LoaderOverlay } from '@moved/ui';

import { Snippet } from '../../../snippets';
import { useBuilding } from '../../../common/actions/selectors';

import { createSatisfactionResponse, updateSatisfactionResponse, submitSatisfactionResponse } from '../actions';
import {
  useSatisfactionRequest,
  useSatisfactionResponse,
  useCreateSatisfactionResponsePending,
  useUpdateSatisfactionResponsePending,
  useSubmitSatisfactionResponsePending,
} from '../actions/selectors';
import { LevelHeader } from '../../shared/components';
import CSS from './styles/SatisfactionForm.module.scss';

export const SatisfactionForm = () => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const Scroller = useScroller();
  const { buildingSlug, token, level } = useParams();
  const history = useHistory();
  const building = useBuilding(buildingSlug);
  const surveyResponse = useSatisfactionResponse(token);
  const surveyRequest = useSatisfactionRequest(token);

  const createPending = useCreateSatisfactionResponsePending();
  const updatePending = useUpdateSatisfactionResponsePending();
  const submitPending = useSubmitSatisfactionResponsePending();
  const pending = createPending || updatePending || submitPending;

  useEffect(() => {
    if(surveyResponse.submitted_at) {
      return history.replace({
        pathname: `/${buildingSlug}/surveys/satisfaction/${token}/${level}/confirmation`,
      });
    }
  },[surveyResponse]); // eslint-disable-line

  // Form submit handlers
  const submitLevel = () => {
    if(get(surveyResponse,'satisfaction_level.label') !== level) {
      if(isEmpty(surveyResponse)) {
        return dispatch(createSatisfactionResponse(token, { satisfaction_level: level }))
          .catch(error => Notify.error(format.error(error)));
      }
      return dispatch(updateSatisfactionResponse(token, { satisfaction_level: level }))
        .catch(error => Notify.error(format.error(error)));
    }
  };
  const _handleSubmit = (data) => {
    if(pending) return;
    if(level === 'satisfied' && get(surveyRequest,'settings.review_site_links',[]).length > 0) {
      return dispatch(updateSatisfactionResponse(token, {...data, selected_amenity_option_ids: data.selected_amenity_option_ids || []}))
        .then(() => {
          Scroller.ref.current.scrollTo({ top: 0, behavior: 'smooth', });
          return history.push(`/${building.slug}/surveys/satisfaction/${token}/${level}/feedback`)
        })
        .catch(error => Notify.error(format.error(error)))
    } else {
      return dispatch(submitSatisfactionResponse(token, {...data, selected_amenity_option_ids: data.selected_amenity_option_ids || []}))
        .then(() => Scroller.ref.current.scrollTo({ top: 0, behavior: 'smooth', }))
        .catch(error => Notify.error(format.error(error)))
    }
  };


  // Fields to show when level is SATISFIED
  const satisfiedFields = [
    get(surveyRequest,'settings.net_promoter_score_enabled') && {
      type: 'content',
      name: 'nps_label',
      content: (
        <h3 className={'headingS marginBottom-4'}>
        <Snippet tag={`surveys.satisfaction.form.description.label.${level}`}>
          If a friend was looking for a new apartment, how likely are you to recommend living at {get(building,'name')}?
        </Snippet>
      </h3>
      ),
      className: CSS.form_title,
    },
    get(surveyRequest,'settings.net_promoter_score_enabled') && {
      type: 'score',
      name: 'net_promoter_score',
      isNumeric: true,
      required: 'Please provide a reason for your move out.',
      onChange: submitLevel,
      value: surveyResponse.net_promoter_score,
    },
    {
      type: 'content',
      name: 'description_label',
      content: (
        <h3 className={'headingS marginTop-64 marginBottom-4'}>
        <Snippet tag={`surveys.satisfaction.form.description.label.${level}`}>
          What do you like best about your experience at {building.name}?
        </Snippet>
      </h3>
      ),
      className: CSS.form_title,
    },
    {
      type: 'content',
      name: 'ui.subtitle',
      content: (
        <h4 className={'labelM contentSecondary marginBottom-12'}>Please select all that apply</h4>
      ),
    },
    {
      type: 'pills',
      name: 'selected_amenity_option_ids',
      required: 'You must select at least one.',
      isMulti: true,
      options: get(surveyRequest,'settings.amenity_options',[]).map((option) => ({
        label: option.display_name,
        value: option.id,
      })),
      onChange: submitLevel,
      value: get(surveyResponse,'selected_amenity_options',[]).map(option => option.id),
    },
  ];
  // Fields to show when level is NOT-SATISFIED
  const notSatisfiedFields = [
    {
      type: 'content',
      name: 'spoken_to_manager_label',
      content: (
        <h3 className={'headingS marginTop-32'}>
        <Snippet tag={`surveys.satisfaction.form.spoken_to_manager.label.${level}`}>
          Have you spoken to a manager on staff about this already?
        </Snippet>
      </h3>
      ),
    },
    {
      type: 'itemList',
      name: 'spoken_to_manager',
      required: 'Required.',
      isTwoCol: true,
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        }
      ],
      onChange: submitLevel,
      value: surveyResponse.spoken_to_manager,
    },
    {
      label: (<>Manager&rsquo;s name</>),
      type: 'text',
      name: 'manager_name',
      required: 'Required',
      value: surveyResponse.manager_name,
      custom: {
        hidden: {
          compare: { field: 'spoken_to_manager' },
          boolean: 'not',
          value: true,
        },
      },
    },
  ];

  const fields = [
    {
      type: 'hidden',
      name: 'satisfaction_level',
      value: level,
    },
    ...(level === 'satisfied' ? satisfiedFields : []),
    (level !== 'satisfied' && {
      type: 'content',
      name: 'description_label',
      content: (
        <h3 className={'headingS'}>
          <Snippet tag={`surveys.satisfaction.form.description.label.${level}`}>
            {level === 'not-satisfied'
            ? 'Tell us more about your experience'
            : 'How can we improve?'}
          </Snippet>
        </h3>
      ),
    }),
    {
      type: 'textarea',
      name: 'response',
      minRows: 4,
      required: 'Description is required.',
      bordered: true,
      placeholder: `Please give us a detailed description of your experience at ${building.name}.`,
      onFocus: submitLevel,
      value: surveyResponse.response,
    },
    ...(level === 'not-satisfied' ? notSatisfiedFields : []),
    {
      type: 'checkbox',
      name: 'consent_to_followup',
      value: surveyResponse.consent_to_followup,
      className: 'marginTop-32 marginBottom-32',
      label: (<>
        It is ok to contact me with follow-up questions via email
      </>),
    },
  ].filter(v => v);

  // Verbiage to render in header block
  const levelData = get(surveyRequest,'settings.satisfaction_levels',[]);
  const headerContent = levelData.map(lvl => ({
      ...lvl,
      displayName: lvl.display_name,
      description: `How do you feel about your experience so far at ${get(building,'name')}?`,
    })
  ).find(lvl => lvl.label === level);

  return (
    <>
      {pending && (<LoaderOverlay />)}
      <h1 className={'headingL marginBottom-4'}>
        <Snippet tag={'surveys.satisfaction.form.title'}>
          Tell us about your experience living here
        </Snippet>
      </h1>
      <h3 className={'labelM contentSecondary marginBottom-20'}>
        <Snippet tag={'surveys.satisfaction.feedback.subTitle'}>
          Your feedback is critical to our management team to help us understand what is important to our residents
        </Snippet>
      </h3>

      <LevelHeader {...headerContent} />
      <DynamicForm
        id={`satisfied-form`}
        fields={fields}
        formStyle={'underline'}
        onSubmit={_handleSubmit}
        autocomplete={'false'}
        disabled={pending}
      />
      <button
        className='btn-primary btn--full'
        form={'satisfied-form'}
        type='submit'
      >
        {level === 'satisfied' ? 'Next step' : 'Submit'}
      </button>
      {get(surveyRequest,'settings.admin_signature.quote') && (
        <div className={CSS.admin_box}>
          <p className={'labelM'}><em>{get(surveyRequest,'settings.admin_signature.quote')}</em></p>
          <div className={CSS.signature}>
            {get(surveyRequest,'settings.admin_signature.photo_url') && (
              <div className={CSS.headshot}>
                <img
                  src={get(surveyRequest,'settings.admin_signature.photo_url')}
                  alt={format.fullname(get(surveyRequest,'admin_signature'))}
                />
              </div>
            )}
            <div className={CSS.name_title}>
              <div className={'labelM contentPrimary'}>
                {format.fullname(get(surveyRequest,'settings.admin_signature'))}
              </div>
              <div className={'labelS contentSecondary'}>
                {get(surveyRequest,'settings.admin_signature.job_title')}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
