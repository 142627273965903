import { MarketplaceAd } from '../../../marketplace/components/MarketplaceAd';

export const TaskOffers = ({ taskDetails, moveId }) => {
  return taskDetails?.offers?.length > 0 && (
    <div className='stackVertical gap-12'>
      <div className='labelL contentPrimary paddingBottom-4 paddingTop-4'>
        Offers
      </div>
      <div className='grid-2-24'>
        { taskDetails.offers.map(offer => (
          <MarketplaceAd
            key={offer.id}
            ad={offer}
            moveId={moveId}
            origin='personal_task_drawer'
          />
        ))}
      </div>
    </div>
  );
};
