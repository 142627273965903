import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';
import { ClipboardText } from '@moved/ui';

import CSS from './tagContext.module.scss';

export const tagContextBuilder = ({
  move={},
  moveStep={},
  building={},
  lease={},
  tenant={}
}) => ({
  move_id: get(move,'id'),
  move_step_id: get(moveStep,'id'),
  move_guid: get(move,'guid'),
  property: get(building,'name'),
  property_id: get(building,'id'),
  property_guid: get(building,'guid'),
  partner: get(building,'partner.name'),
  community_email: get(building, 'community_email'),
  community_phone: get(building, 'community_phone'),
  external_resident_app_url: get(building, 'external_resident_app_url'),
  pms_label: get(building, 'pms_label'),
  lease: get(lease,'external_lease_number'),
  lease_copy: <ClipboardText
    text={get(lease,'external_lease_number')}
    icon={{ symbol: 'Duplicate', library: 'general', color: 'black'}}
    className={CSS.copy}
  />,
  lease_start: get(lease,'start_date') ? format.date(get(lease,'start_date')) : null,
  tenant_first: get(tenant,'firstname'),
  tenant_last: get(tenant,'lastname'),
  tenant_full: get(tenant,'fullname'),
  tenant_email: get(tenant,'email'),
  tenant_code: get(tenant,'external_contact_id'),
  tenant_code_copy: <ClipboardText
    text={get(tenant,'external_contact_id')}
    icon={{ symbol: 'Duplicate', library: 'general', color: 'black'}}
    className={CSS.copy}
  />,
});
