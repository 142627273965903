import React from 'react';
import classNames from 'classnames';

import { Icon } from '@moved/ui';
import { format } from '@moved/services';

import CSS from './styles/OtherPets.module.scss';

const PetPanel = ({ pet }) => {
  const statusMap = {
    'pending-approval': 'In review',
  };
  return (
    <div className={CSS.panel}>
      {pet.photo_url
        ? (
          <div
            className={CSS.splash}
            style={{ backgroundImage: `url('${pet.photo_url}')`}}
          />
        )
        : (
        <div className={CSS.splash}>
          <Icon
            symbol={format.capitalize(pet.pet_type)}
            library={'animals'}
            size={'30px'}
          />
        </div>
        )
      }
      <div className={CSS.grow}>
        <div className={CSS.name}>
          <p>{pet.name}</p>
          <span>Name</span>
        </div>
        <div className={CSS.breed}>
          <p>{pet.breed}</p>
          <span>Breed</span>
        </div>
        <div className={CSS.submitted_by}>
          <p>{format.fullname(pet.submitted_by)}</p>
          <span>Provided by</span>
        </div>
      </div>

      <div className={CSS.status}>
        <div className={classNames(CSS.pill,CSS[(pet.status || '').toLowerCase()])}>
          {format.dashToSpace(statusMap[pet.status] || pet.status)}
        </div>
      </div>
    </div>
  );
};

export const OtherPets = ({ taskDetails }) => {

  if((taskDetails?.other_pets_on_lease ?? []).length < 1) return false;

  return (
    <div className={CSS.box}>
      <div className={CSS.heading}>
        <Icon
          symbol={'Warning-1-circle'}
          library={'code'}
          className={CSS.warning_icon}
          size='20px'
        />
        <span>Other animals associated with your lease</span>
      </div>
      <div className={CSS.panels}>
        {taskDetails?.other_pets_on_lease?.map(pet => (
          <PetPanel key={`panel_${pet.id}`} pet={pet}/>
        ))}
      </div>
    </div>
  );
};
