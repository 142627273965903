import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_COI_REQUEST',
  request: (id, data={}) => request.patch(`/residents/coi-uploads/${id}`, data).then(r => r.data.data.coi_submission),
  reducers: {
    success: (state, { response: coiSubmission }) => {
      return {
        ...state,
        coiSubmissions: {
          ...state.coiSubmissions,
          [coiSubmission.id]: coiSubmission,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateCoiRequestPending,
} = generate.redux(creator);
