import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_KEY_PICKUP_REQUEST',
  request: (id, data={}) => request.post(`/key-pickup-tasks/${id}/requests`, data).then(r => r.data.data.key_pickup_task),
  reducers: {
    success: (state, { response: keyPickupTask }) => {
      return {
        ...state,
        keyPickupTasks: {
          ...state.keyPickupTasks,
          [keyPickupTask.id]: keyPickupTask,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateRequestPending,
} = generate.redux(creator);
