// TODO: replace this with @dnd-kit/core && @dnd-kit/sortable
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

export const DragAndDropContext = (props) => (
  <DragDropContext {...props} />
);

DragAndDropContext.propTypes = {
  /** Specify a handler function for when a drag event is completed */
  onDragEnd: PropTypes.func.isRequired,
  /** Context wrapper children */
  children: PropTypes.node,
};
