import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useParams, matchPath, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';
import { CSSTransition } from 'react-transition-group';

import { Icon } from '@moved/ui';
import { useNotify, useUser } from '@moved/services';

import { useBuilding } from '../../common/actions/selectors';
import { useActiveMoveStep } from '../../dashboard';
import { sendMessage } from '../actions';
import { useSendMessagePending } from '../actions/selectors';

import contactCSS from './styles/ContactForm.module.scss';
import agentImage from '../images/agentImage.jpg';

// TODO: where should this live or should this logic be distributed to the
// routes and then a route service provides this information?
const routesWithHiddenContact = [
  '/moves/:moveId/steps/:stepId/tasks/:taskId/utility-setup/:id/public-grid',
];

export const ContactForm = () => {
  const { isAuthenticated } = useUser();
  const dispatch = useDispatch();
  const { moveId } = useParams();
  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(get(activeMoveStep,'building.slug'));
  const pending = useSendMessagePending();
  const Notify = useNotify();

  // hide the contact form for a fixed list of routes (not ideal, but it works)
  const { pathname } = useLocation();
  const isHidden = routesWithHiddenContact.some(route => matchPath(pathname, route));

  /* state */
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  // the following useRef is needed to access updated values in setTimeout function
  // https://github.com/facebook/react/issues/14010
  const messageRef = useRef(message);
  messageRef.current = message;

  // place to insert the ContactForm up the tree for consistent layout
  const contactFormPortal = document.getElementById('customer-contact-form');

  // IF DISABLED QUICK EXIT
  if(
    !contactFormPortal || // must have rendered the application root
    !isAuthenticated || // must be logged in
    isHidden || // if the active path is in the hidden list
    // if move step in context then building must enable the contact form in settings
    (activeMoveStep && !get(building,'settings.contact_form_enabled'))
  ) return null;

  /* convenience functions */
  const toggleOpen = () => {
    if(open && messageRef.current === true) setTimeout(() => setMessage(''),800);
    setOpen(!open);
  };
  const updateMessage = (e) => setMessage(e.target.value);
  const submitMessage = () => {
    if(pending || !message || message.trim() === '') return false;
    dispatch(sendMessage({
      current_url: window.location.href,
      move_id: moveId,
      move_step_id: get(activeMoveStep,'id'),
      message
    })).then(success => {
      if(success) {
        setMessage(true);
        setTimeout(() => {
          if(open && messageRef.current === true) toggleOpen();
        }, 4000);
      }
      else Notify.error();
    }).catch(() => Notify.error());
  };
  // values based on CSS
  const transitionTimeout = {
    enter: parseInt(contactCSS.enterDuration) + parseInt(contactCSS.enterDelay),
    exit: parseInt(contactCSS.exitDuration) + parseInt(contactCSS.exitDelay),
  };

  return createPortal((
    <div className={contactCSS.container}>
      <div className={classNames(contactCSS.launcher,{ [contactCSS.open]: open })}
        onClick={toggleOpen}>
        <img src={agentImage} className={contactCSS.thumbnail} alt="Contact an Agent"/>
        <Icon symbol={'Close'} size={'16px'} className={contactCSS.close} color={'black'} />
      </div>
      <CSSTransition in={open} timeout={transitionTimeout} mountOnEnter={true} unmountOnExit={true}
        classNames={{enterActive:contactCSS.open,enterDone:contactCSS.open}}>
        <div className={contactCSS.contact_form}>
        { message === true ? (
          <>
            <Icon symbol={'Check'} size={'85px'} className={contactCSS.check} color={'green'} />
            <h3 className={contactCSS.title}>Successfully sent!</h3>
            <div className={contactCSS.subtitle}>
              We’ll be in touch within business hours (9am - 7pm ET)
            </div>
          </>
        ) : (
          <>
            <h3 className={contactCSS.title}>Contact us</h3>
            <div className={contactCSS.subtitle}>
              We will follow up by email (9am - 7pm ET)
            </div>
            <div className={contactCSS.body}>
              <textarea className={contactCSS.input}
                placeholder="Type a message..."
                value={message}
                onChange={updateMessage}
                readOnly={pending} />
            </div>
            <div className={contactCSS.actions}>
              <button className={classNames('btn-primary btn--full btn--small',{loading:pending})}
                type="submit"
                onClick={submitMessage}>
                Send
              </button>
            </div>
          </>
        )}
        </div>
      </CSSTransition>
    </div>
  ), contactFormPortal);
};

export const withContactForm = (Wrapped) => (props) => (<><Wrapped {...props}/><ContactForm/></>);
