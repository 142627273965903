import { merge } from 'lodash';

import * as getLandingPage from './getLandingPage';

export const reducers = [
  getLandingPage.reducer,
];

export const initialState = merge(
  getLandingPage.initialState,
);
