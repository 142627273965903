import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_RESET_PET_REGISTRATION',
  request: (id, data={}) => request.post(`/residents/pet-registration-tasks/${id}/reset`, data).then(r => r.data.data.pet_registration_task),
  reducers: {
    success: (state, { response: petRegistration }) => {
      return {
        ...state,
        petRegistrations: {
          ...state.petRegistrations,
          [petRegistration.id]: petRegistration,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useResetPetRegistrationPending,
} = generate.redux(creator);

export const initialState = {};
