import { LandingPage } from './components/LandingPage';

const routes = [
  {
    path: '/via/:landingPageSlug',
    name: 'Landing Page',
    component: LandingPage,
    allowGuest: true,
    exact: true,
  },
];

export default routes;
