import React from 'react';
import { get, isNil } from 'lodash';

import { fieldService } from '@moved/services';

import * as screenComponents from './components';
import { Snippet } from '../../../snippets';

export const screens = {

  // TODO: update this screen to use the new address modal
  // this is the last remaining dependency using field type address which is deprecated
  // this is the last remaining dependency using AddressModal which is deprecated
  forwarding: {
    slug: 'forwarding',
    label: "Forwarding address",
    title: 'Provide a forwarding address for your security deposit.',
    subtitle: 'Property management will use this address to mail you your security deposit check.',
    isCompleted: (taskable) => !isNil(get(taskable,'forwarding_address.address.google_place_id')),
    fields: (taskable = {}) => {
      const fields = [
        {
          type: 'address',
          name: 'forwarding_address',
          label: 'Forwarding address',
          required: true,
          stopIcon: true,
          simple: true,
        },
      ];
      return fieldService.hydrate(fields,taskable);
    }
  },

  reasoning: {
    slug: 'reasoning',
    label: "Reasoning",
    title: 'Provide your reason for moving out.',
    subtitle: 'This information is used to help your property improve their resident experience.',
    additionalData: {
      options: {
        action: 'getOptionsList',
        getParams: context => [get(context,'building.id')],
        selector: 'useMoveOutOptionsList',
      },
    },
    isCompleted: (taskable) => !isNil(get(taskable,'selected_move_out_reason_option_id')),
    fields: (taskable = {}, additionalData={}) => {
      const { options = [] } = additionalData;
      const fields = [
        {
          type: 'itemList',
          name: 'selected_move_out_reason_option_id',
          isNumeric: true,
          isMulti: false,
          isTwoCol: true,
          required: 'Please provide a reason for your move out.',
          options: options.map((option) => {
            return {
              value: option.id,
              label: option.label,
            };
          }),
        },
      ];
      return fieldService.hydrate(fields,taskable);
    },
  },

  feedback: {
    slug: 'feedback',
    label: "Provide feedback",
    title: 'Please provide any feedback regarding your experience living at the property.',
    subtitle: 'This information is used to help your property improve their resident experience.',
    isCompleted: (taskable) => taskable?.feedback != null,
    fields: (taskable = {}) => {
      const fields = [
        {
          type: 'textarea',
          name: 'feedback',
          label: 'Feedback',
          required: true,
        },
      ];
      return fieldService.hydrate(fields,taskable);
    }
  },

  score: {
    slug: 'score',
    label: "Net score",
    title: 'Please give us a rating.',
    subtitle: 'This information is used to help your property improve their resident experience.',
    isCompleted: (taskable) => !isNil(get(taskable,'net_promoter_score')),
    fields: (taskable = {}, additionalData={}) => {
      const partner = get(additionalData,'building.partner',{});
      const fields = [
        {
          name: 'ui.question',
          type: 'content',
          content: (
            <h3>
              <Snippet tag={'tasks.move-out-details.score.survey-label'}>
                Considering your complete experience with {partner.name},
                how likely would you be to recommend our community to a friend or colleague?
                </Snippet>
            </h3>
          ),
          disabled: true,
        },
        {
          type: 'score',
          name: 'net_promoter_score',
          isNumeric: true,
          required: 'Please provide a reason for your move out.',
        },
      ];
      return fieldService.hydrate(fields,taskable);
    }
  },

  'summary': {
    slug: 'summary',
    label: 'Summary',
    component: screenComponents.Complete,
    isCompleted: (taskable) => {
      return taskable?.net_promoter_score_enabled ?
        taskable?.net_promoter_score != null :
        taskable?.feedback != null;
    },
  },

};
