import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_PET_REGISTRATION',
  request: (id) => request.get(`/residents/pet-registration-tasks/${id}`).then(r => get(r,'data.data.pet_registration_task')),
  selector: (state, id) => get(state,`petRegistrations.${id}`),
  reducers: {
    success: (state, { response: petRegistration }) => {
      return {
        ...state,
        petRegistrations: {
          ...state.petRegistrations,
          [petRegistration.id]: petRegistration,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: usePetRegistration,
  usePending: usePetRegistrationPending,
} = generate.redux(creator);

export const initialState = {
  petRegistrations: {},
};
