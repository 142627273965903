import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_UPDATE_RENTER_INSURANCE',
  request: (id, data={}) => request.patch(`/residents/renters-insurance-tasks/${id}`, data).then(r => r.data.data.renters_insurance_task),
  reducers: {
    success: (state, { response: insuranceTask }) => ({
      ...state,
      renterInsurances: {
        ...state.renterInsurances,
        [insuranceTask.id]: insuranceTask,
      }
    }),
  }
});
