import { useMemo } from 'react';

import { action as getMoverBookingTask, useMoverBookingTask } from './getMoverBookingTask';
import { action as getMoverBooking, useMoverBooking } from '../../book-movers/actions/getMoverBooking';

// Custom action creator that combines two existing actions in a promise chain
// get the moverBookingTask and automatically get the moverBooking if it exists
export const action = (moveTaskId) => (dispatch) => dispatch(getMoverBookingTask(moveTaskId))
  .then(moverBookingTask => {
    return moverBookingTask?.mover_booking_id ?
      dispatch(getMoverBooking(moverBookingTask?.mover_booking_id)).then(() => moverBookingTask) :
      moverBookingTask
  });

export const useMergedMoverBookingTask = (id) => {
  const task = useMoverBookingTask(id);
  const booking = useMoverBooking(task?.mover_booking_id);
  const mergedTask = useMemo(
    () => ({ ...task, mover_booking: booking }),
    [task, booking]
  );
  return mergedTask;
}
