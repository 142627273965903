import { withContactForm } from '../contact';
import { BuildingMoveTaskContextProvider } from './contexts/BuildingMoveTaskContext';
import { BuildingMoveTaskFlow } from './components/BuildingMoveTaskFlow';
import { TaskRedirect } from './components/TaskRedirect';
import { TaskSummary } from './components/TaskSummary';

import { taskDefinitions } from './types';

const taskTypes = Object.keys(taskDefinitions).map(type => (new taskDefinitions[type]()));

const routes = [
  {
    path: `/tasks/:taskId(\\d+)`,
    name: 'moveTask',
    viewKey: ({taskId}) => `task-${taskId}`,
    transition: 'scale',
    component: withContactForm(BuildingMoveTaskContextProvider),
    children: [
      {
        path: `/`,
        name: 'TaskRedirect',
        component: TaskRedirect,
        exact: true,
      },
      ...taskTypes.map(({slug, screens, routes=[]}) => ({
        path: `/:taskType(${slug})`,
        name: 'taskType',
        viewKey: ({taskType}) => `task-${taskType}`,
        children: [
          {
            path: `/`,
            name: 'TaskRedirect',
            component: TaskRedirect,
            exact: true,
          },
          {
            path: `/summary`,
            name: 'TaskSummary',
            component: TaskSummary,
            exact: true,
          },
          {
            path: `/:id(\\d+)`,
            name: 'taskDetails',
            viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
            children: [
              {
                path: `/`,
                name: 'taskFlowRedirect',
                component: BuildingMoveTaskFlow,
                viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
                exact: true,
              },
              // extend with custom routes per task type (defaults to [])
              ...routes,
              // add the default screen routes pre-powered by the static screens for each task type
              {
                path: `/:screenSlug(${Object.values(screens).filter(screen => !screen.requiresContext).map(screen => screen.slug).filter(v=>v).join('|')})`,
                name: 'taskFlowScreen',
                component: BuildingMoveTaskFlow,
                viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
                exact: true,
              },
            ]
          },
        ]
      }))
    ],
  },

];

export default routes;
