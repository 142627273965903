import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CABLE_INTERNET_GET_PROVIDER_LINK',
  request: (id, providerId, origin) => request.post(`/residents/cable-internet-tasks/${id}/building-providers/${providerId}/link`,{origin}).then(r => get(r, 'data.data.cable_internet_provider_link.url')),
};

export const {
  action,
  reducer,
  usePending: useProviderLinkPending,
} = generate.redux(creator);
