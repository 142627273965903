import { DefaultPageNotFound, DashboardRedirect } from '@moved/product';

export const four04Route = {
  path: '*',
  name: 'Page not found',
  component: DefaultPageNotFound,
  allowGuest: true,
}

const routes = [
  // if logged in as consumer or guest base domain redirects to dashboard
  {
    path: '/',
    name: 'Dashboard Redirect',
    component: DashboardRedirect,
    allowGuest: true,
    exact: true,
  },
  // catchall route for logged in consumer and guests is 404 with navigation
  four04Route,
  // catchall route for logged in non-consumer is redirect to dashboard
  {
    path: '*',
    name: 'Dashboard Redirect',
    component: DashboardRedirect,
    restrict: ['cx','vendor','client'],
  },
];

export default routes;
