import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'USER_DELETE_ACCOUNT',
  request: () => request.post(`/users/current/delete-requests`).then(r => get(r,'data.data.user')),
};

export const {
  action,
  reducer,
  usePending: useDeleteAccountPending,
} = generate.redux(creator);
