import { get } from 'lodash';

import { format } from '@moved/services';

export const getSummaryFields = taskable => {
  if(!get(taskable,'payments')) return [];
  return [
    ...get(taskable,'payments',[]).map(payment => ({
      icon: { symbol: 'Money', library: 'shopping', },
      label: 'Payment',
      value: format.currency(payment.amount),
    })),
    taskable.balance && {
      icon: { symbol: 'Security-deposit', library: 'task' },
      label: 'Remaining Balance',
      value: format.currency(taskable.balance),
    },
  ].filter(v=>v);
};
