import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_ORIENTATION_REQUEST',
  request: (id, data={}) => request.post(`/residents/orientation-progress/${id}/requests`, data).then(r => r.data.data.user_orientation_progress),
  reducers: {
    success: (state, { response: orientation }) => {
      return {
        ...state,
        orientation: {
          ...state.orientation,
          [orientation.id]: orientation,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateOrientationRequestPending,
} = generate.redux(creator);
