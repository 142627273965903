import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_SPECIAL_ITEMS',
  request: () => request.get('/inventory-items/special-items').then(r => r?.data),
  selector: (state) => state?.specialItems,
  cache: true,
  reducers: {
    success: (state, { response: specialItems }) => {
      return {
        ...state,
        specialItems,
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useSpecialList,
  usePending: useSpecialListPending,
} = generate.redux(creator);

export const initialState = {
  specialItems: {},
};
