import { format } from '@moved/services';

export const getArtifactFields = (artifact) => ([
  {
    label: `${format.fullname(artifact?.tenant)} ${artifact?.building_calendar?.content?.covered_tenants_field_display_name ?? 'is picking up keys for'}`,
    value: (artifact?.covered_tenants ?? []).map(format.fullname).join(', '),
    icon: { symbol: 'Key-pickup', library: 'task' },
  },
  {
    label: 'Appointment option',
    value: artifact?.building_calendar?.content?.title ??
      artifact?.building_calendar?.name ??
      (artifact?.building_calendar?.booking_type === 'all-day' ? 'Express' : 'In person'),
    icon: { symbol: 'Outgoing-box', library: 'communication' },
  },
  {
    label: 'Appointment date',
    value: format.date(artifact?.pickup_date, 'date'),
    icon: { symbol: 'Date', library: 'code' },
  },
  artifact?.pickup_time && {
    label: 'Appointment time',
    value: format.date(artifact?.pickup_time, 'time', null, ['h:ma']),
    icon: { symbol: 'Time', library: 'code' },
  },
  artifact?.building_calendar?.address && {
    label: 'Appointment location',
    value:  format.address(artifact?.building_calendar?.address),
    icon: { symbol: 'Marker#1', library: 'map' },
  },
  artifact?.created_by_user && {
    label: 'Completed by',
    value:  format.fullname(artifact?.created_by_user),
    icon: { symbol: 'User', library: 'general' },
  },
].filter(Boolean));
