import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './DataBar.module.scss';

export const DataBar = ({ attributes=[], size='large', className }) => (
  <div className={classNames(CSS.data_bar, CSS[size], className)}>
    { attributes.map(({ key, label, value, className }) => (
      <div className={classNames(CSS.attribute, className)} key={key || (label + value)}>
        <label className={classNames(CSS.label,'labelXS contentSecondary')}>{ label }</label>
        <div className={classNames(CSS.value,'contentPrimary',(size === 'large' ? 'headingS' : 'labelL'))}>{ value }</div>
      </div>
    ))}
  </div>
);

DataBar.propTypes = {
  /** List of label/value pairs to display in the data bar */
  attributes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.node,
    value: PropTypes.node,
    className: PropTypes.string,
  })).isRequired,
  /** Size variant to use */
  size: PropTypes.oneOf(['small','large']),
  /** (optional) class name to add to the databar component */
  className: PropTypes.string,
};
