import React from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';
import { Icon, Rating } from '@moved/ui';

import CSS from './styles/MovingCompanyAd.module.scss';

export const MovingCompanyAd = ({ company, selected, onSelect }) => (
  <div className={classNames(CSS.company_card, {[CSS.selected_item]: selected})} onClick={() => onSelect(company)}>
    {/* company logo */}
    { company.image && (
      <img className={CSS.company_icon} src={`${process.env.REACT_APP_S3_ASSET_URL}/vendors/logos/${company.image}`} alt={company.name}/>
    )}
      <div className={CSS.company_content}>
        <div className={CSS.content_row}>

          {/* company name */}
          <label className={CSS.company_name}>{company.name}</label>

          {/* featured tag */}
          { company.is_featured && (
            <span className={CSS.tag}>Featured</span>
          )}

        </div>
        <div className={CSS.content_row}>
          {/* reviews stars */}
          <span className={CSS.reviews_row}>
            <Rating size='small' value={company.review_rating} precision={0.5} readOnly={true} className={CSS.company_stars} classes={{sizeSmall:CSS.stars_small}} /> {company.review_rating}
            <span className={CSS.company_reviews_count}>({company.num_reviews})</span>
            <a href={company.review_url} target='_blank' className={CSS.review_link} rel="noopener noreferrer">
              <Icon library='navigation' symbol='Up-right' size='16px' color='inherit' className={CSS.review_link_icon} />
              <span className={CSS.review_link_text}>View reviews</span>
            </a>
          </span>
          {/* review link */}
        </div>
      </div>
      {/* company rate */}
      <div className={CSS.company_rate}>
        <label>starting at</label>
        <span>{format.currency(company.min_hourly_rate_in_cents, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}/hr</span>
      </div>
      {/* checkbox */}
      <div className={CSS.item_check}>
        <Icon size='16px' symbol='Check' color='white' />
      </div>
  </div>
);
