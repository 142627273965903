import React from 'react';

import { LevelHeader } from './LevelHeader';

export const ErrorContent = () => (
  <LevelHeader
    icon={''}
    title='Sorry'
    description={<>
      We were unable to process your request.<br />
      <span className={'contentError labelM marginTop-20 marginBottom-20'}>Error: Invalid level</span>
    </>}
  />
);
