import React, { useState } from 'react';
import classNames from 'classnames';

import { useUser, userService, useNotify, useModal, format } from '@moved/services';
import { AuthenticationForm } from '@moved/product';

import CSS from '../styles/ClaimAccountVerificationModal.module.scss';

export const ClaimAccountVerificationModal = ({ claim }) => {
  const Notify = useNotify();
  const Modal = useModal();
  const { user } = useUser();
  const [pending, setPending] = useState();

  const submit = ({ email, password }) => {
    if(pending) return;
    setPending(true);
    userService.login({ email:email.toLowerCase(), password })
      .then(r => Modal.close(true))
      .catch(err => {
        setPending(false);
        Notify.error(format.error(err, {
          'not-found':'Invalid login credentials. Please double check your password.',
        }));
      });
  };

  const fields = [
    { label: 'Email', type: 'email', name: 'email', value: user.email, required: true, readOnly: true, },
    { label: 'Password', type: 'password', name: 'password', value: '', required: 'Password is required' },
  ];

  return (
    <>
      <h3 className={CSS.title}>
        Verify your account
      </h3>

      <div className={classNames('contentPrimary labelM', CSS.content)}>
        <p className='mb-35'>
          Please sign in again to link this move to your account.
        </p>
        <AuthenticationForm fields={fields} authenticate={submit} pending={pending}/>

      </div>

      <div className={CSS.footer}>

        <label
          role='button'
          tabIndex='0'
          className={classNames('btn-gray mr-15')}
          onClick={() => Modal.close(false)}
          disabled={pending}
        >
          Cancel
        </label>

        <label
          htmlFor='authentication-form-submit'
          role='button'
          tabIndex='10'
          className={classNames('btn-primary', {'loading': pending})}
          type='submit'
          disabled={pending}
        >
          Verify
        </label>

      </div>
    </>
  );
};
