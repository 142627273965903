import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ClipboardWrapper } from './ClipboardWrapper';
import { Icon } from '../../../sondheim/components/Icon';

import CSS from './ClipboardText.module.scss';

export const ClipboardText = ({ text, icon, className }) => {
  if(!text) return null;
  return (
    <ClipboardWrapper text={text}>
      <div tabIndex="0" className={classNames(CSS.text_format,{[className]:className})}>
        <span>{ text }</span>
        { icon && <Icon symbol={icon.symbol} library={icon.library} color={icon.color} size={'16px'} />}
      </div>
    </ClipboardWrapper>
  );
};

ClipboardText.propTypes = {
  /** Text to display and copy */
  text: PropTypes.string,
};
