import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useModal } from '@moved/services';
import { Button } from '../controls/Button';
import { ModalTitle, ModalContent, ModalActions } from '../Modal';

import CSS from './ConfirmationModal.module.scss';

export const ConfirmationModal = ({
  title='Are you sure?',
  copy='',
  confirmText='Confirm',
  cancelText='Cancel',
  onConfirm,
  isWarning,
}) => {

  const Modal = useModal();
  const [pending, setPending] = useState();

  const confirm = () => {
    if(pending) return;
    setPending(true);
    Promise.resolve(onConfirm?.())
      .then(Modal.close)
      .catch(() => setPending(false));
  }

  const cancel = () => !pending && Modal.close();

  return (
    <>
      <ModalTitle className={CSS.widthLimits}>{ title }</ModalTitle>

      { copy && (<ModalContent className={CSS.widthLimits}>{ copy }</ModalContent>)}

      <ModalActions>
        {cancelText && (
          <Button
            color='secondary'
            onClick={cancel}
            disabled={pending}
            text={cancelText}
          />
        )}
        <Button
          color='primary'
          showWarning={isWarning}
          onClick={confirm}
          disabled={pending}
          text={confirmText}
        />
      </ModalActions>

    </>
  )
};

ConfirmationModal.propTypes = {
  /** Title text to display in the modal */
  title: PropTypes.node,
  /** Main paragraph text to display in the modal */
  copy: PropTypes.node,
  /** CTA text to display in the confirmation button */
  confirmText: PropTypes.string,
  /** Text to display in the cancel button */
  cancelText: PropTypes.string,
  /** Callback function when confirm is selected */
  onConfirm: PropTypes.func,
  /** Whether to style this confirmation as a warning (ie 'warning' CTA) */
  isWarning: PropTypes.bool,
};
