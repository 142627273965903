import React from 'react';
import classNames from 'classnames';

import CSS from './BouncingDots.module.scss';

export const BouncingDots = ({ className }) => (
  <span className={classNames(CSS.dots,className)}>
    <span>.</span><span>.</span><span>.</span>
  </span>
);
