import { merge } from 'lodash';

import * as satisfaction from '../satisfaction/actions/reducer';

export const reducers = [
  ...satisfaction.reducers,
];

export const initialState = merge(
  satisfaction.initialState,
);
