import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_BUILDING_RESERVATION_REQUEST',
  request: (id, data={}) => request.patch(`/building-reservation-requests/${id}`, data).then(r => r.data.data.building_reservation_task),
  reducers: {
    success: (state, { response: reservation }) => {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          [reservation.id]: reservation,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateRequestPending,
} = generate.redux(creator);
