import { format } from '@moved/services';

export const getRequestFields = (request) => ([
  {
    label: `${format.fullname(request?.tenant)} ${request?.building_calendar?.content?.covered_tenants_field_display_name ?? 'is picking up keys for'}`,
    value: (request?.covered_tenants ?? []).map(format.fullname).join(', '),
    icon: { symbol: 'Key-pickup', library: 'task' },
  },
  {
    label: 'Appointment option',
    value: request?.building_calendar?.content?.title ??
      request?.building_calendar?.name ??
      (request?.building_calendar?.booking_type === 'all-day' ? 'Express' : 'In person'),
    icon: { symbol: 'Outgoing-box', library: 'communication' },
  },
  {
    label: 'Appointment date',
    value: format.date(request?.pickup_date, 'date'),
    icon: { symbol: 'Date', library: 'code' },
  },
  request?.pickup_time && {
    label: 'Appointment time',
    value: format.date(request?.pickup_time, 'time', null, ['h:ma']),
    icon: { symbol: 'Time', library: 'code' },
  },
  request?.building_calendar?.address && {
    label: 'Appointment location',
    value: format.address(request?.building_calendar?.address),
    icon: { symbol: 'Marker#1', library: 'map' },
  },
  request?.notes && {
    label: 'Reason for decline',
    value: request?.notes,
    icon: { symbol: 'File', library: 'files' },
  },
].filter(Boolean));
