import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';

import { Button, Collapsible } from '@moved/ui';

import CSS from './ExpandableCardList.module.scss';

export const ExpandableCardList = ({
  cardList,
  hideCta = 'Hide all',
  showCta = 'Show all',
}) => {
  const [expanded, setExpanded] = useState(false);

  const [firstChild, ...rest] = cardList;

  return (
    <section
      className={classNames('stackVertical', CSS.group, {
        [CSS.expanded]: expanded,
      })}
    >
      {firstChild}
      <>
        {rest.map((child, idx) => (
          <Collapsible key={idx} open={expanded}>
            {child}
          </Collapsible>
        ))}
      </>
      {rest.length > 0 && (
        <footer className={CSS.ctaContainer}>
          {expanded
            ? (
              <Button
                color='tertiary'
                text={hideCta}
                size='small'
                onClick={() => setExpanded(false)}
              />
            ) : (
              <div
                className={classNames('labelS', CSS.showCtaWrapper)}
                color={'tertiary'}
                onClick={() => setExpanded(true)}
              >
                {showCta}
              </div>
            )
          }
        </footer>
      )}
    </section>
  );
};

ExpandableCardList.propTypes = {
  /** Card elements */
  cardList: PropTypes.arrayOf(PropTypes.node).isRequired,
  /** (Optional) customize copy for the "show less" button */
  hideCta: PropTypes.string,
  /** (Optional) customize copy for the "show more" button */
  showCta: PropTypes.string,
};
