import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_SUBMIT_CABLE_INTERNET_TASK',
  request: (id) => request.post(`/residents/cable-internet-tasks/${id}/submit`).then(r => get(r, 'data.data.cable_internet_task')),
  reducers: {
    success: (state, { response: cableInternetTask }) => {
      return {
        ...state,
        cableInternetTasks: {
          ...state.cableInternetTasks,
          [cableInternetTask.id]: cableInternetTask,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useSubmitCableInternetTaskPending,
} = generate.redux(creator);
