import React, { useState } from 'react';
import classNames from 'classnames';

import { request } from '@moved/services';
import { DynamicForm } from '@moved/ui';
import { useModal, useNotify } from '@moved/services';

import CSS from './ForgotPasswordModal.module.scss';

export const ForgotPasswordModal = ({ email='' }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const [pending, setPending] = useState(false);

  const fields = [
    {
      label: 'Email',
      type: 'email',
      name: 'email',
      value: email,
      required: true,
      readOnly: pending
    },
  ];

  const submitForgotPassword = (data) => {
    if(pending) return;
    setPending(true);
    if(data.email) data.email = data.email.toLowerCase();
    request.get(`/users/password-reset`, {params: data})
      .then(() => {
        Notify.default('You will soon receive a link to update your password at the email address you specified, if it was recognized by our system.');
        Modal.close(true);
      })
      .catch(() => {
        Notify.error();
        setPending(false);
      });
  };

  return (
    <>
      <h1 className={CSS.title}>
        Lost Password?
      </h1>
      <div className={CSS.content}>
        <p>Enter your email address and we'll send instructions on how to create a new password.</p>
        <DynamicForm
          id="email-form"
          fields={fields}
          onSubmit={submitForgotPassword}
          disabled={pending}
          formStyle='underline'
        />
      </div>
      <div className={CSS.footer}>
        <label
          htmlFor="email-form-submit"
          tabIndex="0"
          role="button"
          className={classNames(CSS.btn_forgot,{[CSS.loading]:pending})}
          disabled={pending}
        >
          Send Email
        </label>
      </div>
    </>
  )
}
