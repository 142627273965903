import React from 'react';
import moment from 'moment';

import { format, useMobile } from '@moved/services';
import { Icon, PopOver } from '@moved/ui';

import { InsuranceDisclaimer } from './InsuranceDisclaimer';
import { CancellationDisclaimer } from './CancellationDisclaimer';

import CSS from './styles/BookBreakdown.module.scss';

export const BookBreakdown = ({ moverBooking, discounts = [], showCancellationPolicy, showInsuranceDisclaimer }) => {

  const moveDate = moverBooking?.requested_pickup_date;
  const lastCancellationDate = moveDate ? moment(moveDate).subtract(3,'days') : false;
  const isCancelable = lastCancellationDate ? moment().isSameOrBefore(lastCancellationDate,'day') : false;
  const selectedRate = moverBooking?.selected_partner_rate;
  const bookedServiceOrder = moverBooking?.booked_service_order;
  const isMobile = useMobile();

  // convenience functions
  const getMinimum = () => bookedServiceOrder ? bookedServiceOrder?.minimum : selectedRate?.minimum;
  const getRate = () => bookedServiceOrder ? bookedServiceOrder?.rate : selectedRate?.hourly_rate;
  const getPrice = () => bookedServiceOrder?.estimated_price;
  const calculateDiscount = (startingPrice) => discounts.reduce((runningMin, discount) => {
    switch(discount.type) {
      case 'cents':
        return Math.max(0, runningMin - discount.cents);
      case 'percent':
        return Math.max(0, runningMin - (getMinimum() * discount.percent));
      default:
        return runningMin;
    }
  }, startingPrice);
  const discountedPrice = () => calculateDiscount(getPrice());

  return (
    <div className={CSS.ticket}>
      <div className={CSS.total}>
        <h3>Quote summary</h3>
        <div className={CSS.costs}>
          { bookedServiceOrder?.rate_type === 'flat' ? (
            <>
            <dl className={CSS.row}>
              <dt className={CSS.label}>Estimated Price</dt>
              <dd className={CSS.value}>{format.currency(getPrice())}</dd>
            </dl>
            </>
          ) : (
            <>
              <dl className={CSS.row}>
                <dt className={CSS.label}>Hourly rate</dt>
                <dd className={CSS.value}>{format.currency(getRate())}</dd>
              </dl>
              <dl className={CSS.row}>
                <dt className={CSS.label}>
                  Arrival fee
                  <PopOver
                    placement="top"
                    className="ml-5"
                    trigger={isMobile ? 'click' : 'hover'}
                    tooltip={(
                      <p className={CSS.tooltip}>
                        The <strong>arrival fee</strong> is a component of the minimum cost of the move and represents the time it takes for movers to travel to your pickup location at the beginning of the move, and from your destination location at the end of the move.<br/><br/>
                        The final cost will be based on the combination of <strong>arrival fee</strong> and <strong>labor time</strong>.<br/><br/>
                        <strong>Labor time</strong> starts once the movers arrive at the pickup address and ends once they are done unloading at the drop-off address, rounded up to the nearest half hour. Please note that the travel between the pickup and the destination is part of labor time. Any tolls or parking fees required to complete the move will be included as additional charges in the final price. Any delays cause by elevators, changes to the list of items to be moved, building restrictions, traffic, or anything else will be included in the total time of the move.
                      </p>
                    )}
                  >
                    <Icon symbol={'Info-circle'} library={'code'} size={'20px'} />
                  </PopOver>
                </dt>
                <dd className={CSS.value}>{format.currency(getRate())}</dd>
              </dl>
              <dl className={CSS.row}>
                <dt className={CSS.label}>Minimum cost</dt>
                <dd className={CSS.value}>{format.currency(getMinimum())}</dd>
              </dl>
            </>
          )}

          {discounts && discounts.map((discount,index) => {
            return (
              <dl className={CSS.row} key={`${discount?.sponsor.name}_${discount.cents || discount.percent}_${index}`}>
                <dt className={CSS.label}>Coupon</dt>
                <dd className={CSS.value}><span className={CSS.coupon}>
                  {(function() {
                    switch(discount.type) {
                      case 'cents':
                        return `- ${format.currency(discount.cents)}`;
                      case 'percent':
                        return `- ${format.currency(getMinimum() * discount.percent)}`;
                      default:
                        return null;
                    };
                  })()}
                </span></dd>
              </dl>
            );
          })}
        </div>
      </div>

      { bookedServiceOrder?.rate_type === 'flat' ? (
        <div className={CSS.amount}>
          <dl className={CSS.due}>
            <dt className={CSS.label}>Estimated due</dt>
            <dd className={CSS.value}>{format.currency(discountedPrice())}</dd>
          </dl>
          <p className={CSS.fine_print}>
            Moved will authorize your card for the estimated due 48 hours before
            moving day. Upon completion of the move if your total cost ends up being less,
            we will release the hold on the overage. If the total end up being greater, you
            will be billed for the difference.
          </p>
        </div>
      ) : (
        <div className={CSS.amount}>
          <dl className={CSS.due}>
            <dt className={CSS.label}>Authorization amount</dt>
            <dd className={CSS.value}>{format.currency(getMinimum())}</dd>
          </dl>
          <p className={CSS.fine_print}>
            Moved will authorize your card for this amount 48 hours before
            moving day. If your move takes longer than the minimum hours, you
            will be billed for the difference. { discounts && `Coupon(s) will be applied to the final price.` }
          </p>
        </div>
      )}

      { isCancelable && (
        <div className={CSS.cancellation}>
          <Icon symbol={'Time-schedule'} size={'24px'} className={CSS.clock} />
          <div>
            <h3>Free cancellation</h3>
            <p>
              You can cancel free of charge until{' '}
              { lastCancellationDate && (
                <span className={CSS.cancel_time}>
                  11:59&nbsp;PM on { format.date(lastCancellationDate, 'MMM D') }
                </span>
              )}
              {' '}(48hrs) before move date.
            </p>
          </div>
        </div>
      )}

      { showCancellationPolicy && (
        <div className={CSS.cancellation}>
          <div>
            <h3>Cancellation policy</h3>
            <p className={CSS.fine_print}>
              <CancellationDisclaimer />
            </p>
          </div>
        </div>
      )}

      { showInsuranceDisclaimer && (
        <div className={CSS.cancellation}>
          <div>
            <h3>Insurance</h3>
            <p className={CSS.fine_print}>
              <InsuranceDisclaimer />
            </p>
          </div>
        </div>
      )}

    </div>
  );
}
