import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_HAPPYCO_INSPECTION_TASK',
  request: (id) => request.get(`/residents/happyco-inspection-tasks/${id}`).then(r => r.data.data.happyco_inspection_task),
  selector: (state, id) => get(state, `happycoInspectionTasks.${id}`),
  reducers: {
    success: (state, { response: happycoInspectionTask }) => ({
      ...state,
      happycoInspectionTasks: {
        ...state.happycoInspectionTasks,
        [happycoInspectionTask.id]: happycoInspectionTask,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useHappycoInspectionTask,
  usePending: useHappycoInspectionTaskPending,
} = generate.redux(creator);
