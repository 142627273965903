import React from 'react';

import { Button } from '@moved/ui';

import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const Summary = ({ screen, origin, previousScreen, moveId, onClose }) => {

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={[{
        value: (
          <Button
            size='small'
            color='secondary'
            text='Review Instructions'
            onClick={() => previousScreen()}
          />
        )
      }]}
    />
  );

};
