import React from 'react';
import { merge } from 'lodash';

import { TagRenderer } from '@moved/ui';

import { useResidentTags } from '../hooks/useResidentTags';

export const ResidentTagRenderer = ({ tags={}, ...props }) => {
  const residentTags = useResidentTags();
  return (
    <TagRenderer
      tags={merge(residentTags,tags)}
      {...props}
    />
  );
};
