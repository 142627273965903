import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  ModalTitle,
  ModalContent,
  ModalActions,
  Form,
  FormText,
} from '@moved/ui';

import { getMove } from '../../moves/actions';
import { createCustomTask } from '../actions/createCustomTask';
import { updateTaskList } from '../actions/updateTaskList';

export const CreateCustomTaskModal = ({ taskListId, moveId }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState();

  const submit = (data) => {
    if(pending) return;
    setPending(true);
    dispatch(createCustomTask(taskListId, data))
      // after submission set the task list progress
      .then(() => dispatch(updateTaskList(taskListId, { first_task: 1 })))
      .then(() => dispatch(getMove(moveId))) // refresh move to update new task count
      .then(() => Modal.close())
      .catch(err => {
        setPending(false);
        Notify.error(format.error(err))
      });
  }

  return (
    <div style={{minWidth:'450px'}}>
      <ModalTitle>Add custom task</ModalTitle>
      <ModalContent className='paddingTop-24 paddingBottom-24'>
        <Form
          name='customTask'
          validation={Yup.object({
            title: Yup.string().required('required'),
          })}
          onSubmit={submit}
        >
          <FormText
            name='title'
            label='Name'
            hint='eg.: Sell furniture, hire painters, etc.'
          />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={Modal.close}
          disabled={pending}
        />
        <Button
          text='Add'
          color='primary'
          form='customTask'
          disabled={pending}
        />
      </ModalActions>
    </div>
  );
}
