import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import moment from 'moment';
import classNames from 'classnames';

import { DynamicForm } from '@moved/ui';
import { useNotify, format, fieldService } from '@moved/services';

import { Snippet } from '../../../../snippets';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

export const PolicyDetails = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);
  const taskDetails = taskDefinition.selectors.useRenterInsurance(id);
  const pending = taskDefinition.selectors.useUpdateRenterInsuranceRequestPending();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);

  const _handleSubmit = ({ policy_term, confirmation, ...policyData}) => {
    if(pending) return;
    dispatch(taskDefinition.actions.updateRequest(activeRequest.id, policyData))
      .then(resp => {
        // If this screen is in the flow it is *sometimes* last so it submits the policy
        // only when the flag 'submitRequest' has been set on the screen by the flow builder
        if(screen.submitRequest) return dispatch(taskDefinition.actions.submitRequest(activeRequest.id));
        else return resp;
      })
      .then(nextScreen)
      .catch(err => Notify.error());
  };

  const fields = [
    {
      label: 'Policy details',
      type: 'title',
    },
    {
      label: 'Insurance company',
      type: 'text',
      name: 'insurance_provider',
      required: true,
    },
    {
      label: 'Policy number',
      type: 'text',
      name: 'policy_number',
      required: true,
    },
    {
      label: 'Liability coverage amount',
      type: 'currency',
      name: 'liability_coverage_amount',
      required: true,
    },

    {
      label: 'Coverage timeline',
      type: 'title',
    },
    {
      label: 'Policy Term',
      type: 'slideToggle',
      name: 'policy_term',
      default: 12,
      required: true,
      toggleProps: { full: true, rectangular: true },
      onChange: (data, setFieldValue, name) => {
        if(data !== 'other' && formData?.coverage_start) {
          const coverageEndDate = moment(formData?.coverage_start).add(data,'M');
          setFieldValue('coverage_end',coverageEndDate.format('YYYY-MM-DD'));
        }
      },
      options: [
        { label: '12 months', value: 12, },
        { label: '6 months', value: 6, },
        { label: 'Other', value: 'other', },
      ]
    },
    {
      label: 'Start date',
      type: 'datePicker',
      name: 'coverage_start',
      half: true,
      required: true,
      maxDate: taskDetails?.latest_coverage_start_date,
      flavor: 'Coverage must begin on or before your lease start date',
      onChange: (data, setFieldValue, name) => {
        if(formData?.policy_term !== 'other') {
          const coverageEndDate = moment(data).add(formData?.policy_term ?? 12,'M');
          setFieldValue('coverage_end',coverageEndDate.format('YYYY-MM-DD'));
        }
      },
    },
    {
      label: 'End date',
      type: 'datePicker',
      name: 'coverage_end',
      half: true,
      required: true,
      minDate: taskDetails?.latest_coverage_start_date,
      onChange: (data, setFieldValue, name) => {
        if(formData?.coverage_start) {
          const term = moment(data).diff(formData?.coverage_start,'months',true);
          if(term === 12) { if(formData?.policy_term !== 12) setFieldValue('policy_term',12); }
          else if(term === 6) { if(formData?.policy_term !== 6) setFieldValue('policy_term',6); }
          else { if(formData?.policy_term !== 'other') setFieldValue('policy_term','other'); }
        }
      },
    },
    {
      name: 'confirmation',
      label: (
        <>
          I confirm that <Snippet tag={'tasks.insurance.interested_party_name'} description={'Interested party name'}>{building?.partner?.name}</Snippet> is listed as an "Interested Party"
        </>
      ),
      type: 'checkbox',
      value: false,
      required: 'Confirmation is required in order to continue',
    },
  ];
  const hydratedFields = fieldService.hydrate(fields,activeRequest);
  const validation = Yup.object().shape({
    liability_coverage_amount: Yup.number()
      .required('Liability coverage amount is required')
      .min(
        taskDetails.minimum_coverage_requirement ? taskDetails.minimum_coverage_requirement : 10000000,
        `Liability coverage amount must be at least ${taskDetails.minimum_coverage_requirement ? format.currency(taskDetails.minimum_coverage_requirement) : '$100,000'}`
      )
      .max(2000000000,'Maximum coverage amount is $20,000,000.00'),
    coverage_end: Yup.date('End date must be a valid date').when('coverage_start',
      (coverage_start, schema) => {
        if(coverage_start && moment(coverage_start).isValid()) {
          return schema
            .min(
              moment(coverage_start).add(1, 'day').toDate(),
              `Coverage end date must be after start date`
            )
            .max(
              moment(coverage_start).add(2,'year').toDate(),
              `Coverage end date must be within two years of start date.`
            );
        } else {
          return schema.min(
            moment().add(1, 'day').toDate(),
            `Coverage end date must be after start date`
          );
        }
      },
    ),
  },['coverage_start','coverage_end']);

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <DynamicForm
          id={`${screen.slug}-form`}
          fields={hydratedFields}
          validation={validation}
          formStyle={'underline'}
          onSubmit={_handleSubmit}
          onChange={setFormData}
          autocomplete={screen.autocomplete}
          disabled={pending}
        />
      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor={`${screen.slug}-form-submit`}
          tabIndex="0"
          role="button"
          className={classNames('btn-primary btn--full',{'loading':pending})}
          disabled={pending}
        >
          Next
        </label>
      </ScreenActions>
    </ScreenLayout>
  );
}
