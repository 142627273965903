import { AppStoreLinkDisplay } from './AppStoreLink/AppStoreLinkDisplay';
import { AttachesDisplay } from './Attaches/AttachesDisplay';
import { ButtonDisplay } from './Button/ButtonDisplay';
import { CheckListDisplay } from './CheckList/CheckListDisplay';
import { DefinitionDisplay } from './Definition/DefinitionDisplay';
import { DelimiterDisplay } from './Delimiter/DelimiterDisplay';
import { HeadingDisplay } from './Heading/HeadingDisplay';
import { ImageDisplay } from './Image/ImageDisplay';
import { LegacyDisplay } from './Legacy/LegacyDisplay';
import { ListDisplay } from './List/ListDisplay';
import { QuoteDisplay } from './Quote/QuoteDisplay';
import { SpacingDisplay } from './Spacing/SpacingDisplay';
import { TextDisplay } from './Text/TextDisplay';
import { TitleBarDisplay } from './TitleBar/TitleBarDisplay';

export const renderers = {
  appStoreLink: AppStoreLinkDisplay,
  attaches: AttachesDisplay,
  button: ButtonDisplay,
  checklist: CheckListDisplay,
  definition: DefinitionDisplay,
  delimiter: DelimiterDisplay,
  heading: HeadingDisplay,
  image: ImageDisplay,
  legacy: LegacyDisplay,
  external: LegacyDisplay, // extension of Legacy with different watermark
  list: ListDisplay,
  paragraph: TextDisplay, // deprecated for Text (soon)
  quote: QuoteDisplay,
  spacing: SpacingDisplay,
  text: TextDisplay,
  titleBar: TitleBarDisplay,
};
