import { generate, request } from '@moved/services';
import { get } from 'lodash'

const creator = {
  namespace: 'TASKS_GET_UTILITY_SETUP',
  request: (id) => request.get(`/utility-setup-tasks/${id}`).then(r => get(r,'data.data.utility_setup_task')),
  selector: (state, id) => get(state,`utilitySetups.${id}`),
  reducers: {
    success: (state, { response: utilitySetup }) => {
      return {
        ...state,
        utilitySetups: {
          ...state.utilitySetups,
          [utilitySetup.id]: utilitySetup,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useUtilitySetup,
  usePending: useUtilitySetupPending,
} = generate.redux(creator);

export const initialState = {};
