import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_UTILITIES',
  request: (id) => request.get(`/utilities/${id}`).then(r => r.data.data),
  selector: (state, id) => get(state,`utilities.${id}`),
  reducers: {
    success: (state, { response: utility }) => {
      return {
        ...state,
        utilities: {
          ...state.utilities,
          [utility.id]: utility,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useUtility,
  usePending: useUtilityPending,
} = generate.redux(creator);
