import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_VEHICLE_REGISTRATION',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/vehicle-registration-tasks`, data).then(r => get(r,'data.data.vehicle_registration_task')),
  reducers: {
    success: (state, { response: vehicleRegistration }) => {
      return {
        ...state,
        vehicleRegistrations: {
          ...state.vehicleRegistrations,
          [vehicleRegistration.id]: vehicleRegistration,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCreateVehicleRegistrationPending,
} = generate.redux(creator);

export const initialState = {};
