import { base } from '../base';

import { getArtifactFields } from './getArtifactFields';
import { getArtifacts } from './getArtifacts';
import { getOverrideFields } from './getOverrideFields';
import { getOverrides } from './getOverrides';
import { getPrimaryPolicyHolder } from './getPrimaryPolicyHolder';
import { getRequestFields } from './getRequestFields';

export const insurance = {
  ...base,
  getArtifactFields,
  getArtifacts,
  getOverrideFields,
  getOverrides,
  getPrimaryPolicyHolder,
  getRequestFields,
};
