import React, { useState } from 'react';
import { useInterval } from 'react-use';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { userService } from '@moved/services';

import CSS from './Greeting.module.scss';

export const Greeting = ({ user, className }) => {
  const [greeting, setGreeting] = useState(userService.getTimeBasedGreeting(user));

  useInterval(() => {
    setGreeting(userService.getTimeBasedGreeting(user));
  }, 1000*60); // refresh the greeting every minute

  return <span className={classNames(CSS.greeting,className)}>{ greeting }</span>;

};

Greeting.propTypes = {
  /** User object */
  user: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
  }).isRequired,
  /** Optional class name */
  className: PropTypes.string,
};
