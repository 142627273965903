import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_CREATE_NEW_TASKS',
  request: (id, data) => request.post(`/residents/personal-task-lists/${id}/personal-tasks`,data).then(r => r?.data?.data?.personal_task_list),
  reducers: {
    success: (state, { response: taskList }) => ({
      ...state,
      taskLists: {
        ...state.taskLists,
        [taskList.id]: taskList,
      },
    }),
  },
};

export const {
  action: createNewTasks,
  reducer,
  usePending: useCreateNewTasksPending,
} = generate.redux(creator);

export const initialState = {
  taskLists: {},
};
