import React from 'react';

import { format } from '@moved/services';

import CSS from './styles/BookCoupon.module.scss';

export const BookCoupon = ({ discount }) => (
  <div className={CSS.coupon}>
    <div className={CSS.coupon_text}>
      <span className={CSS.coupon_title}>Coupon</span>
      <span>
        Courtesy of {discount?.sponsor?.is_partner ? `${discount?.sponsor?.name}, in partnership with Moved` : 'Moved'}
      </span>
    </div>
    <div className={CSS.coupon_amount}>
      <span>
        {(function() {
          switch(discount.type) {
            case 'cents':
              return format.currency(discount.cents);
            case 'percent':
              return format.percent(discount.percent);
            default:
              return null;
          };
        })()}
      </span>
    </div>
  </div>
);
