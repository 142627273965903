import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { useNotify } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { PageNotFound } from '../../common';
import { getBuilding } from '../../common/actions';
import { useBuilding, useBuildingPending } from '../../common/actions/selectors';
import { useMove, useMovePending } from '../../moves/actions/selectors';

// changing move step changes building context and needs to refresh snippet map too
import { getSnippetMap } from '../../snippets/actions';

const ErrorMessage = () => (
  <span>
    We couldn't find that move.&nbsp;
    <a href="/" style={{color: 'inherit', borderBottom: '1px solid', paddingBottom: '3px' }}>Click here</a> and we'll get you back on track.
  </span>
);

const ActiveMoveStep = createContext();

export const useActiveMoveStep = () => useContext(ActiveMoveStep);

export const MoveStepContextProvider = ({ children }) => {
  const { moveId, stepId } = useParams();
  const Notify = useNotify();
  // redux
  const dispatch = useDispatch();
  const move = useMove(moveId);
  const activeMoveStep = (move?.move_steps ?? []).find(step => step.id === parseInt(stepId));
  const building = useBuilding(activeMoveStep?.building?.slug);
  const buildingPending = useBuildingPending();
  const movePending = useMovePending();
  const pending = buildingPending || movePending;

  // refresh the activeMoveStep and the building for that moveStep
  useEffect(() => {
    if(!activeMoveStep?.building?.slug) return;
    dispatch(getBuilding(activeMoveStep.building.slug))
      .then((building) => dispatch(getSnippetMap(building.id)))
      .catch(() => Notify.error(<ErrorMessage/>));
  },[activeMoveStep,dispatch,Notify]);

  return (
    <ActiveMoveStep.Provider value={activeMoveStep}>
      {(activeMoveStep && building) ? (
        children
      ) : (
        pending ? <LoaderOverlay/> : <PageNotFound />
      )}
    </ActiveMoveStep.Provider>
  );
};

export const withMoveStep = (Wrapped) => (props) => (<MoveStepContextProvider><Wrapped {...props}/></MoveStepContextProvider>);
