import userRoutes from '../features/user/routes';
import landingPagesRoutes from '../features/landingPages/routes';
import movesRoutes from '../features/moves/routes'; // includes dashboard and task routes
import surveyRoutes from '../features/surveys/routes'; //guest routes for survey response emails
import commonRoutes from '../features/common/routes';

export const routeTree = [
  ...movesRoutes,
  ...userRoutes,
  ...landingPagesRoutes,
  ...surveyRoutes,
  ...commonRoutes // must be last
];
