import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_ADDON_OPTIONS_LIST',
  request: () => request.get('/addon-interests/options').then(r => r.data),
  selector: (state) => state.options_list,
  cache: true,
  reducers: {
    success: (state, { response: options_list }) => {
      return {
        ...state,
        options_list: options_list,
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useAddonOptionsList,
  usePending: useAddonOptionsListPending,
} = generate.redux(creator);

export const initialState = {
  options_list: [],
};
