import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_INSURANCE_VERIFY_EPREMIUM_POLICY',
  request: (id, data={}) => request.post(`/residents/renters-insurance-policy-requests/${id}/complete-epremium-purchase`, data).then(r => r.data.data.renters_insurance_task),
  reducers: {
    success: (state, { response: insuranceTask }) => {
      return {
        ...state,
        renterInsurances: {
          ...state.renterInsurances,
          [insuranceTask.id]: insuranceTask,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResourcePending: useVerifyEpremiumPolicyPending,
} = generate.redux(creator);
