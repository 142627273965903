import { generate, request } from '@moved/services';

const creator = {
  namespace: 'GET_SERVICE_PROVIDER_AD_REDIRECT',
  request: ({
    buildingMoveTaskId,
    serviceProviderAdId,
  }) => (
    request
      .post(`/residents/building-move-tasks/${buildingMoveTaskId}/building-providers/${serviceProviderAdId}/link`)
      .then(resp => resp?.data?.data?.service_provider_ad_link)
  ),
};

export const {
  action,
  reducer,
  usePending: useServiceProviderAdRedirectPending,
} = generate.redux(creator);

export const initialState = {};
