import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { LoaderOverlay } from '@moved/ui';

import { useActiveBuildingMoveTask } from '../../../contexts/BuildingMoveTaskContext';

export const DeclineEmailCoupon = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    activeTaskDetails,
    activeTaskDefinition,
    activeTaskBaseRoute,
  } = useActiveBuildingMoveTask();

  useEffect(() => {
    if(!activeTaskDetails) return;
    const isTaskEligibleForDecline = (
      get(activeTaskDetails,'selected_moving_method') === 'professional-movers' &&
      get(activeTaskDetails,'interested_in_moved') &&
      !get(activeTaskDetails,'mover_booking.booked_service_order')
    );
    if(isTaskEligibleForDecline) {
      dispatch(activeTaskDefinition.actions.reset(id))
        .then(resp => history.replace(`${activeTaskBaseRoute}${activeTaskDefinition.getStartOverRoute(resp)}`))
        .catch(() => history.replace(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(activeTaskDetails)}`));
    }
    else {
      history.replace(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(activeTaskDetails)}`);
    }
  },[activeTaskDetails]); // eslint-disable-line

  return <LoaderOverlay/>;

};
