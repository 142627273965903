import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import * as Yup from 'yup';

import { Icon, Notebox, StyledForm, RenderFields, ConfirmationModal } from '@moved/ui';
import { useUser, format, useNotify, useModal } from '@moved/services';

import { LayoutCollapsibleHeader } from '../../common';
import { useMoveSummaries } from '../../moves/actions/selectors';

import { updateUser, deleteAccount } from '../actions';
import { useUpdateUserPending, useDeleteAccountPending } from '../actions/selectors';

import CSS from '../styles/Profile.module.scss';

export const Profile = () => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const Modal = useModal();
  const { user, updateUser: updateUserInContext } = useUser();
  const updatePending = useUpdateUserPending();
  const deletePending = useDeleteAccountPending();
  const moveSummaries = useMoveSummaries();
  // deconstruct relevant user data for convenience
  const {
    firstname,
    lastname,
    email,
    delete_requested_at: deletionRequested,
  } = user;

  // track the current form data to know if it is dirty and needs saving
  const [userData, setUserData] = useState({ firstname, lastname, email });
  const isFormDirty = (
    userData.firstname !== firstname ||
    userData.lastname !== lastname ||
    userData.email !== email
  );

  // build the form fields for the personal information section
  const personalInfoFields = [
    {
      name: 'firstname',
      label: 'First name',
      type: 'text',
      value: user.firstname,
      half: true,
    },
    {
      name: 'lastname',
      label: 'Last name',
      type: 'text',
      value: user.lastname,
      half: true,
    },
    {
      name: 'email',
      label: 'Email address',
      type: 'email',
      value: user.email,
      half: true,
    },
  ];

  const validation = Yup.object().shape({
    firstname: Yup.string().required('required'),
    lastname: Yup.string().required('required'),
    email: Yup.string().email('Must be a valid email').required('required'),
  });

  // button action handlers
  const revertUserDataForm = ({setValues}) => {
    setUserData({firstname, lastname, email});
    setValues({firstname, lastname, email});
  };

  const submitUserDataForm = (data) => {
    if(updatePending || !isFormDirty) return;
    return dispatch(updateUser(data))
      .then((user) => {
        updateUserInContext(user);
        Notify.default(`Successfully updated your information.`);
      })
      .catch((err) => Notify.error(format.error(err,{
        'user-email-exists':'This email is already associated with another account.'
      })));
  }

  const submitDeleteAccount = () => {
    if(deletePending) return;
    return dispatch(deleteAccount())
      .then(user => {
        updateUserInContext(user);
        Notify.default(`Your request for deletion has been submitted.`);
      });
  };

  const confirmDeleteAccount = () => Modal.open(
    <ConfirmationModal
      title={(
        <div className='flex items-center'>
          <Icon library='code' symbol='Warning-2-circle-small' size='18px' color='red' className='mr-10' style={{display:'inline-block'}}/>
          <span>Delete your account</span>
        </div>
      )}
      copy={`Deleting your account can take up to 72 hours. Are you sure you wish to delete your account?`}
      confirmText='Submit request'
      isWarning={true}
      cancelText='Cancel'
      onConfirm={submitDeleteAccount}
    />,
    { sondheim: true },
  );

  // if there is only one move, redirect to that move, otherwise redirect to the moves list
  const homeRoute = moveSummaries?.length === 1 ? `/moves/${moveSummaries[0].id}` : '/moves';

  return (
    <LayoutCollapsibleHeader theme='light'>
      <div className={CSS.dashboard}>
        <div className={CSS.content}>
          <div className={CSS.headliner}>
            <Link to={homeRoute} className={classNames('labelS',CSS.back_link)}>
              <Icon library='navigation' symbol='Arrow-up' size='20px' className={CSS.back_icon} />
              Back to home
            </Link>
            <h1 className='displayXL contentPrimary'>Profile</h1>
            { deletionRequested && (
              <Notebox
                icon={{
                  library:'code',
                  symbol:'Warning-2'
                }}
                heading='Account Pending Deletion'
                body='Your request for account deletion has been received. Please allow up to 72 hours for your account to be removed from our system.'
                color='red'
                className='mt-10'
              />
          )}
          </div>
          <section className={CSS.section}>
            <StyledForm
              id='personal-info-form'
              onSubmit={submitUserDataForm}
              formStyle='underline'
              validation={validation}
              initialValues={{firstname,lastname,email}}
              autocomplete='false'
              onChange={setUserData}
            >
              {(formik) => (
              <div className={CSS.form_wrapper}>
                <div className={CSS.section_header}>
                  <div className={CSS.heading_column}>
                    <h4 className='headingS contentPrimary'>Personal info</h4>
                    <p className='labelS contentSecondary'>Update your personal details.</p>
                  </div>
                  <CSSTransition
                    in={isFormDirty}
                    unmountOnExit
                    timeout={300}
                    classNames={{
                      enter: CSS['enter'],
                      enterActive: CSS['enter-active'],
                      exit: CSS['exit'],
                      exitActive: CSS['exit-active'],
                    }}
                  >
                    <div className={CSS.action_column}>
                      <div className='btn-gray btn--small mr-10' onClick={() => revertUserDataForm(formik)}>
                        Revert
                      </div>
                      <button
                        className={classNames('btn-primary btn--small', {loading: updatePending})}
                        type='submit'
                        form='personal-info-form'
                        disabled={updatePending}
                      >
                        Save changes
                      </button>
                    </div>
                  </CSSTransition>
                </div>
                <div className={CSS.form_fields}>
                  <RenderFields
                    fields={personalInfoFields}
                    form={formik}
                    formStyle='underline'
                    autocomplete={false}
                  />
                </div>
              </div>
              )}
            </StyledForm>
          </section>
          <section className={CSS.section}>
            <div className={CSS.section_header}>
              <div className={CSS.heading_column}>
                <h4 className='headingS contentPrimary'>Change password</h4>
                <p className='labelS contentSecondary'>Change your password at any time.</p>
              </div>
            </div>
            <div className={CSS.form_fields}>
              <Link to='/profile/password'>
                <div className='inline-flex btn-gray'>
                  Change password
                  <Icon
                    library='navigation'
                    symbol='Arrow-right'
                    size='24px'
                    className='ml-15'
                  />
                </div>
              </Link>
            </div>
          </section>
          <section className={CSS.section}>
            <div className={CSS.section_header}>
              <div className={CSS.heading_column}>
                <h4 className='headingS contentPrimary'>Delete account</h4>
                <p className='labelS contentSecondary'>Request deletion of all of your personal data.</p>
              </div>
            </div>
            <div className={CSS.form_fields}>
              <button
                className='btn-ghost-red'
                onClick={confirmDeleteAccount}
                disabled={deletionRequested}
              >
                Delete my account
              </button>
            </div>
          </section>

        </div>
      </div>
    </LayoutCollapsibleHeader>
  );
}
