import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'SURVEYS_GET_SATISFACTION_REQUEST',
  request: (token) => request.get(`/residents/satisfaction-survey-requests/${token}`).then(r => r.data.data.satisfaction_survey_request),
  selector: (state, token) => get(state, `satisfactionRequest.${token}`) || {},
  reducers: {
    success: (state, { response: { response, ...request }, params: [token] }) => {
      return {
        ...state,
        satisfactionRequest: {
          ...state.satisfactionRequest,
          [token]: request,
        },
        // Since we're also get the response on this endpoint
        // but all updates, creates .etc only return response
        // (and we still have a get endpoint)
        // storing response under the separate namespace.
        satisfactionResponses: {
          ...state.satisfactionResponses,
          [token]: response,
        },
      };
    },
    failure: (state, { params: [token] }) => {
      return {
        ...state,
          satisfactionRequest: {
          ...state.satisfactionRequest,
          [token]: {},
        },
        satisfactionResponses: {
          ...state.satisfactionResponses,
          [token]: {},
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  useResource: useSatisfactionRequest,
  usePending: useSatisfactionRequestPending,
} = generate.redux(creator);

export const initialState = {
  satisfactionRequest: {},
  satisfactionResponses: {},
};
