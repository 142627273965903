import { merge } from 'lodash';
import moment from 'moment';

import { payMoveInCosts as helpers } from '@moved/product';

import { Snippet } from '../../../snippets';
import { BaseTask } from '../shared/components/BaseTask';
import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class PayMoveInCosts extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.usePayMoveInCostsTask,
        ...selectors,
      }
    });
  }

  static label = 'Pay move-in costs';
  static slug = 'pay-move-in-costs';
  static icon = 'Calculator';
  static helpers = helpers;

  _flowBuilder(taskData) {
    return [
      this.screens['payment'],
      this.screens['summary'],
    ];
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    const wasSubmittedWithin3Days = moment(request.submitted_at).add(3,'d').isAfter();
    let requestCopy = {};
    switch(request.status) {
      case 'cancelled':
        requestCopy = {
          summary: {
            detailsTitle: null,
          }
        }
        break;
      case 'rejected':
        requestCopy = {
          summary: {
            detailsTitle: null,
            flavor: `Property Management was unable to verify your payment. Review the requested changes below.`,
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            detailsTitle: null,
            title: (
              taskDetails.payments.length === 0 ? (
                wasSubmittedWithin3Days ? 'Payments pending' : 'Payments missing'
              ) : (
                request.verified_at ? 'Pending approval' : 'Payments pending'
              )
            ),
            flavor: (
              taskDetails.payments.length === 0 ? (
                wasSubmittedWithin3Days ? (
                  // No payments, recent submission
                  `Thank you for indicating that you have paid your move-in costs. We are waiting for verification that payment has been processed, which may take up to several days.`
                ) : (
                  // No payments, 3 days after submission
                  `Thank you for indicating that you have paid your move-in costs. Unfortunately, we have not received verification that payment has been processed after 3 days. Please ensure payment was made and then follow up with property management.`
                )
              ) : (
                request.verified_at ? (
                  // Paid in full, not yet approved manually
                  `Thank you for indicating that you have paid your move-in costs. Property management has been notified that your payments are processed. You will be notified when your file is reviewed.`
                ) : (
                  // Partial payment
                  `Thank you for indicating that you have paid your move-in costs. Once all costs have been paid and approved, this task will automatically be marked as completed. If any household members have made additional payments, verification may take up to several days.`
                )
              )
            ),
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            detailsTitle: null,
            title: `Thank you!`,
            flavor: requiresApproval ? (
              <Snippet tag={'tasks.pay-move-in-costs.summary.approved.flavor'}>
                Payment for your move-in costs has been approved.
              </Snippet>
            ) : (
              <Snippet tag={'tasks.pay-move-in-costs.summary.submitted.flavor'}>
                Thank you for indicating that you have paid your move-in costs. Your property will contact you if there are any issues.
              </Snippet>
            ),
          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
