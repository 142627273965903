import React from 'react';
import classNames from 'classnames';

import { Icon } from '@moved/ui';

import CSS from './styles/TrainMobile.module.scss';

export const TrainMobile = ({
  taskDetails,
  taskDefinition,
  activeScreen,
  onClose,
  previousScreen,
}) => {
  // don't attempt to render the train until the details are loaded
  if(!taskDetails || taskDefinition?.flatFlow?.length === 0) return;

  const activeIndex = taskDefinition.flatFlow?.findIndex?.(
    screen => screen.slug === activeScreen.slug && (activeScreen.context == null || screen.context === activeScreen.context)
  );

  const percentage = ((activeIndex+1)/taskDefinition?.flatFlow?.length)*100;

  return (
    <div className={CSS.train}>

      <div onClick={() => previousScreen()} className={CSS.nav}>
        <Icon symbol='Chevron-left' library='navigation' size='24px' />
      </div>

      <div className={CSS.station}>
        <div
          className={classNames(CSS.rail, { [CSS.complete]: (percentage>=100) })}
          style={{ width: `${percentage}%`, }}
        />
      </div>

      <div onClick={onClose} className={CSS.nav}>
        <Icon symbol='Close' library='navigation' size='24px' />
      </div>

    </div>
  );
};
