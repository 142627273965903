import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import { Icon } from '../../../../../sondheim/components/Icon';
import { TagRenderer } from '../../../TagRenderer';

import CSS from './Definition.module.scss';

export const DefinitionDisplay = ({ data, tags }) => {
  const { icon, title, content, showHeading=true } = data;
  return (
    <div className={classNames(CSS.render)}>
      <Icon className={classNames(CSS.icon)} library={get(icon,'library')} symbol={get(icon,'symbol')} size={'32px'}/>
      <div className={'flex-auto'}>
        { showHeading && (
          <TagRenderer as='div' className={classNames('headingS contentPrimary',CSS.title)} content={title} tags={tags}/>
        )}
        <TagRenderer as='div' className={classNames('labelM contentSecondary',CSS.content)} content={content} tags={tags}/>
      </div>
    </div>
  );
};
