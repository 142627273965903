import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_VEHICLE_TYPES',
  request: () => request.get(`/vehicle-types`).then(r => get(r,'data.data.vehicle_types')),
  selector: (state) => get(state,`vehicleTypes`),
  cache: { minutes: 60 },
  reducers: {
    success: (state, { response: types }) => {
      return {
        ...state,
        vehicleTypes: types,
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useVehicleTypes,
  usePending: useVehicleTypesPending,
} = generate.redux(creator);

export const initialState = {
  vehicleTypes: [],
};
