import * as click from './click';
import * as create from './create';
import * as get from './get';
import * as submit from './submit';

export const reducers = [
  click.reducer,
  create.reducer,
  get.reducer,
  submit.reducer,
];

export const initialState = {
  happycoInspectionTasks: {},
};
