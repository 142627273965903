import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useNotify, format } from '@moved/services';
import { DynamicForm, AtomSpinner, Notebox } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';
import { QuoteFAQs } from './QuoteFAQs';
import { QuoteTicket } from './QuoteTicket';
import { RateDetails } from './RateDetails';
import { LastMinuteNotice } from './LastMinuteNotice';

import CSS from './styles/ViewQuotes.module.scss';

// Calculate new minimum after removing all discounts
const _calcReduction = (min, discounts) => {
  const reducer = (accumulator, discount) => {
    switch(discount.type) {
      case 'cents':
        return accumulator - discount.cents;
      case 'percent':
        return accumulator - (min * discount.percent);
      default:
        return accumulator;
    };
  };
  return discounts.reduce(reducer,min);
};

// Render quotes screen
export const ViewQuotes = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const rates = taskDefinition.selectors.useRates(moverBooking.id) || [];
  const getRatesPending = taskDefinition.selectors.useRatesPending();
  const updatePending = taskDefinition.selectors.useUpdateMoverBookingPending();
  const { discounts = [] } = taskDefinition.selectors.useMove(moveId);
  const percentDiscounts = discounts.filter(discount => discount?.type === 'percent');
  const isCancelable = taskDefinition.isCancellationFree(taskDetails);

  // fetch latest rates
  useEffect(() => {
    dispatch(taskDefinition.actions.getRates(moverBooking.id))
      .catch(err => Notify.error('Unable to retrieve quotes'));
  // eslint-disable-next-line
  },[]); // only run on mount

  // Submit form function
  const submit = ({ rate }) => {
    const data = { selected_partner_rate_id: parseInt(rate) };
    return dispatch(taskDefinition.actions.updateMoverBooking(moverBooking.id, data))
      .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
      .then(nextScreen)
      .catch(err => Notify.error(format.error(err)));
  };

  // Opt out function
  const optOut = () => {
    dispatch(taskDefinition.actions.update(taskDetails.id, { interested_in_moved: false }))
      .then(nextScreen)
      .catch(err => Notify.error(format.error(err)));
  }

  // convenience function
  const getInitialRateSelection = () => {
    const selectedRate = moverBooking?.selected_partner_rate;
    const selected = (
      (selectedRate && rates.find(rate => rate.id === selectedRate.id)) || // previously selected
      (selectedRate && rates.find(rate => rate.crew_size === selectedRate.crew_size)) || // updated rate but same crew_size
      rates.find(rate => rate.is_recommended) // fallback to recommended rate
    );
    return selected ? selected.id : '';
  };

  const fields = [
    {
      name: 'rate',
      type: 'itemList',
      value: getInitialRateSelection(),
      required: 'You must make a selection',
      className: CSS.item_list,
      options: rates.map(rate => ({
        value: rate.id,
        icon: { symbol: `Crew-${rate.crew_size}`, library: 'people' },
        large: true,
        label: `${rate.crew_size} Person Crew`,
        sublabel: rate.is_recommended ? (<span className={CSS.badge}>Recommended</span>) : null,
        rightLabel: (
          <div className="flex flex-column">
          {/* Show percentage off on hourly rate only if _all_ coupons are percent coupons */}
          {discounts.length > 0 && percentDiscounts.length === discounts.length ?
            (<>
              <div><span>{format.currency(_calcReduction(rate.hourly_rate, percentDiscounts))}</span> <span className={CSS.per_hour}>/ hour</span></div>
              <span className={CSS.pre_coupon_rate}>{format.currency(rate.hourly_rate)}</span>
            </>) : (
              <div><span>{format.currency(rate.hourly_rate)}</span> <span className={CSS.per_hour}>/ hour</span></div>
            )}
          </div>
        ),
        hiddenContent: (<RateDetails rate={rate} discounts={discounts} task={taskDefinition} isCancelable={isCancelable} />),
      })),
    },
  ];

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <div className='stackHorizontal stackVertical--mobile gap-40 items-start'>

          <div className={CSS.form}>
            { getRatesPending ? (
              <>
                <h5 className={CSS.title} style={{textAlign: 'center', marginTop: '50px'}}>Generating quotes</h5>
                <AtomSpinner />
              </>
            ) : rates.length ? (
              <>
                <LastMinuteNotice moverBooking={moverBooking}/>
                <h5 className={CSS.title}>Choose an option</h5>
                <DynamicForm id='quote-form'
                  fields={fields}
                  onSubmit={submit}
                  disabled={updatePending}
                />
              </>
            ) : (
              <Notebox
                heading={`Unable to generate a rate`}
                body={`We are unable to provide you with instant rates. To
                  continue please send a message to your personal concierge
                  using the contact form in the bottom right corner.`}
                color='orange'
                icon={{ library:'code', symbol:'Warning-2' }}
              />
            )}

            <QuoteFAQs />
          </div> {/* CSS.form */}

          <QuoteTicket taskDefinition={taskDefinition} moveId={moveId} />

        </div>
      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor="quote-form-submit"
          tabIndex="0"
          role="button"
          className={'btn-primary btn--full'}
          disabled={updatePending}>
          Next
        </label>
        { screen.showOptOut && (
          <label
            tabIndex="0"
            role="button"
            className={'btn-ghost' + (updatePending  ? ' loading' : '')}
            onClick={optOut}
            disabled={updatePending}>
            No thanks
          </label>
        )}
      </ScreenActions>
    </ScreenLayout>
  );
}
