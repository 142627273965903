import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_CABLE_INTERNET_TASK',
  request: (taskID, data) => request.post(`/move-tasks/${taskID}/cable-internet-tasks`, data).then(r => get(r, 'data.data.cable_internet_task')),
  reducers: {
    success: (state, { response: cableInternetTask }) => {
      return {
        ...state,
        cableInternetTasks: {
          ...state.cableInternetTasks,
          [cableInternetTask.id]: cableInternetTask,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateCableInternetTaskPending,
} = generate.redux(creator);

export const initialState = {
  cableInternetTasks: {},
};
