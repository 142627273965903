import { merge } from 'lodash';

import * as editSnippets from './editSnippets';
import * as getSnippetMap from './getSnippetMap';
import * as getSnippets from './getSnippets';

export const initialState = merge(
  editSnippets.initialState,
  getSnippetMap.initialState,
  getSnippets.initialState,
);

export const reducers = [
  editSnippets.reducer,
  getSnippetMap.reducer,
  getSnippets.reducer,
];
