import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_KEY_PICKUP_TASK',
  request: (id) => request.get(`/key-pickup-tasks/${id}`).then(r => r.data.data.key_pickup_task),
  selector: (state, id) => get(state,`keyPickupTasks.${id}`),
  reducers: {
    success: (state, { response: keyPickupTask }) => {
      return {
        ...state,
        keyPickupTasks: {
          ...state.keyPickupTasks,
          [keyPickupTask.id]: keyPickupTask,
        },
      }
    }
  }
}

export const {
  action,
  reducer,
  useResource: useKeyPickupTask,
  usePending: useKeyPickupTaskPending,
} = generate.redux(creator);

export const initialState = {
  keyPickupTasks: {},
};
