import { Badge } from '@moved/ui';
import { DueDateInput } from './DueDateInput';
import { ReminderInput } from './ReminderInput';

export const TaskStatus = ({ taskDetails }) => {
  return (
    <div className='stackVertical gap-8'>
      <div className='stackHorizontal gap-24 items-center justify-start'>
        <div className='labelM contentSecondary width-96'>Status</div>
        <Badge
          text={taskDetails.status}
          type={taskDetails.status === 'todo' ? 'warning' : 'success'}
          size='medium'
        />
      </div>

      <div className='stackHorizontal gap-24 items-center justify-start'>
        <div className='labelM contentSecondary width-96'>Due date</div>
        <DueDateInput
          taskDetails={taskDetails}
        />
      </div>

      <div className='stackHorizontal gap-24 items-center justify-start'>
        <div className='labelM contentSecondary width-96'>Reminder</div>
        <ReminderInput
          taskDetails={taskDetails}
        />
      </div>
    </div>
  );
};
