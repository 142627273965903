import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { format, useModal, addressService } from '@moved/services';
import {
  Collapsible,
  Card,
  Icon,
  Tooltip,
  Button,
  Text
} from '@moved/ui';

import { MovingStopModal } from './MovingStopModal';

import CSS from './MovingStopList.module.scss';

export const MovingStopList = ({ initialStops, onChange }) => {
  const Modal = useModal();
  const { getStopHint, getStopError } = addressService;

  const [origin, setOrigin] = useState(initialStops?.at?.(0) ?? {});
  const [destination, setDestination] = useState(initialStops?.at?.(-1) ?? {});
  const [stops, setStops] = useState(initialStops?.length > 2 ? initialStops?.slice(1, -1) : []);

  useEffect(() => {
    onChange?.([origin, ...stops, destination].filter(Boolean));
  },[origin,destination,stops,onChange]);

  const openStopModal = (stop, index) => {
    Modal.open(
      <MovingStopModal
        title={index === 'new' ? 'Add stop' : 'Edit Stop'}
        value={stop}
      />,
      {
        onClose: (updatedStop) => {
          if(!updatedStop?.address) return;
          if(index === 'origin') return setOrigin(updatedStop);
          if(index === 'destination') return setDestination(updatedStop);
          if(index === 'new') return setStops([...stops, updatedStop]);
          setStops(stops.map((stop, i) => index === i ? updatedStop : stop));
        },
        sondheim: true,
      }
    );
  };

  const canAdd = (!getStopError(origin) && !getStopError(destination) && stops.length < 3);

  return (
    <div className={classNames('stackVertical gap-20', CSS.stopsList)}>
      {/* Origin */}
      <div className='stackHorizontal gap-20 items-center'>
        <Card className={CSS.stopIcon}>
          <Icon library='map' symbol='Origin' />
        </Card>
        <Text
          name='origin'
          label='Origin address'
          value={origin?.address ? format.address(origin.address,{unit:false}) : ''}
          isControlled={true}
          className='width-full'
          hint={!origin?.address ?
            'This is the address where the movers will load your belongings.' :
            getStopHint(origin)
          }
          error={getStopError(origin)}
          onClick={() => openStopModal(origin,'origin')}
        />
        { getStopError(origin) && (
          <Button
            text='Add details'
            onClick={() => openStopModal(origin,'origin')}
            size='small'
            color='secondary'
            className='flex-none'
          />
        )}
      </div>

      { stops.map((stop, index) => (
        <div className='stackHorizontal gap-20 items-center' key={stop?.address ? format.address(stop.address) : index}>
          <Card className={classNames(CSS.stopIcon,CSS.small)}>
            <Icon library='map' symbol='Origin' size='16px' />
          </Card>
          <Text
            name={`stop-${index}`}
            label='Additional stop'
            value={stop?.address ? format.address(stop.address,{unit:false}) : ''}
            isControlled={true}
            className='width-full'
            hint={!stop?.address ?
              'This is an additional stop for the movers to load or unload items.' :
              getStopHint(stop)
            }
            error={getStopError(stop)}
            onClick={() => openStopModal(stop,index)}
          />
          <Tooltip
            tooltip='Remove stop'
          >
            <Button
              onClick={() => setStops(stops.filter((stop,i) => i !== index))}
              icon={{ library: 'general', symbol: 'Trash' }}
              size='small'
              color='tertiary'
              className='flex-none'
            />
          </Tooltip>
        </div>
      ))}

      {/* Add Stop Button*/}
      { stops?.length < 3 && (
        <Collapsible open={canAdd}>
          <div className='stackHorizontal gap-20 items-center'>
            <Card className={classNames(CSS.stopIcon,CSS.small)}>
              <Icon library='navigation' symbol='Plus' size='16px' />
            </Card>
            <Button
              text='Add additional stop'
              onClick={() => openStopModal({},'new')}
              size='small'
              color='secondary'
            />
          </div>
        </Collapsible>
      )}

      {/* Destination */}
      <div className='stackHorizontal gap-20 items-center'>
        <Card className={CSS.stopIcon}>
          <Icon library='map' symbol='Destination' />
        </Card>
        <Text
          name='destination'
          label='Destination address'
          value={destination?.address ? format.address(destination.address,{unit:false}) : ''}
          isControlled={true}
          className='width-full'
          hint={!destination?.address ?
            'This is where the movers will deliver your belongings.' :
            getStopHint(destination)
          }
          error={getStopError(destination)}
          onClick={() => openStopModal(destination,'destination')}
        />
        { getStopError(destination) && (
          <Button
            text='Add details'
            onClick={() => openStopModal(destination,'destination')}
            size='small'
            color='secondary'
            className='flex-none'
          />
        )}
      </div>
    </div>
  );
};
