import React from 'react';

import { useMobile } from '@moved/services';

import { TrainMobile } from './TrainMobile';
import { TrainDesktop } from './TrainDesktop';

// Use fully explicit prop list to ensure we update both Variants to any changes
export const Train = ({
  taskDetails,
  taskDefinition,
  activeScreen,
  previousScreen,
  nextScreen,
  goToScreen,
  onClose,
}) => {
  const isMobile = useMobile();
  const TrainVariant = isMobile ? TrainMobile : TrainDesktop;
  return (
    <TrainVariant
      taskDetails={taskDetails}
      taskDefinition={taskDefinition}
      activeScreen={activeScreen}
      previousScreen={previousScreen}
      nextScreen={nextScreen}
      goToScreen={goToScreen}
      onClose={onClose}
    />
  );
};
