import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_CABLE_INTERNET_TASK',
  request: (id, data) => request.patch(`/residents/cable-internet-tasks/${id}`, data).then(r => r.data.data.cable_internet_task),
  reducers: {
    success: (state, { response: cableInternetTask }) => {
      return {
        ...state,
        cableInternetTasks: {
          ...state.cableInternetTasks,
          [cableInternetTask.id]: cableInternetTask,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateCableInternetPending,
} = generate.redux(creator);
