import React from 'react';
import { useParams, Link } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import { ProgressRing } from '@moved/ui';

import { useMove } from '../../moves/actions/selectors';

import CSS from './styles/MoveStepNavigation.module.scss';

export const MoveStepNavigation = () => {
  const { moveId } = useParams();
  const { move_steps: moveSteps } = useMove(moveId);

  if(moveSteps.length < 2) return null;

  return (
    <div className={CSS.container}>
      <div className={classNames(CSS.move_steps_holder)}>
        { moveSteps.map(step => (
          <MoveStepLink key={step.id} step={step} />
        ))}
      </div>
    </div>
  );
};

const MoveStepLink = ({ step }) => {
  const { moveId, stepId } = useParams();
  return (
    <Link
      to={`/moves/${moveId}/steps/${step.id}`}
      className={classNames(CSS.step_link,{[CSS.active]:step.id === parseInt(stepId)})}
    >
      { get(step,'building.dashboard_image_url') && (
        <div className={CSS.thumbnail} style={{
          backgroundImage: `url('${get(step,'building.dashboard_image_url')}')`
        }} />
      )}
      <div className={CSS.column}>
        <h6 className={CSS.move_step_label}>
          { step.display_name }
        </h6>
        <h2 className={CSS.move_step_building}>
          { get(step,'building.settings.display_name') || get(step,'building.name') }
        </h2>
      </div>
      <div className={CSS.move_step_progress}>
        <ProgressRing
          progress={get(step,'move_tasks',[]).filter(task => task.requirement === 'required' && task.status === 'complete').length}
          total={get(step,'move_tasks',[]).filter(task => task.requirement === 'required').length}
        />
      </div>
    </Link>
  );
};
