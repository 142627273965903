import { MarketplaceWrapper } from './components/MarketplaceWrapper';
import { Marketplace } from './components/Marketplace';
import { withContactForm } from '../contact';

import { four04Route } from '../common/routes';
import tasksRoutes from '../tasks/routes';

const routes = [
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: withContactForm(MarketplaceWrapper),
    transition: 'crossFade',
    viewKey: () => `marketplace`,
    children: [
      {
        path: '/',
        name: 'Marketplace Dashboard',
        component: Marketplace,
        exact: true,
        viewKey: ({moveId}) => `marketplace-${moveId}`,
        transition: 'scaleOut',
      },
      ...tasksRoutes,
      four04Route,
    ]
  },
];

export default routes;
