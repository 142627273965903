import { merge } from 'lodash';

import * as create from './create';
import * as createRequest from './createRequest';
import * as get from './get';
import * as getProviderLink from './getProviderLink';
import * as update from './update';
import * as updateRequest from './updateRequest';
import * as submitRequest from './submitRequest';
import * as verifyEpremiumPolicy from './verifyEpremiumPolicy';

export const reducers = [
  create.reducer,
  createRequest.reducer,
  get.reducer,
  getProviderLink.reducer,
  update.reducer,
  updateRequest.reducer,
  submitRequest.reducer,
  verifyEpremiumPolicy.reducer,
];

export const initialState = merge(
  create.initialState,
  get.initialState,
  getProviderLink.initialState,
);
