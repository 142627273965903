import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CORE_EDIT_SNIPPETS',
  request: (id, data={}) => request.post(`/buildings/${id}/snippets`, data).then(r => r.data.data.snippet_map),
  reducers: {
    success: (state, { response: snippets, params: [id] }) => ({
      ...state,
      snippetMap: {
        ...state.snippetMap,
        [id]: snippets
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useEditSnippetsPending,
} = generate.redux(creator);

export const initialState = {
  snippets: {},
};
