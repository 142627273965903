import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_TASK_SUMMARY',
  request: (id) => request.get(`/residents/building-move-task-summaries/${id}`).then(r => get(r, 'data.data.building_move_task_summary')),
  selector: (state, id) => get(state,`taskSummaries.${id}`),
  reducers: {
    success: (state, { response: taskSummary, params: [id] }) => {
      return {
        ...state,
        taskSummaries: {
          ...state.taskSummaries,
          [id]: taskSummary,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useTaskSummary,
  usePending: useTaskSummaryPending,
} = generate.redux(creator);

export const initialState = {
  taskSummaries: {},
};
