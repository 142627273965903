import * as create from './create';
import * as get from './get';
import * as createRequest from './createRequest';


export const reducers = [
  create.reducer,
  get.reducer,
  createRequest.reducer,
];

export const initialState = {
  payMoveInCostsTasks: {},
};
