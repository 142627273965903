import { merge } from 'lodash';

import * as getBuilding from './getBuilding';

export const initialState = merge(
  getBuilding.initialState,
);

export const reducers = [
  getBuilding.reducer,
];
