import React from 'react';
import classNames from 'classnames';

import { format } from '@moved/services';

import { TagRenderer } from '../../../TagRenderer';

import CSS from './Text.module.scss';

export const TextDisplay = ({ data:{ text='', color='secondary' }, tool, tags }) => (
  <TagRenderer
    as='p'
    content={text}
    tags={tags}
    className={classNames(CSS.text,`labelM content${format.capitalize(color)}`)}
  />
);
