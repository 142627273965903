import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_SOLD_OUT_DATES',
  request: (moveId) => request.get(`/moves/${moveId}/sold-out-dates`).then(r => r?.data?.data?.sold_out_dates),
  selector: (state, moveId) => state?.soldOutDates?.[moveId],
  reducers: {
    success: (state, { response: dates, params: [moveId] }) => {
      return {
        ...state,
        soldOutDates: {
          ...state.soldOutDates,
          [moveId]: dates,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useSoldOutDates,
  usePending: useSoldOutDatesPending,
} = generate.redux(creator);

export const initialState = {
  soldOutDates: {},
};
