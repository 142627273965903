import React from 'react';
import classNames from 'classnames';

import CSS from './styles/TimePickerItem.module.scss';

export const TimePickerItem = ({ time, reserved, className }) => {
  return (
    <div className={classNames(CSS.wrapper,className)}>
      <span className={CSS.time_number}>{ time }</span>
      { reserved && (
        <div className={CSS.time_reserved}>Reserved</div>
      )}
    </div>
  );
};
