import React from 'react';
import Emoji from "react-emoji-render";

import CSS from './styles/ProgressBar.module.scss';

export const ProgressBar = ({ message, items }) => {

  if(!message) message=":clap: You're on top of things - keep it up!";

  const _completeCount = (items) => {
    let number = 0;
    items.forEach((item) => {
      if(item.status === "complete") number++;
    })
    return number;
  };

  const _renderDash = (complete, total) => {
    let dashArr = [];
    for(let i = 0; i < total; i++) {
      dashArr.push(
        <span key={i} className={i < complete ? CSS.complete : ""} />
      );
    }
    return dashArr;
  };

  const _progressStatus = (complete, total) => {
    const percentage = complete/total;
    let status = null;
    if(percentage >= 1) {
      status = 'done';
    } else if(percentage >= 0.5) {
      status = 'half_or_more';
    } else if(percentage > 0) {
      status = 'less_than_half';
    } else {
      status = 'none';
    }
    return status;
  };

  const complete = _completeCount(items),
        total = items.length,
        status = _progressStatus(complete,total);

  return (
    <div className={[CSS.area, status === 'done' ? CSS.done : ''].join(' ')}>
      <div className={CSS.caption}>
        <span><Emoji text={message}/></span>
        <span className={CSS.numbers}>{complete}/{total}</span>
      </div>
      <div className={CSS.bar}>
        {_renderDash(complete, total)}
      </div>
    </div>
  );
}
