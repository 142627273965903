import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_CABLE_INTERNET_TASK',
  request: (id) => request.get(`/residents/cable-internet-tasks/${id}`).then(r => get(r, 'data.data.cable_internet_task')),
  selector: (state, id) => get(state,`cableInternetTasks.${id}`),
  reducers: {
    success: (state, { response: cableInternetTask }) => {
      return {
        ...state,
        cableInternetTasks: {
          ...state.cableInternetTasks,
          [cableInternetTask.id]: cableInternetTask,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useCableInternetTask,
  usePending: useGetCableInternetTaskPending,
} = generate.redux(creator);

export const initialState = {};
