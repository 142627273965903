import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useModal } from '@moved/services';
import { Card, Icon } from '@moved/ui';

import { CreateCustomTaskModal } from './CreateCustomTaskModal';
import { ExploreTasksModal } from './ExploreTasksModal';
import { IntroModal } from './IntroModal';

import CSS from './styles/GettingStarted.module.scss';

export const GettingStarted = ({ taskList, className }) => {
  const Modal = useModal();
  const { moveId } = useParams();

  const learnMore = () => {
    Modal.open(
      <IntroModal taskListId={taskList.id} />,
      { sondheim: true }
    );
  }

  const exploreTasks = () => {
    Modal.open(
      <ExploreTasksModal taskListId={taskList.id} moveId={moveId} />,
      { glass: true }
    );
  };

  const createCustomTask = () => {
    Modal.open(
      <CreateCustomTaskModal taskListId={taskList.id} moveId={moveId} />,
      { sondheim: true }
    );
  }

  const completeIcon = { symbol: 'Done-circle', library: 'code' };

  const steps = [
    {
      name: 'dashboard',
      title: 'Create your dashboard',
      description: 'Congrats! You\'re already on your way.',
      icon: completeIcon,
      isComplete: true, // complete by definition because they are on this page
    },
    {
      name: 'video',
      title: 'Make yourself at home',
      description: 'Learn how to use this space.',
      icon: { symbol: 'Bulb#1', library: 'home' },
      isComplete: taskList?.progress?.video === 1,
      onClick: learnMore,
    },
    {
      name: 'explore_tasks',
      title: 'Explore tasks',
      description: 'We\'ve done this thousands of times.',
      icon: { symbol: 'Binocular', library: 'general' },
      isComplete: taskList?.progress?.explore_tasks === 1,
      onClick: exploreTasks,
    },
    {
      name: 'first_task',
      title: 'Create your first custom task',
      description: 'It\'s your move, make it your own.',
      icon: { symbol: 'Plus', library: 'code' },
      isComplete: taskList?.progress?.first_task === 1,
      onClick: createCustomTask,
    },
  ];

  return (
    <div className={className}>
      <div className='stackVertical-24'>
        <div className='stackVertical'>
          <h3 className='displayS contentPrimary'>Getting started</h3>
          <p className='labelM contentSecondary'>Get organized for your upcoming move by managing all of your personal tasks in one place.</p>
        </div>
        <div className='stackHorizontal-24 stackVertical--mobile'>
          { steps.map(step => (
            <Card
              key={step.title}
              className={classNames(
                CSS.card,
                {
                  [CSS.complete]:step.isComplete,
                  [CSS.clickable]:step.onClick != null,
                }
              )}
              onClick={step.onClick}
            >
              <Icon {...(step.isComplete ? completeIcon : step.icon)} size='40px' className='marginBottom-40' />
              <div className='stackVertical-4'>
                <h4 className='headingS contentPrimary'>{ step.title }</h4>
                <p className='labelS contentSecondary'>{ step.description }</p>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};
