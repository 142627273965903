import { get } from 'lodash';
import { format } from '@moved/services';
import { Icon } from '@moved/ui';

export const getOverrideFields = override => {
  if(!override) return [];
  return [
    ...(get(override,'content',[])
      .filter(chapter => Object.keys(get(override,'progress',{})).includes(chapter.slug))
      .map(chapter => ({
        icon: { symbol: 'File', library: 'files' },
        label: chapter.title,
        value: (
          <Icon symbol='Done-circle' library='code' size='24px' />
        ),
      })
    )),
    get(override,'notes') && {
      label: 'Notes',
      value: get(override,'notes'),
      icon: { symbol: 'Chat-check', library: 'communication' },
    },
    {
      label: 'Completed by',
      value: format.fullname(get(override,'created_by_user')),
      icon: { symbol: 'User', library: 'general' },
    },
  ].filter(v=>v);
};
