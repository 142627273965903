import React from 'react';

import { format } from '@moved/services';
import { DataBar } from '@moved/ui';

// TODO: incorporate these assets and styles once we have Onboarding Wizard to populate the whole journey.
// Also, need to upgrade DataBar to support the extra markup
// import mapIcon from './assets/map-icon.png';
// import CSS from './styles/MoveData.module.scss';

export const MoveData = ({ moveSummary, className }) => {
  const originAddress = moveSummary.move_step_summaries?.find(({type}) => type === 'move-out')?.address ?? moveSummary?.origin_address;
  const destinationAddress = moveSummary.move_step_summaries?.find(({type}) => type === 'move-in')?.address ??  moveSummary?.destination_address;
  const moveDate = moveSummary?.move_step_summaries?.find(({date}) => date != null)?.date;
  return (
    <DataBar
      size='small'
      className={className}
      attributes={[
        originAddress && {
          key: 'origin',
          label: 'Origin',
          value: format.address(originAddress),
        },
        destinationAddress && {
          key: 'destination',
          label: 'Destination',
          value: format.address(destinationAddress),
        },
        moveDate && {
          key: 'date',
          label: 'Date',
          value: format.date(moveDate),
        },
      ].filter(Boolean)}
    />
  );
};
