import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './Card.module.scss';

export const Card = ({ className, onClick, children }) => {
  return (
    <div className={classNames(CSS.card, className)} onClick={onClick}>
      {children}
    </div>
  );
};

Card.propTypes = {
  /** The content to be rendered in the Card */
  children: PropTypes.node.isRequired,
  /** Forward any click event handlers */
  onClick: PropTypes.func,
  /** Custom class name */
  className: PropTypes.string,
};
