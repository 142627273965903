import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { LoaderOverlay } from '@moved/ui';

import CSS from './styles/AllConnect.module.scss';

export const AllConnect = ({ address={}, options={}, building }) => {

  const history = useHistory();

  // handle loading and unloading of the sdk and the inline styles it initiates.
  useEffect(() => {
    // this library requires a query param to be present on the referrer page for development
    // in order to know which 'production' account it is referencing
    if(process.env.REACT_APP_ENV_NAME !== 'prod') history.replace({
      search: 'mf_debug_url=https://my.moved.com/'
    });
    // cleanup the artifacts imported by this library on unmount
    return () => {
      // allow subsequent loads to reinitialize
      window.mfInitialized = false;
      // need to remove styles imported automatically by this library
      const styleImportElement = document.getElementById('inline-widget-css');
      if(styleImportElement) styleImportElement.parentNode.removeChild(styleImportElement);
    }
  },[]); // eslint-disable-line

  // AllConnect provides no lifecycle callbacks so we have no idea when the final
  // rendered results are displayed. This block pads the loading of the component by
  // 3 seconds. We show the LoaderOverlay during this time because the iFrame render
  // is very clunky.
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    },3000);
    return () => clearTimeout(timeout);
  },[]);


  return (
    <div className={classNames(CSS.wrapper)}>
      <Helmet>
        <script
          id="myFinance-widget-script"
          src="https://static.myfinance.com/widget/inlineMedia_core.js"
          type="text/javascript"
          onload="window.initializeInline({loadInlineCss:true});"
        />
      </Helmet>

      { isLoading && <LoaderOverlay/>}

      {/* id based container for the widget to inject into */}
      <div className="myFinance-widget"
        data-creative-set-id="721a4a72-06b9-4d93-bcd8-6bd55544cad6"
        data-campaign="chameleon-moved-zip-detected-providers"
        data-sub-id={building?.partner?.id}
        data-sub-id2={building?.id}
        data-sub-id3='widget'
        data-street={address.street}
        data-city={address.city}
        data-state={address.state}
        data-apt-num={address.unit}
        data-zip={address.zipcode}
      />

    </div>
  );
};
