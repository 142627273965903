import { BookMovers } from '../book-movers/definition';

import * as actions from './actions';
import * as selectors from './actions/selectors';

export class BookMoversStorage extends BookMovers {
  constructor() {
    super({
      actions,
      selectors:  {
        useTaskable: selectors.useMergedMoverBookingTask,
        ...selectors,
      }
    });
    this.isStorage = true;
  }

  static slug = 'book-movers-for-storage';

  /* START OVERRIDES */
  _flowBuilder(moverBookingTask) {
    return super._flowBuilder(this.getMoverBooking(moverBookingTask), moverBookingTask);
  }
  getMoverBooking(moverBookingTask) {
    return moverBookingTask?.mover_booking;
  }
  updateMoverBookingForTask(moverBooking, moverBookingTask) {
    return {
      ...moverBookingTask,
      mover_booking: moverBooking
    };
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
