import * as Yup from 'yup';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { useModal, useNotify, format } from '@moved/services';
import { Button, Card, Collapsible, Form, FormButton, FormPhone, FormRadioList, FormText, PhoneValidation, Icon, ModalTitle, ModalContent, ModalActions } from '@moved/ui';

import { DateTimeSelect } from './DateTimeSelect';
import { createReminder } from '../../actions/createReminder';
import { getTask } from '../../actions/getTask';

import CSS from './ReminderSelectModal.module.scss';

const reminderOptions = [
  { value: 'email', label: 'Email' },
  { value: 'sms', label: 'Text message' },
];

// Sanitize the phone number initially
const sanitizePhoneNumber = (phone) => phone.replace(/\D/g, '').slice(0, 10);

export const ReminderSelectModal = ({ taskDetails }) => {
  const taskId = taskDetails.id;
  const reminder = taskDetails.reminder;

  const [selectedReminderDatetime, setSelectedReminderDatetime] = useState(reminder?.reminder_datetime);
  const [selectedReminderMethod, setSelectedReminderMethod] = useState(reminder?.communication_method);
  const [isDateTimeSelectOpen, setIsDateTimeSelectOpen] = useState(false);
  const [contactPhone, setContactPhone] = useState(sanitizePhoneNumber(taskDetails?.phone_number || ''));
  const [pending, setPending] = useState(false);

  const dispatch = useDispatch();
  const Notify = useNotify();
  const { close } = useModal();

  const validationSchema = Yup.object({
    contact_phone: Yup.string().when('communication_method', {
      is: 'sms',
      then: PhoneValidation.required('Required'),
    }),
  });

  const isMethodSelected = ( selectedReminderMethod === 'email' || (selectedReminderMethod === 'sms' && contactPhone) );

  const handleSubmit = (data) => {
    if (pending) return;
    setPending(true);

    const utcReminderDatetime = moment(selectedReminderDatetime).utc().format();

    const formData = {
      reminder_datetime: utcReminderDatetime,
      communication_method: selectedReminderMethod,
      ...(selectedReminderMethod === 'sms' && { contact_phone: data.contact_phone }),
    };

    dispatch(createReminder(taskId, formData))
      .then(() => dispatch(getTask(taskId)))
      .then(() => close(false))
      .catch((err) => {
        setPending(false);
        Notify.error(format.error(err));
      });
  };

  return !isDateTimeSelectOpen ? (
    <Form
      name='reminder-select'
      validation={validationSchema}
      onSubmit={handleSubmit}
    >
      <div style={{ minWidth: '450px' }}>
        <ModalTitle>Set reminder date</ModalTitle>
        <ModalContent className='paddingTop-24 paddingBottom-24'>
          <Card className='stackHorizontal items-center justify-between'>
            <div className='stackHorizontal gap-12 items-start flex-grow'>
              <Icon library='home' symbol='Alarm-clock' />
              <FormText
                className='flex-grow padding-0'
                name='reminder_datetime'
                value={selectedReminderDatetime ? moment(selectedReminderDatetime).format('MMMM Do, YYYY - hh:mm A') : 'Set reminder'}
                readOnly={true}
              />
            </div>
            <Button color='tertiary' size='small' onClick={() => setIsDateTimeSelectOpen(true)} text='Change' />
          </Card>

          <div className='paddingTop-32'>
            <h3 className='headingS contentPrimary'>Send me reminder via</h3>
            <FormRadioList
              name='communication_method'
              options={reminderOptions}
              value={selectedReminderMethod}
              className={classNames('paddingTop-16', CSS.reminder_methods)}
              onChange={({ communication_method }) => {
                setSelectedReminderMethod(communication_method);
              }}
            />
          </div>

          <Collapsible open={selectedReminderMethod === 'sms'}>
            <FormPhone
              name='contact_phone'
              label='Phone number'
              value={contactPhone}
              className={'marginTop-16'}
              onChange={(e) => setContactPhone(sanitizePhoneNumber(e?.target?.value || e.contact_phone || ''))}
            />
          </Collapsible>
        </ModalContent>

        <ModalActions>
          <FormButton
            text='Cancel'
            onClick={() => close(false)}
            color='secondary'
          />
          <FormButton
            text='Set Reminder'
            type='submit'
            disabled={
              pending ||
              !selectedReminderDatetime ||
              !isMethodSelected ||
              (selectedReminderMethod === 'sms' && !contactPhone)
            }
          />
        </ModalActions>
      </div>
    </Form>
  ) : (
    <div>
      <ModalTitle>Select custom date & time</ModalTitle>
      <ModalContent className='paddingTop-24'>
        <DateTimeSelect
          name='reminder_datetime'
          datetime_value={selectedReminderDatetime ? moment(selectedReminderDatetime).toDate() : null}
          onNext={({ reminder_datetime }) => {
            setSelectedReminderDatetime(reminder_datetime);
            setIsDateTimeSelectOpen(false);
          }}
          onCancel={() => setIsDateTimeSelectOpen(false)}
        />
      </ModalContent>
    </div>
  );
};
