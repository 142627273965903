import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { FileTypeIcon } from '../../../FileTypeIcon';
import download from './download.svg';
import CSS from './Attaches.module.scss';

export const AttachesDisplay = ({ data, tool, tags }) => {
  const { file, title } = data;
  return (
    <div className={classNames('cdx-block',tool.className,CSS.render)}>
      <a
        href={file.url}
        target={"_blank"}
        rel="noopener noreferrer"
        className={classNames(CSS.button)}
        download={file.name}
      >
        <FileTypeIcon type={file.extension} size={30} className={CSS.type_icon} />
        <span>{title ? title : 'Download ' + get(file,'name')}</span>
        <img alt={'Download'} src={download} className={CSS.icon} />
      </a>
    </div>
  );
};
