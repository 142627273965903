import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_PET_TYPES',
  request: () => request.get(`/pet-types`).then(r => get(r,'data.data.pet_types')),
  selector: (state) => get(state,`petTypes`),
  reducers: {
    success: (state, { response: types }) => {
      return {
        ...state,
        petTypes: types,
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: usePetTypes,
  usePending: usePetTypesPending,
} = generate.redux(creator);

export const initialState = {
  petTypes: [],
};
