import React from 'react';
import classNames from 'classnames';
import { request } from '@moved/services';
import { unescape } from 'lodash';

import { AppStoreLink } from '../../../../../sondheim/components/AppStoreLink';

import CSS from './AppStoreLink.module.scss';

export const AppStoreLinkDisplay = ({ data, tool, tags }) => {
  const { stores, appleUrl, googleUrl } = data;
  const trackClick = (store) => {
    const { move_id, move_step_id, content_editor_id } = tags;
    if(!content_editor_id || !move_id) return;
    request.post(`/residents/content-editor-contents/${content_editor_id}/link-clicks`,{
      move_id,
      move_step_id,
      url: store === 'apple' ? unescape(appleUrl) : unescape(googleUrl),
      cta_text: store === 'apple' ? 'Apple App Store Link' : 'Google App Store Link',
    });
  };
  return (
    <div className={classNames(CSS.render,tool.className)}>
      { stores.includes('apple') && <AppStoreLink type='apple' link={unescape(appleUrl)} onClick={() => trackClick('apple')} height={44} /> }
      { stores.includes('google') && <AppStoreLink type='google' link={unescape(googleUrl)} onClick={() => trackClick('google')} height={44} /> }
    </div>
  );
};
