import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_SUBMIT_BUILDING_RESERVATION_APPOINTMENT',
  request: (id, data={}) => request.post(`/building-reservation-requests/${id}/submit`, data).then(r => r.data.data.building_reservation_task),
  reducers: {
    success: (state, { response: reservation }) => {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          [reservation.id]: reservation,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useSubmitRequestPending,
} = generate.redux(creator);
