import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_SECURITY_DEPOSIT_PAYMENT',
  request: (id, data={}) => request.post(`/security-deposits/${id}/payments`, data).then(r => get(r,'data.data.security_deposit')),
  reducers: {
    success: (state, { response: securityDeposit }) => {
      return {
        ...state,
        securityDeposits: {
          ...state.securityDeposits,
          [securityDeposit.id]: securityDeposit,
        },
      }
    }
  }
}

export const {
  action,
  reducer,
  usePending: usePaymentPending,
} = generate.redux(creator);
