/*
*  Input an array of discounts for a move and returns a formatted display string
*  of the total discount for the move.
*
*  examples:
*  $100
*  10%
*  10% and $100
*/
import { currency } from './currency';
export const discountTotal = (discounts = []) => {
  if(!discounts.length) return '';

  // group discounts by type
  const percentDiscounts = discounts.filter(discount => discount.type === 'percent');
  const flatDiscounts = discounts.filter(discount => discount.type === 'cents');

  // sum the total for each type
  const percentTotal = percentDiscounts.length ? percentDiscounts.reduce((total, discount) => total + discount.percent, 0) : 0;
  const flatTotal = flatDiscounts.length ? flatDiscounts.reduce((total, discount) => total + discount.cents, 0) : 0;

  // format each type into it's display value
  const displayValues = [
    percentTotal && `${percentTotal}%`,
    flatTotal && currency(flatTotal),
  ].filter(v => v);

  // return the joined string
  return displayValues.length ? displayValues.join(' and ') : '';
};
