import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_SUBMIT_ORIENTATION_REQUEST',
  request: (id, data={}) => request.post(`/residents/orientation-requests/${id}/submit`, data).then(r => r.data.data.user_orientation_progress),
  reducers: {
    success: (state, { response: orientation }) => {
      return {
        ...state,
        orientation: {
          ...state.orientation,
          [orientation.id]: orientation,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useSubmitOrientationRequestPending,
} = generate.redux(creator);
