import { base } from '../base';

import { getArtifactFields } from './getArtifactFields';
import { getArtifacts } from './getArtifacts';
import { getRequestFields } from './getRequestFields';
import { getSummaryFields } from './getSummaryFields';

export const moveOutInspection = {
  ...base,
  getArtifactFields,
  getArtifacts,
  getRequestFields,
  getSummaryFields,
};
