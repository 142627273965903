import classNames from 'classnames';
import { get } from 'lodash';

import { TagRenderer } from '../../../TagRenderer';

import CSS from './TitleBar.module.scss';

export const TitleBarDisplay = ({ data, tool, tags }) => (
  <div className={classNames('cdx-block',tool.className,CSS.render, { [CSS.background]: data.withBackground })}>
    <img className={classNames(CSS.image)} src={get(data,'file.url')} alt={data.caption}/>
    <TagRenderer as='p' className={CSS.caption} content={data.caption} tags={tags} />
  </div>
);
