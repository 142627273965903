import { PersonalTaskList } from './contexts/PersonalTaskList';
import { Dashboard } from './components/Dashboard';
import { TaskDetails } from './components/TaskDetails';
import { withContactForm } from '../contact';

const routes = [
  {
    path: '/personal-tasks',
    name: 'DashboardLanding',
    component: withContactForm(PersonalTaskList),
    transition: 'crossFade',
    viewKey: ({moveId}) => `move-${moveId}-tasks`,
    children: [
      {
        path: '/',
        name: 'PersonalTasksDashboard',
        component: Dashboard,
        viewKey: () => 'personal-tasks-dashboard',
        // exact: true,
        // transitions: {
        //   in: 'scaleOut',
        //   out: 'scale',
        // },
        children: [
          {
            path: '/:taskId(\\d+)',
            name: 'PersonalTasksDashboard',
            component: TaskDetails,
            viewKey: () => 'personal-tasks-dashboard',
            exact: true,
          },
        ]
      },
    ],
  },
];

export default routes;
