import { create } from '../../moving-plan/actions';
import { createMoverBooking } from '../../book-movers/actions';

// Custom action creator that combines two existing actions in a promise chain
// create the moverBookingTask and automatically create the moverBooking too
export const action = (moveTaskId) => {
  return (dispatch) => dispatch(create(moveTaskId))
      .then(
        moverBookingTask => dispatch(createMoverBooking(moverBookingTask.move_id, { mover_booking_task_id: moverBookingTask.id }))
          .then(
            moverBooking => ({ ...moverBookingTask, mover_booking: moverBooking })
          )
      );
};
