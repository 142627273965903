import React from 'react';

import CSS from './styles/MoversAnimation.module.scss';

export const MoversAnimation = () => {
  return (
    <div className={CSS.container}>
      <div className={CSS.pin}></div>
      <div className={CSS.pinShadow}></div>
      <div className={CSS.truckOne}></div>
      <div className={CSS.truckTwo}></div>
    </div>
  );
};
