import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_SUBMIT_COI_REQUEST',
  request: (id, data={}) => request.post(`/residents/coi-uploads/${id}/submit`, data).then(r => r.data.data.coi_submission),
  reducers: {
    success: (state, { response: coiSubmission }) => {
      return {
        ...state,
        coiSubmissions: {
          ...state.coiSubmissions,
          [coiSubmission.id]: coiSubmission,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useSubmitCoiRequestPending,
} = generate.redux(creator);
