import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

export const getArtifactFields = artifact => [
  {
    label: 'Submitted COI',
    value: get(artifact,'file_url') && (<a href={get(artifact,'file_url')} target="_blank" rel="noopener noreferrer">View File</a>),
    icon: { symbol: 'Uploaded-file', library: 'files' },
  },
  get(artifact,'created_at') && {
    label: 'Date approved',
    value: format.date(get(artifact,'created_at')),
    icon: { symbol: 'Time', library: 'code' },
  },
  {
    label: 'Completed by',
    value: get(artifact,'created_by_user') && format.fullname(get(artifact,'created_by_user')),
    icon: { symbol: 'User', library: 'general' },
  },
].filter(Boolean);
