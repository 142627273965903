import { BuildingMoveTaskFlow } from '../../components/BuildingMoveTaskFlow';
import { screens } from './screens';

export const routes = [
  {
    path: `/:screenSlug(${Object.values(screens).map(screen => screen.slug).filter(v=>v).join('|')})/:screenContext(\\d+)?`,
    name: 'taskFlowScreen',
    component: BuildingMoveTaskFlow,
    viewKey: ({taskType,id}) => `task-${taskType}-${id}`,
    exact: true,
  },
];
