import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_RESERVATION',
  request: (id) => request.get(`/building-reservation-tasks/${id}`).then(r => get(r,'data.data.building_reservation_task')),
  selector: (state, id) => get(state,`reservations.${id}`),
  reducers: {
    success: (state, { response: reservation }) => {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          [reservation.id]: reservation,
        }
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useReservation,
  usePending: useReservationPending,
} = generate.redux(creator);
