
// private convenience function for sorting moveSteps
const stepTypeOrder = ['move-out','move-in'];
const stepSort = (a,b) => stepTypeOrder.indexOf(a?.type) - stepTypeOrder.indexOf(b?.type);

// Use this to reconstruct the dashboard route if you already have the context
export const getDashboardRoute = (moveId, moveStepId) => {
  if(moveStepId) return `/moves/${moveId}/steps/${moveStepId}`;
  return `/moves/${moveId}/personal-tasks`;
};

// Use this if you don't know which dashboard already
export const getRecommendedDashboardRoute = (move) => {
  // sort and select the first move_step to be the active one (fail safe if no move_steps)
  const activeMoveStep = (move?.move_steps ?? []).sort(stepSort)[0];
  return getDashboardRoute(move?.id, activeMoveStep?.id);
};

export const shouldTriggerOnboardingWizard = (move, building) => {
  return (
    // only launch the wizard if the move is not a double sided move and the building's feature flag is enabled
    (move?.move_steps?.length === 1 && building?.settings?.dashboard_wizard_enabled === true) ||
    // or if the move has no steps (consumer direct move)
    (move?.move_steps?.length === 0)
  );
};
