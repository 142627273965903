import { useModal } from '@moved/services';
import { Button, ModalTitle, ModalContent, ModalActions } from '@moved/ui';

export const LegalDisclaimerModal = ({
  legalText,
}) => {
  const Modal = useModal();
  return (
    <section className='stackVertical-24' style={{ maxWidth: '650px' }}>
      <ModalTitle>Terms &amp; Conditions</ModalTitle>
      <ModalContent>
        <p className='contentSecondary'>
          {legalText}
        </p>
      </ModalContent>
      <ModalActions>
        <Button text='OK' onClick={Modal.close} />
      </ModalActions>
    </section>
  );
};
