import { get } from 'lodash';
import { format } from '@moved/services';

// Get the primary policy holder
const getPrimaryPolicyHolder = artifact => {
  return get(artifact,'covered_tenants',[]).find(tenant => get(tenant,'is_primary_policyholder'));
};

export const getArtifactFields = artifact => {
  if(!artifact) return [];
  const primaryTenant = getPrimaryPolicyHolder(artifact);
  return [
    {
      label: 'Provider',
      value: get(artifact,'insurance_provider'),
      icon: { symbol: 'Home', library: 'home' },
    },
    get(artifact,'liability_coverage_amount') && {
      label: 'Liability Coverage',
      value: format.currency(get(artifact,'liability_coverage_amount')),
      icon: { symbol: 'Shield-check', library: 'general' },
    },
    get(artifact,'policy_number') && {
      label: 'Policy number',
      value: get(artifact,'policy_number'),
      icon: { symbol: 'File', library: 'files' },
    },
    primaryTenant && {
      label: 'Primary policy holder',
      value: `${primaryTenant.firstname} ${primaryTenant.lastname}`,
      icon: { symbol: 'User', library: 'general' },
    },
    get(artifact,'covered_tenants',[]).length > 0 && {
      label: 'Covered residents',
      value: get(artifact,'covered_tenants',[])
        .map(tenant => `${tenant.firstname} ${tenant.lastname}`)
        .join(', '),
      icon: { symbol: 'Group', library: 'communication' },
    },
    get(artifact,'coverage_start') && {
      label: 'Start date',
      value: format.date(get(artifact,'coverage_start'),'date'),
      icon: { symbol: 'Date', library: 'code' },
    },
    get(artifact,'coverage_end') && {
      label: 'End date',
      value: format.date(get(artifact,'coverage_end'),'date'),
      icon: { symbol: 'Date-to', library: 'code' },
    },
    get(artifact,'file_urls.length') > 0 && {
      label: 'Submitted documents',
      value: format.fileList(get(artifact,'file_urls',[])),
      icon: { symbol: 'Uploaded-file', library: 'files' },
    },
  ].filter(v=>v);
};
