import React from 'react';
import { merge } from 'lodash';

import { ContentRenderer } from '@moved/ui';

import { useResidentTags } from '../hooks/useResidentTags';

export const ResidentContentRenderer = ({
  content,
  tags = {},
  className,
}) => {
  const residentTags = useResidentTags();
  return <ContentRenderer content={content} className={className} tags={merge({},residentTags,tags)}/>;
};
