import React from 'react';
import { ResidentTagRenderer } from '../../common';

import { useSnippet } from '../hooks/useSnippet';

export const Snippet = ({ tag, description, children }) => {
  const snippet = useSnippet(tag, description);
  return (
    <>
      { snippet ? <ResidentTagRenderer content={snippet} as={'span'} /> : children }
      {/* Shows tooltip with the snippet tag name if param is set to true */}
      { localStorage.getItem('snippetVisible') === 'true' && (
        <div style={{ position: 'absolute', backgroundColor: '#0370f3', padding: '6px 10px', borderRadius: '4px', color: '#fff', fontWeight: '600'}}>{tag}</div>
      )}
    </>
  );
};
