import React from 'react';

import { globals } from '@moved/services';

export const ResidentSupportEmailLink = ({ className }) => {
  const supportEmail = globals?.emails?.residentSupport;
  return (
    <a
      href={`mailto:${supportEmail}`}
      target='_blank'
      rel='noopener noreferrer'
      className={className}
    >
      {supportEmail}
    </a>
  );
};
