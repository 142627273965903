import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_BADGE_UPLOAD',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/badge-upload-tasks`, data).then(r => get(r,'data.data.badge_upload_task')),
  reducers: {
    success: (state, { response: badge }) => {
      return {
        ...state,
        badges: {
          ...state.badges,
          [badge.id]: badge,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCreateBadgeUploadPending,
} = generate.redux(creator);
