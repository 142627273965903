import { merge } from 'lodash';
import { reserve as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class Reserve extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useReservation,
        ...selectors,
      }
    });
  }

  static label = 'Reserve Move';
  static slug = 'reserve';
  static icon = 'Alarm-clock';
  static lockedMessage = 'Complete the tasks above before scheduling your move date.';
  static helpers = helpers;
  static config = {
    supportsArtifacts: true,
    supportsOnHoldArtifact: true,
  };

  _flowBuilder(taskData) {
    return [
      this.screens['reserve-time'],
      this.screens['summary'],
    ];
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: <>
              <p>
                Unfortunately the reservation you requested is no longer
                available. Submit a new request for a different time slot.
              </p>
              <p className='txt-center italic'>{request.notes}</p>
            </>,
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: <>
              Property Management has been notified of your requested move date
              &amp; time. You will be notified when your request is reviewed.
            </>,
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: (
              !taskDetails ?
                'Your move-in date reservation has been taken care of.' :
                'You are confirmed for your requested move date & time below.'
            ),
          },
          history: {
            title: 'Current Reservation',
            onHoldDisclaimer: `This is your currently active reservation. We won't cancel it until a new one is approved.`
          }
        };
        break;
      case 'override':
        requestCopy = {
          summary: {
            flavor: 'You have declined to reserve a move-in time.',
          }
        }
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
