import React from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';

import { Transitions } from '@moved/ui';

import { useMove } from '../../moves/actions/selectors';
import { VideoBG } from './VideoBG';

import CSS from './styles/SplashBG.module.scss';

export const SplashBG = ({ background, video, className, children }) => {
  const { moveId } = useParams();
  const move = useMove(moveId);
  const hasMultipleMoveSteps = get(move,'move_steps',[]).length > 1;

  return (
    <section id={'splash'} className={classNames(CSS.splash,className)}>
      <div className={classNames(CSS.transition_wrapper,'go-full-page-hook')}>
        <Transitions transition='crossFade' pageKey={background || video} className={CSS.transition}>
          <div className={CSS.splash_bg} style={{ backgroundImage: background ? `url(${background})`: 'none'}}>
            { video && <VideoBG video={video} /> }
          </div>
        </Transitions>
      </div>
      <div className={CSS.splash_container}>
        <div className={classNames(CSS.splash_content,{[CSS.navigation_padding]:hasMultipleMoveSteps && !video})}>
          {children}
        </div>
      </div>
    </section>
  );
};
