import { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { format, useNotify } from '@moved/services';
import { DatePickerDropdown } from '@moved/ui';

import { InputButton } from './InputButton';
import { updateTask } from '../../actions/updateTask';

export const DueDateInput = ({ taskDetails }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();

  const parsedDueDate = moment(taskDetails.due_date ?? 'none').isValid()
    ? moment(taskDetails.due_date).format('YYYY-MM-DD')
    : '';

  const [selectedDate, setSelectedDate] = useState(parsedDueDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const displayDueDate = (date) => {
    return date ? moment(date).format('MMM Do') : 'No due date';
  };

  const handleDueDateSubmit = (newDate) => {
    const formattedDueDate = newDate ? moment(newDate).format('YYYY-MM-DD') : '';

    dispatch(updateTask(taskDetails.id, { due_date: formattedDueDate }))
      .then(() => {
        setSelectedDate(newDate);
        setIsDatePickerOpen(false);
      })
      .catch(err => Notify.error(format.error(err)));
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  return (
    <div className='stackHorizontal gap-8 items-center' style={{ position: 'relative' }}>
      <div onClick={toggleDatePicker}>
        <InputButton
          text={displayDueDate(parsedDueDate)}
          icon={{ library: 'code', symbol: 'Date-to' }}
          onDelete={parsedDueDate ? () => handleDueDateSubmit('') : null}   // Only show X to delete if due date present
        />
      </div>
      {isDatePickerOpen && (
        <DatePickerDropdown
          title='Add due date'
          value={selectedDate}
          isControlled={true}
          onChange={setSelectedDate}
          onClose={toggleDatePicker}
          onSubmit={handleDueDateSubmit}
        />
      )}
    </div>
  );
};
