import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_PAY_MOVE_IN_COSTS',
  request: (id) => request.get(`/residents/pay-move-in-costs-tasks/${id}`).then(r => r.data.data.pay_move_in_costs_task),
  selector: (state, id) => get(state, `payMoveInCostsTasks.${id}`),
  reducers: {
    success: (state, { response: payMoveInCostsTask }) => ({
      ...state,
      payMoveInCostsTasks: {
        ...state.payMoveInCostsTasks,
        [payMoveInCostsTask.id]: payMoveInCostsTask,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: usePayMoveInCostsTask,
  usePending: usePayMoveInCostsTaskPending,
} = generate.redux(creator);
