import * as create from './create';
import * as complete from './complete';
import * as get from './get';
import * as update from './update';
import * as getOptionsList from './getOptionsList';


export const reducers = [
  create.reducer,
  complete.reducer,
  get.reducer,
  update.reducer,
  getOptionsList.reducer,
];

export const initialState = {
  addon_interests: {},
  options_list: [],
};
