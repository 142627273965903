import { merge } from 'lodash';

import * as create from './create';
import * as get from './get';
import * as update from './update';
import * as getOptionsList from './getOptionsList';


export const reducers = [
  create.reducer,
  get.reducer,
  update.reducer,
  getOptionsList.reducer,
];

export const initialState = merge(
  get.initialState,
  getOptionsList.initialState,
);
