import React from 'react';
import classNames from 'classnames';

import { TagRenderer } from '../../../TagRenderer';
import { Icon } from '../../../../../sondheim/components/Icon';

import CSS from './Heading.module.scss';

export const HeadingDisplay = ({ data, tool, tags }) => {
  const { text, level, showIcon, icon } = data;
  const { tag, classes } = tool.class.allLevels.find(l => l.number === level);

  return (
    <div className={CSS.wrapper}>
      { showIcon && (
        <Icon symbol={icon.symbol} library={icon.library} size='32px' />
      )}
      <TagRenderer
        as={tag}
        content={text}
        tags={tags}
        className={classNames(CSS.heading, classes)}
      />
    </div>
  );

};
