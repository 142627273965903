import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_UTILITY_REQUEST',
  request: (id, data={}) => request.patch(`/residents/utility-setup-requests/${id}`, data).then(r => r.data.data.utility_setup_task),
  reducers: {
    success: (state, { response: utilitySetups }) => {
      return {
        ...state,
        utilitySetups: {
          ...state.utilitySetups,
          [utilitySetups.id]: utilitySetups,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateUtilityRequestPending,
} = generate.redux(creator);
