import { get, isNil } from 'lodash';
import * as screenComponents from './components';

export const screens = {

  'select-appointment': {
    slug: 'appointment',
    label: 'Select appointment',
    title: 'Who will pick up your keys?',
    subtitle: 'Have another member of your household pick up your keys, or do it yourself',
    component: screenComponents.KeySelectAppointment,
    isCompleted: (data, keys) => {
      const activeRequest = keys.helpers.getActiveRequest(data);
      if (activeRequest && activeRequest.status !== 'draft') return true;

      return get(data,'responsibility') === 'self' || !isNil(get(data,'current_tenant_key.key_pickup_appointment_id'))
  },
  },

  'select-roommates': {
    slug: 'roommates',
    label: 'Roommate keys',
    title: 'Would you like to pick up any other keys?',
    subtitle: 'If yes, select the name of any member of your household that you will pick up keys for. Otherwise, you will only be given your own keys at your appointment.',
    component: screenComponents.KeySelectRoommates,
    isCompleted: (data, keys) => {
      const activeRequest = keys.helpers.getActiveRequest(data);
      if (activeRequest && activeRequest.status !== 'draft') return true;
      return !isNil(get(activeRequest,'tenant_key_ids'))
    },
  },

  'select-calendar': {
    slug: 'type',
    label: 'Pick up option',
    title: 'How are you planning to pick up your keys?',
    component: screenComponents.KeySelectCalendarType,
    maxWidth: 800,
    isCompleted: (data, keys) => {
      const activeRequest = keys.helpers.getActiveRequest(data);
      if (activeRequest && activeRequest.status !== 'draft') return true;
      return !isNil(get(activeRequest,'building_calendar.booking_type'))
    },
  },

  'select-date': {
    slug: 'date',
    label: 'Pick up date',
    component: screenComponents.KeySelectDates,
    title: 'Select key pick up time',
    subtitle: 'Choose an available date and time below that works best for you.',
    maxWidth: 804,
    isCompleted: (data, keys) => {
      const activeRequest = keys.helpers.getActiveRequest(data);
      return activeRequest && activeRequest.status !== 'draft';
    },
  },

  'select-date-express': {
    slug: 'date-express',
    label: 'Pick up date',
    component: screenComponents.KeySelectDates,
    title: 'Select key pick up date',
    maxWidth: 804,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest && activeRequest.status !== 'draft';
    },
  },

  'summary': {
    slug: 'summary',
    label: 'Summary',
    component: screenComponents.KeyPickupSummary,
    backRestricted: true,
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest?.status === 'approved';
    },
  },

};
