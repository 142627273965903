import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_COMPLETE_UTILITIES',
  request: (id, data) => request.patch(`/utilities/${id}`, data).then(r => r.data.data),
  reducers: {
    success: (state, { response: utility }) => {
      return {
        ...state,
        utilities: {
          ...state.utilities,
          [utility.id]: utility,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCompletePending,
} = generate.redux(creator);
