import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'USER_UPDATE_USER',
  request: (data) => request.patch(`/users/current`, data).then(response => get(response,'data.data.user')),
};

export const {
  action,
  reducer,
  usePending: useUpdateUserPending,
} = generate.redux(creator);
