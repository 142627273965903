import React from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import { TagRenderer } from '../../../TagRenderer';
import CSS from './Image.module.scss';

export const ImageDisplay = ({ data, tool, tags }) => (
  <div className={classNames('cdx-block',tool.className,CSS.render, { [CSS.background]: data.withBackground })}>
    <img className={classNames({ [CSS.stretched]: data.stretched, [CSS.border]: data.withBorder })} src={get(data,'file.url')} alt={data.caption} />
    <TagRenderer as='p' className={CSS.caption} content={data.caption} tags={tags} />
  </div>
);
