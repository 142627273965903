import React, { useState } from 'react';
import classNames from 'classnames';

import { MenuIcon } from '@moved/ui';
import { LogoBlock } from '@moved/product';

import { Sidebar } from './Sidebar';

import CSS from './styles/MobileHeader.module.scss';

export const MobileHeader = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div className={CSS.spacer} />
      <div className={classNames(CSS.mobileHeader, 'stackVertical backgroundSecondary')}>
        <div className={CSS.headerBar}>
          <MenuIcon onChange={setShowMenu} className={CSS.menuIcon}/>
          <LogoBlock />
        </div>
        <div className={classNames(CSS.collapsible,{[CSS.expanded]:showMenu})}>
          <Sidebar expanded />
        </div>
      </div>
    </>
  );
}
