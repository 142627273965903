import {
  Instructions,
  Summary,
} from './components';

export const screens = {

  'instructions': {
    slug: 'instructions',
    component: Instructions,
    label: 'Instructions',
    title: 'Complete your move-in inspection',
    subtitle: 'Please ensure that you complete the move-in inspection immediately upon move-in',
    isCompleted: (taskable, { helpers }) => taskable.submitted_at || helpers.getActiveRequest(taskable)?.status === 'Complete',
  },

  'summary': {
    slug: 'confirmation',
    component: Summary,
    label: 'Confirmation',
    isCompleted: (taskable, { helpers }) => helpers.getActiveRequest(taskable)?.status === 'Complete',

  },

};
