import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_CREATE_DOCUMENT_SUBMISSION',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/document-submission-tasks`, data).then(r => get(r,'data.data.document_submission_task')),
  reducers: {
    success: (state, { response: docSubmission }) => {
      return {
        ...state,
        docSubmissions: {
          ...state.docSubmissions,
          [docSubmission.id]: docSubmission,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useCreateDocSubmissionPending,
} = generate.redux(creator);

export const initialState = {};
