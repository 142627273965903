import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useNotify, userService } from '@moved/services';

import { setPassword } from '../actions';
import { useSetPasswordPending } from '../actions/selectors';
import { SetPasswordForm } from './SetPasswordForm';

export const PasswordSet = (props) => {
  const { token } = useParams();
  const history = useHistory();
  const Notify = useNotify();
  const pending = useSetPasswordPending();

  const onComplete = (user) => {
    Notify.default(`Successfully set your password. Welcome, ${user.firstname}!`);
    userService.redirect(user, history);
  };

  return (
    <SetPasswordForm
      title='Set Your Password'
      subtitle={`You'll use this to sign in and access a dashboard of your upcoming move(s)!`}
      cta='Save Password'
      token={token}
      pending={pending}
      onSubmit={setPassword}
      onComplete={onComplete}
    />
  );
}
