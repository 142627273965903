import { merge } from 'lodash';

import * as getMarketplaceAds from './getMarketplaceAds';
import * as getMarketplaceAdRedirect from './getMarketplaceAdRedirect';

export const initialState = merge(
  getMarketplaceAds.initialState,
  getMarketplaceAdRedirect.initialState,
);

export const reducers = [
  getMarketplaceAds.reducer,
  getMarketplaceAdRedirect.reducer,
];
