import { merge } from 'lodash';

import * as cancelPetDetails from  './cancelPetDetails';
import * as create from './create';
import * as createPet from './createPet';
import * as createRequest from './createRequest';
import * as get from './get';
import * as getPetTypes from './getPetTypes';
import * as reset from './reset';
import * as submitRequest from './submitRequest';
import * as update from './update';
import * as updatePet from './updatePet';
import * as updateRequest from './updateRequest';

export const reducers = [
  cancelPetDetails.reducer,
  create.reducer,
  createPet.reducer,
  createRequest.reducer,
  get.reducer,
  getPetTypes.reducer,
  reset.reducer,
  submitRequest.reducer,
  update.reducer,
  updatePet.reducer,
  updateRequest.reducer,
];

export const initialState = merge(
  cancelPetDetails.initialState,
  create.initialState,
  createPet.initialState,
  createRequest.initialState,
  get.initialState,
  getPetTypes.initialState,
  reset.initialState,
  submitRequest.initialState,
  update.initialState,
  updatePet.initialState,
  updateRequest.initialState,
);
