import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { useNotify, format } from '@moved/services';
import { Button, CompleteCard, FieldList } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions
} from '../../shared';
import { Snippet } from '../../../../snippets';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

const statusMap = {
  Scheduled: {
    status: 'pending',
    title: 'In Progress',
    flavor: 'Thanks for starting your move-in inspection. Once it is confirmed completed in HappyCo, this task will update automatically within 24 hours.',
  },
  Draft: {
    status: 'pending',
    title: 'In Progress',
    flavor: 'Thanks for starting your move-in inspection. Once it is confirmed completed in HappyCo, this task will update automatically within 24 hours.',
  },
  Incomplete: {
    status: 'pending',
    title: 'Action Required',
    flavor: 'HappyCo has reviewed your inspection and requires you to take action. Click below to complete your move-in inspection.',
  },
  Expired: {
    status: 'cancelled',
    title: 'Link Expired',
    flavor: 'The link to complete your move-in inspection is no longer valid. Please reach out to your property to complete the inspection.',
  },
  Complete: {
    status: 'approved',
    title: 'Inspection Complete',
    flavor: 'Thanks for completing your move-in inspection!',
  },
}

export const Summary = ({ screen, taskDetails, taskDefinition, taskSummary, moveId, origin, previousScreen, onClose }) => {
  // HOOKS
  const dispatch = useDispatch();
  const Notify = useNotify();

  const { actions, helpers, slug: taskType } = taskDefinition;

  const overrides = helpers.getOverrides(taskSummary) || [];
  const inspection = overrides.length > 0 ? overrides[0].happyco_inspection : helpers.getActiveRequest(taskDetails);

  const {
    status,
    title,
    flavor,
  } = statusMap[inspection.status] || {};

  const linkToHappyco = () => {
    dispatch(actions.click(get(inspection,'id')))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => Notify.error(format.error(err)));
  };

  const attributes = [
    ...helpers.getRequestFields(inspection),
    {
      value: (
        <div className={taskCSS.start_over}>
          { status !== 'approved' && (
            <Button
              text='Continue Inspection'
              size='small'
              color='tertiary'
              onClick={linkToHappyco}
            />
          )}
          {taskDetails && (
            <Button
              text='Review Instructions'
              size='small'
              color='secondary'
              onClick={() => previousScreen()}
            />
          )}
        </div>
      ),
    },
  ].filter(v=>v);

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <CompleteCard
          status={status}
          title={(
            <Snippet tag={`tasks.${taskType}.summary.${status}.title`}>
              {title}
            </Snippet>
          )}
          flavor={(
            <Snippet tag={`tasks.${taskType}.summary.${status}.flavor`}>
              {flavor}
            </Snippet>
          )}
        >
          <FieldList list={attributes} />
        </CompleteCard>
      <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
      </ScreenContent>
    </ScreenLayout>
  )
};
