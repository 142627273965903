import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_ADDON_INTERESTS',
  request: (id) => request.get(`/addon-interests/${id}`).then(r => get(r,'data.data.addon_interests')),
  selector: (state, id) => get(state,`addon_interests.${id}`),
  reducers: {
    success: (state, { response: addonInterests }) => {
      return {
        ...state,
        addon_interests: {
          ...state.addon_interests,
          [addonInterests.id]: addonInterests,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useAddonInterests,
  usePending: useAddonInterestsPending,
} = generate.redux(creator);
