import { get } from 'lodash';

import * as screenComponents from './components';

export const screens = {

  'badge-image': {
    slug: 'image',
    label: 'Upload',
    title: 'Upload photo for security badge',
    subtitle: 'This photo will be used on your physical badge.',
    component: screenComponents.BadgeImage,
    isCompleted: (data) => get(data,'requests').length > 0,
    maxWidth: 860,
  },
  'badge-complete': {
    slug: 'complete',
    component: screenComponents.Summary,
    label: 'Confirmation',
    body: 'Your badge should be ready for you when you move in!',
    isCompleted: (data, taskDefinition) => {
      const activeRequest = taskDefinition?.helpers?.getActiveRequest(data);
      return activeRequest?.status === 'approved';
    },
  },
};
