import React, { useEffect } from 'react';
import classNames from 'classnames';

import { useScroller, useMobile } from '@moved/services';
import { Footer } from '@moved/product';

import { Sidebar } from './Sidebar';
import { MobileHeader } from './MobileHeader';

import CSS from './styles/LayoutSidebar.module.scss';

export const LayoutSidebar  = ({ children, withParallax }) => {
  const Scroller = useScroller();
  const isMobile = useMobile();

  // on unmount return the Scroller to the window
  useEffect(() => () => Scroller.set(window),[Scroller]);

  return (
    <div className='stackHorizontal height-full stackVertical--mobile'>
      { isMobile ? (
        <MobileHeader />
      ) : (
        <Sidebar />
      )}
      <div
        className={classNames(
          CSS.content,
          { [CSS.parallax]: withParallax },
        )}
        ref={Scroller.ref}
      >
        { children }
        <Footer />
      </div>
    </div>
  );
}
