import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_UPDATE_PET_REGISTRATION',
  request: (id, data={}) => request.patch(`/residents/pet-registration-tasks/${id}`, data).then(r => get(r,'data.data.pet_registration_task')),
  reducers: {
    success: (state, { response: petRegistration }) => {
      return {
        ...state,
        petRegistrations: {
          ...state.petRegistrations,
          [petRegistration.id]: petRegistration,
        },
      };
    },
  },
};

export const {
  action,
  reducer,
  usePending: useUpdatePetRegistrationPending,
} = generate.redux(creator);

export const initialState = {};
