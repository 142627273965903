import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_CREATE_REMINDER',
  request: (taskId,data) => request.post(`/residents/personal-tasks/${taskId}/reminders`,data)
    .then(r => r?.data?.data?.personal_task),
  reducers: {
    success: (state, { response: task }) => ({
      ...state,
      personalTasks: {
        ...state.personalTasks,
        [task.id]: task,
      },
    }),
  },
};

export const {
  action: createReminder,
  reducer,
  usePending: useCreateReminderPending,
} = generate.redux(creator);

export const initialState = {
  personalTasks: {},
};
