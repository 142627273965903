import React from 'react';
import PropTypes from 'prop-types';

import { Rating as MuiRating } from '@mui/material';

// custom wrapper around Material UI Star Rating component
export const Rating = ({
  name,
  value,
  isControlled,
  size='medium',
  precision=1,
  readOnly,
  onChange,
  className,
  ...props
}) => (
  <MuiRating
    name={name}
    value={isControlled ? (value ?? 0) : undefined }
    defaultValue={isControlled ? undefined : value}
    size={size}
    precision={precision}
    readOnly={readOnly}
    classes={{root:className}}
    onChange={(e,rating) => onChange({[name]:rating})}
    {...props}
  />
);

Rating.propTypes = {
  /** Name used for the form input */
  name: PropTypes.string,

  /** The active rating value */
  value: PropTypes.number,

  /** The size variant to render */
  size: PropTypes.oneOf(['large','medium','small']),

  /** The minimum increment value change allowed. */
  precision: PropTypes.number,

  /** Flag to set the input to display only */
  readOnly: PropTypes.bool,

  /** Callback fired when the value changes */
  onChange: PropTypes.func,

  /** Class name to add to the root component */
  className: PropTypes.string,

};

Rating.defaultProps = {};
