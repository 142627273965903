import { merge } from 'lodash';

import * as complete from './complete';
import * as create from './create';
import * as getMoverBookingTask from './getMoverBookingTask';
import * as getMovingCompanyAds from './getMovingCompanyAds';
import * as getUhaulAdLink from './getUhaulAdLink';
import * as getUhaulProducts from './getUhaulProducts';
import * as reset from './reset';
import * as update from './update';

export const reducers = [
  complete.reducer,
  create.reducer,
  getMoverBookingTask.reducer,
  getMovingCompanyAds.reducer,
  getUhaulAdLink.reducer,
  getUhaulProducts.reducer,
  reset.reducer,
  update.reducer,
];

export const initialState = merge(
  complete.initialState,
  getMoverBookingTask.initialState,
  getMovingCompanyAds.initialState,
  getUhaulProducts.initialState,
  reset.initialState,
);
