import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EditorContent, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import UniqueID from '@tiptap-pro/extension-unique-id';

import CSS from './ChecklistEditor.module.scss';

const convertContentToTipTap = (content) => ({
  type: 'doc',
  content: [{
    type: 'taskList',
    content: content?.map(item => ({
      type: 'taskItem',
      attrs: {
        checked: item?.isCompleted ?? false,
        id: item?.id,
      },
      content: [{
        text: item?.text ?? '',
        type: 'text',
      }],
    })),
  }],
});

const convertContentFromTipTap = ({ content }) => content?.at(0)?.content?.map(item => ({
  id: item?.attrs?.id,
  text: item?.content?.at(0)?.text ?? '',
  isCompleted: item?.attrs?.checked,
}));

export const ChecklistEditor = ({
  initialContent,
  onChange,
  className,
}) => {
  const editor = useEditor({
    content: convertContentToTipTap(initialContent?.length > 0 ? initialContent : [{text:''}]),
    extensions: [
      Document,
      Text,
      TaskList.configure({ HTMLAttributes: { class: CSS.list } }),
      TaskItem
        .extend({ content: 'text*' })
        .configure({ HTMLAttributes: { class: CSS.item } }),
      UniqueID.configure({ types:['taskItem'] }),
    ],
    injectCSS: false,
    onUpdate({ editor }) {
      const content = editor.getJSON();
      const taskList = convertContentFromTipTap(content);
      onChange?.(taskList);
    },
  });

  if(!editor) return null;

  return (
    <EditorContent editor={editor} className={classNames(CSS.editor,className)} />
  );
};

ChecklistEditor.propTypes = {
  /** Content to initialize the checklist on load */
  initialContent: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      text: PropTypes.string.isRequired,
      isComplete: PropTypes.bool,
    })
  ),
  /** Callback with updated content on each change of data */
  onChange: PropTypes.func,
  /** Class to append to the editor wrapper */
  className: PropTypes.string,
};
