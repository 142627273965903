import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_MOVER_BOOKING',
  request: (id) => request.get(`/mover-bookings/${id}`).then(r => r?.data?.data?.mover_booking),
  selector: (state, id) => state?.moverBookings?.[id],
  reducers: {
    success: (state, { response: booking }) => ({
      ...state,
      moverBookings: {
        ...state.moverBookings,
        [booking.id]: booking,
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useMoverBooking,
  usePending: useMoverBookingPending,
} = generate.redux(creator);

export const initialState = {
  moverBookings: {},
};
