import React, { useState } from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { merge } from 'lodash';

import { Icon } from '../../../sondheim/components/Icon';
import { Tooltip } from '../../../sondheim/components/Tooltip';
import { ImageCrop } from '../../../sondheim/components/ImageCrop';
import { Uploader } from '../Uploader';

import CSS from './ImageCropUpload.module.scss';

export const ImageCropUpload = ({
  onCrop = noop,
  onReject = noop,
  initialFile,
  uploaderOptions,
  cropOptions={},
  className,
}) => {
  // STATE
  const [initialPhoto, setInitialPhoto] = useState(initialFile);
  const [file, setFile] = useState(false);

  const handleSelect = files => setFile(files[0]);

  const resetFile = () => {
    setFile(false);
    setInitialPhoto(initialFile);
    return onCrop(false);
  };
  const resetCrop = () => {
    const newFile = new File([file], file.name, { type: file.type });
    setFile(newFile);
  };

  const options = merge({},{
    accept: {
      'image/jpg': ['.jpg', '.jpeg'],
      'image/heic': ['.heic'],
    },
    maxFiles: 1,
    multiple: false,
  }, uploaderOptions);

  return (
    <div className={classNames(CSS.image_upload,className)}>
      { initialPhoto && (
        <div className={CSS.uploaded_file}>
          <div className={CSS.image_wrapper}>
            <img src={initialPhoto} alt={initialPhoto}/>
          </div>
          <a href={initialPhoto} target="_blank" rel="noopener noreferrer" className={CSS.image_name}>
            {decodeURIComponent(initialPhoto.substring(initialPhoto.lastIndexOf('/') + 1))}
          </a>
          <div className={CSS.remove_wrapper} onClick={() => setInitialPhoto(false)}>
            <Tooltip
              tooltipClassName={CSS.popover}
              tooltip={'Remove file'}
              className={CSS.remove}
            >
              <Icon symbol={'Trash'} library={'general'} size={'24px'} />
            </Tooltip>
          </div>
        </div>
      )}
      { !initialPhoto && (!file ? (
        <Uploader
          dzOptions={options}
          onSelect={handleSelect}
          onReject={onReject}
          icon={{
            symbol: 'Cloud-upload',
            library: 'files'
          }}
        />
      ) : (
        <div className={CSS.cropzone}>
          <div className={CSS.actions}>
            <div
              className={CSS.button_wrapper}
              onClick={resetCrop}
            >
              <Tooltip
                tooltipClassName={CSS.popover}
                tooltip={'Reset'}
                className={classNames(CSS.button)}
              >
                <Icon symbol={'Crop'} library={'design'} size={'24px'} />
              </Tooltip>
            </div>

            <div
              className={CSS.button_wrapper}
              onClick={resetFile}
            >
              <Tooltip
                tooltipClassName={CSS.popover}
                tooltip={'Remove file'}
                className={classNames(CSS.remove,CSS.button)}
              >
                <Icon symbol={'Trash'} library={'general'} size={'24px'} />
              </Tooltip>
            </div>
          </div>
          <ImageCrop
            file={file}
            onCrop={onCrop}
            {...cropOptions}
          />
        </div>
      ))}
    </div>
  );

};
