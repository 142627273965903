import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './ProgressRing.module.scss';

export const ProgressRing = ({ progress, total, size=44, stroke=4, showFraction=true, className }) => {
  const normalizedRadius = (size - stroke)/2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference * (1 - (progress / total));
  return (
    <div className={classNames(CSS.container,className)}>
      { showFraction && (
        <div className={CSS.fraction}>{`${parseInt(progress)}/${parseInt(total)}`}</div>
      )}
      <svg
        height={size}
        width={size}
        >
        <circle
          className={CSS.circle_bg}
          stroke="var(--color-2,#c2c5c9)"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={size/2}
          cy={size/2}
        />
        <circle
          className={CSS.circle_fill}
          stroke="var(--color-1,#000)"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={size/2}
          cy={size/2}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{strokeDashoffset}}
        />
      </svg>
    </div>
  );
}

ProgressRing.propTypes = {
  /** Number that determine how much is filled */
  progress: PropTypes.number.isRequired,
  /** Number that determines how much is available to be filled */
  total: PropTypes.number.isRequired,
  /** Number value in pixels for the outer diameter of the circle */
  size: PropTypes.number,
  /** Number value in pixels for how wide the circle bar is */
  stroke: PropTypes.number,
  /** Flag for if the fractional completion values should show in the center */
  showFraction: PropTypes.bool,
  /** Classname to add to the container */
  className: PropTypes.string,
};
