import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_VEHICLE',
  request: (requestID, vehicleID, data={}) => request.patch(`/residents/vehicle-registration-requests/${requestID}/vehicles/${vehicleID}`, data).then(r => r.data.data.vehicle_registration_task),
  reducers: {
    success: (state, { response: vehicleRegistration }) => {
      return {
        ...state,
        vehicleRegistrations: {
          ...state.vehicleRegistrations,
          [vehicleRegistration.id]: vehicleRegistration,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateVehiclePending,
} = generate.redux(creator);

export const initialState = {};
