import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_GET_AVAILABLE_PERSONAL_TASK_TYPES',
  request: (id) => request.get(`/residents/personal-task-lists/${id}/available-personal-task-types`).then(r => r?.data?.data?.personal_task_types),
  selector: (state, id) => state?.availablePersonalTaskTypes?.[id],
  reducers: {
    success: (state, { response: availableTaskTypes, params: [id] }) => ({
      ...state,
      availablePersonalTaskTypes: {
        ...state.availablePersonalTaskTypes,
        [id]: availableTaskTypes,
      },
    }),
  },
};

export const {
  action: getAvailablePersonalTaskTypes,
  reducer,
  useResource: useAvailablePersonalTaskTypes,
  usePending: useAvailablePersonalTaskTypesPending,
} = generate.redux(creator);

export const initialState = {
  availablePersonalTaskTypes: {},
};
