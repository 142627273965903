import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';

import { useNotify, s3, format, useUser } from '@moved/services';
import { Uploader} from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

const options = {
  accept: {
    'image/jpg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/heic': ['.heic'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc','.docx'],
  },
  multiple: true,
  maxFiles: 10,
};

const _renderTaskFiles = files => files.map((file, index) => {
  return (
    <li key={file}><a href={file}target="_blank" rel="noopener noreferrer">View file {index+1}</a></li>
  );
});

export const UploadPolicy = ({ screen, nextScreen, taskDefinition }) => {
  const { id, taskType } = useParams();
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { user } = useUser();
  // state
  const [files, setFiles] = useState(false);
  const [pending, setPending] = useState(false);
  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const taskDetails = taskDefinition.selectors.useRenterInsurance(id);

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const taskFiles = get(activeRequest, 'file_urls', []);

  const handleNext = e => {
    if(!files) return nextScreen();
    e.preventDefault();
    if(!files || pending) return;
    setPending(true);

    const fileUploadPromises = files.map(file => {
      return dispatch(getS3UploadUrl(building.id, {
        filename: s3.getUniqueFilename(file.name, user),
        http_content_type: file.type,
        subdirectory: `insurance`,
      }))
        .then(({ signed_request, url }) => s3.putFile(file, signed_request).then(() => url))
    });

    Promise.all(fileUploadPromises)
      .then(file_urls => dispatch(taskDefinition.actions.updateRequest(activeRequest.id, { file_urls })))
      .then(() => nextScreen())
      .catch(err => {
        Notify.error();
        setPending(false);
      });
  };

  const handleSelect = files => setFiles(files);
  const handleReject = files => Notify.error('Oops! The file does not meet the file type requirement.');

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            {!isEmpty(taskFiles) && (
              <>
                <h3 className={'mb-10'}>Previously uploaded documents</h3>
                <p>You may view your previously uploaded files by clicking on the links below.</p>
                {_renderTaskFiles(taskFiles)}
                <h3 className={'mt-30 mb-10'}>Upload new policy documents</h3>
                <p>If you wish to upload new policy documents you may do so below.<br /><em>(<strong>Warning:</strong> This will remove any previosuly uploaded files.)</em></p>
              </>
            )}

            <div className={'mb-20'}>
              <Snippet tag={'tasks.insurance.upload.instructions'} description={'Insurance upload instructions'}>
                <p>Please ensure your renters insurance policy meets the following requirements:</p>
                <ul>
                  <li>Coverage starts on or before your lease start date.</li>
                  <li>Policy has at least {get(taskDetails,'minimum_coverage_requirement') ? format.currency(get(taskDetails,'minimum_coverage_requirement')) : '$100,000'} in personal liability coverage.</li>
                  <li><strong>Please include <Snippet tag={'tasks.insurance.interested_party_name'} description={'Interested party name'}>{get(building,'partner.name')}</Snippet> as an "Interested Party"</strong></li>
                  <li>Everyone on the lease must be listed on the policy. Those who aren't will need to provide an additional policy that also meets the above criteria.</li>
                </ul>
              </Snippet>
            </div>

            <div className={'mb-20'}>
              <Uploader id={`${taskType}_${screen.slug}_upload`} dzOptions={options} onSelect={handleSelect} onReject={handleReject} icon={{size:'80px'}} />
            </div>

          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>

            <button
              id='upload_policy_button'
              className={classNames('btn-primary btn--full',{'loading':pending})}
              onClick={handleNext}
              disabled={!files && isEmpty(taskFiles)}
            >
              Next
            </button>

          </div>
        </div>


      </div>

    </div>
  );
};
