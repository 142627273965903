import { MovesList, Move, withMoveSummaries } from './components';

import { withContactForm } from '../contact';
import dashboardRoutes from '../dashboard/routes';
import personalTaskRoutes from '../personalTasks/routes';
import marketplaceRoutes from '../marketplace/routes';

const routes = [
  {
    path: '/moves',
    name: 'Moves List',
    component: withContactForm(withMoveSummaries(MovesList)),
    exact: true,
    transitions: {
      in: 'rotateRoomBottom',
      out: 'rotateRoomTop',
    },
    viewKey: () => `moves-list`,
  },
  {
    path: '/moves/:moveId(\\d+)',
    name: 'Move',
    component: withMoveSummaries(Move),
    viewKey: ({moveId}) => `move-${moveId}`,
    transition: 'rotateRoomTop',
    children: [
      ...marketplaceRoutes,
      ...personalTaskRoutes,
      ...dashboardRoutes,
    ],
  },
];

export default routes;
