import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_MOVE_OUT_DETAILS_OPTIONS_LIST',
  request: building_id => request.get(`/buildings/${building_id}/move-out-reason-options`).then(r => r.data.data.move_out_reason_options),
  selector: (state, building_id) => state.moveOutReasons[building_id] ? state.moveOutReasons[building_id] : [],
  cache: true,
  reducers: {
    success: (state, { response: reasons, params: [building_id] }) => {
      return {
        ...state,
        moveOutReasons: {
          ...state.moveOutReasons,
          [building_id]: reasons,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useMoveOutOptionsList,
  usePending: useMoveOutOptionsListPending,
} = generate.redux(creator);

export const initialState = {
  moveOutReasons: {},
};
