import React from 'react';
import classNames from 'classnames';

import { Icon } from '../../../../../sondheim/components/Icon';
import { TagRenderer } from '../../../TagRenderer';

import CSS from './CheckList.module.scss';

export const CheckListDisplay = ({ data, tool, tags }) => {
  const { color, items } = data;
  return (
    <div className={classNames('cdx-block',tool.className,CSS.render)}>
      {items && items.map((item,idx) => (
        <div className={CSS.render_item} key={`${item}_${idx}`}>
          <Icon className={CSS[color]} library={'code'} symbol={'Done-circle'} />
          <TagRenderer content={item} as='span' tags={tags} />
        </div>
      ))}
    </div>
  );
};
