import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CANCEL_BUILDING_RESERVATION_REQUEST',
  request: (id, data={}) => request.post(`residents/building-reservation-requests/${id}/cancel`, data).then(r => r.data.data.building_reservation_task),
  reducers: {
    success: (state, { response: reservation }) => {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          [reservation.id]: reservation,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCancelRequestPending,
} = generate.redux(creator);
