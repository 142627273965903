import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_VEHICLE_REGISTRATION',
  request: (id) => request.get(`/residents/vehicle-registration-tasks/${id}`).then(r => get(r,'data.data.vehicle_registration_task')),
  selector: (state, id) => get(state,`vehicleRegistrations.${id}`),
  reducers: {
    success: (state, { response: vehicleRegistration }) => {
      return {
        ...state,
          vehicleRegistrations: {
          ...state.vehicleRegistrations,
          [vehicleRegistration.id]: vehicleRegistration,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useVehicleRegistration,
  usePending: useVehicleRegistrationPending,
} = generate.redux(creator);

export const initialState = {
  vehicleRegistrations: {},
};
