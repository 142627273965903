import React from 'react';
import classNames from 'classnames';
import CSS from './styles/ScreenContent.module.scss';

export const ScreenContent = ({ screen={}, children, className }) => {
  return (
    <div className={classNames(CSS.content,className)}>
      <div className={CSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
        { children }
      </div>
    </div>
  );
}
