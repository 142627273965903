import { movingPlan as helpers } from '@moved/product';

import { BookMovers } from '../book-movers/definition';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class MovingPlan extends BookMovers {
  constructor() {
    super({
      screens,
      actions,
      selectors:  {
        useTaskable: selectors.useMergedMoverBookingTask,
        ...selectors,
      }
    });
  }

  static label = 'Confirm your moving plan';
  static slug = 'moving-plan';
  static icon = 'Loader';
  static helpers = helpers;

  _flowBuilder(moverBookingTask) {
    switch(moverBookingTask?.selected_moving_method) {
      case 'diy':
      case 'truck-rental':      // support for legacy book-mover task flow
      case 'hiring-labor':      // support for legacy book-mover task flow
      case 'container-rental':  // support for legacy book-mover task flow
        // full diy flow
        return [
          this.screens['moving-method'],
          this.screens['diy-solutions'],
          this.screens['confirmation'],
        ];
      case 'professional-movers':
        switch(moverBookingTask?.interested_in_moved) {
          case true:
            // full mover booking flow (inherited flow from BookMovers task)
            return [
              this.screens['moving-method'],
              this.screens['moving-provider'],
              ...super._flowBuilder(moverBookingTask?.mover_booking, moverBookingTask),
            ];

          case false:
            // full user-provided flow
            return [
              this.screens['moving-method'],
              this.screens['moving-provider'],
              this.screens['moving-companies'],
              this.screens['confirmation-user-provided'],
            ];

          default:
            // partial booking flow
            return [
              this.screens['moving-method'],
              this.screens['moving-provider'],
              this.screens['mystery'],
              this.screens['confirmation'],
            ];
        }
      default:
        // initial flow
        return [
          this.screens['moving-method'],
          this.screens['mystery'],
          this.screens['confirmation'],
        ];
    }
  }

  /* START OVERRIDES */
  getMoverBooking(moverBookingTask) {
    return moverBookingTask?.mover_booking;
  }
  updateMoverBookingForTask(moverBooking, moverBookingTask) {
    return {
      ...moverBookingTask,
      mover_booking: moverBooking
    };
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
