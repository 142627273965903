import { get, isNil } from 'lodash';

import * as screenComponents from './components';

export const screens = {

  'insurance-survey': {
    slug: 'survey',
    label: 'Survey',
    title: 'Obtain renters insurance',
    subtitle: 'We require coverage starting when your lease begins.',
    component: screenComponents.PurchaseOptions,
    isCompleted: (data) => !isNil(get(data,'selected_purchase_option')),
  },

  'insurance-providers': {
    slug: 'plan',
    label: 'Plans',
    component: screenComponents.InsuranceProviders,
    title: 'Select a plan from one of our preferred partners',
    subtitle: `Get a competitive rate on your renters insurance policy from one of the insurance providers below.`,
  },

  'insurance-upload': {
    slug: 'upload',
    label: 'Upload policy',
    component: screenComponents.UploadPolicy,
    title: 'Upload your renters insurance policy',
    subtitle: 'Ensure your policy begins on your lease start date.',
    isCompleted: (data) => !isNil(get(data,'requests[0].file_urls')),
  },

  'insurance-details': {
    slug: 'details',
    label: 'Policy details',
    title: 'Provide insurance policy details',
    subtitle: 'Fill in the required fields below.',
    maxWidth: 500,
    component: screenComponents.PolicyDetails,
    isCompleted: (data) => !isNil(get(data,'requests[0].insurance_provider')) &&
      !isNil(get(data,'requests[0].policy_number')) &&
      !isNil(get(data,'requests[0].liability_coverage_amount')) &&
      !isNil(get(data,'requests[0].coverage_start')) &&
      !isNil(get(data,'requests[0].coverage_end')),
  },

  'verify-epremium': {
    slug: 'verify',
    label: 'Policy number',
    title: 'Provide insurance policy number',
    subtitle: 'Fill in the policy number below so that we can verify your policy.',
    maxWidth: 500,
    component: screenComponents.VerifyEpremium,
    isCompleted: (data) => !isNil(get(data,'requests[0].policy_number')),
  },

  'insurance-individuals': {
    slug: 'individuals',
    label: 'Insured residents',
    component: screenComponents.InsuredIndividuals,
    title: 'Insured residents',
    subtitle: 'Indicate who is covered and who is the primary policy holder.',
    isCompleted: (data) => get(data,'requests[0].tenants',[]).find(tenant => tenant.is_covered && tenant.is_primary_policyholder),
  },

  'summary': {
    slug: 'summary',
    label: "Summary",
    component: screenComponents.CompleteInsurance,
    backRestricted: (data) => get(data,'requests[0].status') !== 'draft',
    isCompleted: (data, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(data);
      return activeRequest?.status === 'approved';
    },

  },

};
