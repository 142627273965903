import { merge } from 'lodash';

import * as createSatisfactionResponse from './createSatisfactionResponse';
import * as getSatisfactionRequest from './getSatisfactionRequest';
import * as getSatisfactionResponse from './getSatisfactionResponse';
import * as submitSatisfactionResponse from './submitSatisfactionResponse';
import * as trackReviewSiteClick from './trackReviewSiteClick';
import * as updateSatisfactionLevel from './updateSatisfactionLevel';
import * as updateSatisfactionResponse from './updateSatisfactionResponse';

export const initialState = merge(
  createSatisfactionResponse.initialState,
  getSatisfactionRequest.initialState,
  getSatisfactionResponse.initialState,
  submitSatisfactionResponse.initialState,
  trackReviewSiteClick.initialState,
  updateSatisfactionLevel.initialState,
  updateSatisfactionResponse.initialState,
);

export const reducers = [
  createSatisfactionResponse.reducer,
  getSatisfactionRequest.reducer,
  getSatisfactionResponse.reducer,
  submitSatisfactionResponse.reducer,
  trackReviewSiteClick.reducer,
  updateSatisfactionLevel.reducer,
  updateSatisfactionResponse.reducer,
];
