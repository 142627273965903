import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_SUBMIT_DOC_REQUEST',
  request: (id, data={}) => request.post(`/document-submission-requests/${id}/submit`, data).then(r => r.data.data.document_submission_task),
  reducers: {
    success: (state, { response: docSubmission }) => {
      return {
        ...state,
        docSubmissions: {
          ...state.docSubmissions,
          [docSubmission.id]: docSubmission,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useSubmitDocRequestPending,
} = generate.redux(creator);

export const initialState = {};
