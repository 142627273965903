import { Button, TimeSelect } from '@moved/ui';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import CSS from './DateTimeSelect.module.scss';


export const DateTimeSelect = ({ datetime_value, onNext, onCancel }) => {
  // Separate out the date and time portions from the datetime_value prop
  const initialDate = datetime_value ? moment(datetime_value).startOf('day').toDate() : null;
  const initialTime = datetime_value ? moment(datetime_value).format('HH:mm') : null;

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedTime, setSelectedTime] = useState(initialTime);

  const combinedDateTime = selectedDate && selectedTime && moment(selectedDate).set({
    hour: moment(selectedTime, 'HH:mm').hours(),
    minute: moment(selectedTime, 'HH:mm').minutes(),
  });

  const error = combinedDateTime && (!combinedDateTime.isValid() || combinedDateTime.isBefore(moment())) && 'Selected date and time are not valid';

  const handleNext = () => {
    if (!combinedDateTime) return;

    // Format the combined datetime to 'YYYY-MM-DDTHH:mm'
    const reminder_datetime = combinedDateTime.format('YYYY-MM-DDTHH:mm');

    onNext({ reminder_datetime });
  };

  return (
    <div className={CSS.container}>
      <DatePicker
        selected={selectedDate}
        onChange={setSelectedDate}
        minDate={moment().toDate()}
        inline
        calendarClassName={CSS.calendar}
      />
      <div>
        <h3 className='labelL contentPrimary'>Pick a time</h3>
        <TimeSelect
          name='reminder_time'
          value={selectedTime}
          onChange={({ reminder_time }) => setSelectedTime(reminder_time)}
        />
      </div>

      {error && <div className='labelS contentError marginTop-4'>{error}</div>}

      <div className={CSS.buttons}>
        <Button
          text='Cancel'
          onClick={onCancel}
          color='secondary'
        />
        <Button
          text='Next'
          onClick={handleNext}
        />
      </div>
    </div>
  );
};
