import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { Button, Icon } from '@moved/ui';

import { useOptionalControl } from '@moved/services';

import CSS from './DatePickerDropdown.module.scss';

const convertToDate = (date) => (date ? new Date(date + 'T00:00') : null);

export const DatePickerDropdown = ({
  title,
  value,
  isControlled,
  onChange,
  onClose,
  onSubmit,
}) => {
  const [selectedDate, setSelectedDate] = useOptionalControl(value, isControlled);

  const handleDateChange = (date) => {
    const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
    setSelectedDate(formattedDate);
    onChange(formattedDate);
  };

  const handleSave = () => {
    onSubmit(selectedDate);
  };

  return (
    <div className={CSS.datepicker_dropdown}>
      <div className={classNames('stackHorizontal items-center justify-between', CSS.header)}>
        <h3 className='contentPrimary'>{title}</h3>
        <span className={CSS.close_icon} onClick={onClose}>
          <Icon symbol='Close' library='navigation'/>
        </span>
      </div>

      <DatePicker
        selected={convertToDate(selectedDate)}
        onChange={handleDateChange}
        minDate={moment().toDate()}
        inline
        calendarClassName={CSS.calendar}
      />

      <div className={classNames('stackHorizontal items-center justify-between', CSS.buttons)}>
        <span className={CSS.clear_button} onClick={() => handleDateChange('')}>
          Clear
        </span>
        <Button text='Save' onClick={handleSave} color='primary' />
      </div>
    </div>
  );
};

DatePickerDropdown.propTypes = {
  /** Title in header of the dropdown */
  title: PropTypes.string.isRequired,
  /** Value to use for this input `YYYY-MM-DD` (only initial value if not controlled) */
  value: PropTypes.string,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func.isRequired,
  /** onClose handler function when the user clicks Close*/
  onClose: PropTypes.func.isRequired,
  /** onSubmit handler function when the user clicks Save */
  onSubmit: PropTypes.func.isRequired,
};