import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Card } from '@moved/ui';

import car from './assets/ptd-promo-car.png';
import creditcard from './assets/ptd-promo-creditcard.png';
import mailbox from './assets/ptd-promo-mailbox.png';
import movers from './assets/ptd-promo-movers.png';

import CSS from './styles/PersonalTasksLeader.module.scss';

export const PersonalTasksLeader = ({ move }) => {
  const history = useHistory();
  return (
    <Card className={classNames('stackHorizontal gap-64 items-center justify-center', CSS.container)}>

      <img src={movers} className='hidden--mobile' alt="Book movers" />
      <img src={mailbox} className='hidden--mobile' alt="Forward you address" />

      <div className='stackVertical gap-20'>
        <div className={classNames('stackVertical gap-4 gap-12--mobile', CSS.content)}>
          <h2 className={classNames('displayM contentPrimary', CSS.title)}>You have better things to do</h2>
          <p className='labelM contentSecondary'>
            One place to manage <em>everything</em> you need to get done. Create your
            personalized task list from our expertly curated selection,
            based on over 100,000 successful moves.
          </p>
        </div>
        <Button
          color='secondary'
          text='View personal dashboard'
          onClick={() => history.push(`/moves/${move.id}/personal-tasks`)}
        />
      </div>

      <img src={creditcard} className='hidden--mobile' alt="Credit card offers" />
      <img src={car} className='hidden--mobile' alt="Car" />

    </Card>
  );
};
