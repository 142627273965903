import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { useActiveMoveStep } from '../../dashboard';
import { getDashboardRoute } from '../../dashboard/helpers';
import { taskDefinitions } from '../types';
import { getTaskSummary } from '../actions';
import { useTaskSummary } from '../actions/selectors';

const ActiveBuildingMoveTask = createContext();

export const useActiveBuildingMoveTask = () => useContext(ActiveBuildingMoveTask);

export const BuildingMoveTaskContextProvider = ({ children }) => {
  const { moveId, stepId, taskId } = useParams();
  const Notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardRoute = getDashboardRoute(moveId, stepId);
  const moveStep = useActiveMoveStep();
  const [pending, setPending] = useState(true);

  const activeBuildingMoveTask = (moveStep?.move_tasks ?? []).find(({id}) => id === parseInt(taskId));

  const TaskType = activeBuildingMoveTask && taskDefinitions[activeBuildingMoveTask?.task_type];
  const [activeTaskDefinition] = useState(TaskType && new TaskType());
  const activeTaskBaseRoute = activeTaskDefinition && `${dashboardRoute}${activeTaskDefinition.getBaseRoute(activeBuildingMoveTask?.id)}`;
  const activeTaskDetails = activeTaskDefinition?.selectors?.useTaskable(activeBuildingMoveTask?.task_details_id);
  const activeTaskSummary = useTaskSummary(taskId);

  // Fetch task details and summary on load and any time the task id changes
  useEffect(() => {
    if(!pending) setPending(true);
    if(!activeTaskDefinition) {
      Notify.error('This task is not currently available.');
      history.replace(dashboardRoute);
    }
    else {
      Promise.all([
        activeBuildingMoveTask.task_details_id && dispatch(activeTaskDefinition.actions.get(activeBuildingMoveTask.task_details_id)),
        activeTaskDefinition.config.supportsArtifacts && dispatch(getTaskSummary(taskId)),
      ])
        .then(([taskDetails]) => activeTaskDefinition.updateFlow(taskDetails))
        .then(() => setPending(false))
        .catch(err => {
          Notify.error(format.error(err));
          history.replace(dashboardRoute);
        });
    }
  },[taskId]); // eslint-disable-line

  // Refresh the summary any time the activeRequest status changes
  const activeRequestStatus = activeTaskDefinition?.helpers?.getActiveRequest(activeTaskDetails)?.status;
  useEffect(() => {
    if(!activeTaskDefinition?.config?.supportsArtifacts || pending) return;
    dispatch(getTaskSummary(taskId));
  },[activeRequestStatus]); // eslint-disable-line

  if(!activeBuildingMoveTask || !activeTaskDefinition) return null;

  if(pending) return <LoaderOverlay />;

  return (
    <ActiveBuildingMoveTask.Provider value={{
      activeBuildingMoveTask,
      activeTaskDefinition,
      activeTaskBaseRoute,
      activeTaskDetails,
      activeTaskSummary,
    }}>
      { children }
    </ActiveBuildingMoveTask.Provider>
  );
};

export const withTask = (Wrapped) => (props) => (<BuildingMoveTaskContextProvider><Wrapped {...props}/></BuildingMoveTaskContextProvider>);
