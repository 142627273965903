import { get, merge } from 'lodash';

import { format } from '@moved/services';
import { insurance as helpers } from '@moved/product';

import { ResidentTagRenderer } from '../../../common';
import { BaseTask } from '../shared/components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class Insurance extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useRenterInsurance,
        ...selectors,
      }
    });
  }

  static label = 'Renters Insurance';
  static slug = 'insurance';
  static icon = 'Shield-thunder';
  static helpers = helpers;
  static config = {
    supportsArtifacts: true,
  };

  _flowBuilder(taskData) {
    const activeRequest = this.helpers.getActiveRequest(taskData);
    const isMultiTenant = get(activeRequest,'tenants',[]).length > 1;
    const selectedOption = get(taskData,'selected_purchase_option');
    const {
      verificationMethod,
      showProviderSelection,
    } = this.getPurchaseOptionFlowImplications(selectedOption);

    // if option not specified but has a non-draft policy, must be included on roommate, just show summary:
    if(!selectedOption && activeRequest && activeRequest.status !== 'draft') return [
      this.screens['summary']
    ];

    // if no option specified, show mystery flow
    if(!selectedOption) return [
      this.screens['insurance-survey'],
      this.screens['mystery'],
      this.screens['summary'],
    ];

    return [
      this.screens['insurance-survey'],
      showProviderSelection && this.screens['insurance-providers'],
      verificationMethod === 'epremium' && this.screens['verify-epremium'],
      verificationMethod === 'collect-details' && this.screens['insurance-upload'],
      // if not multitenant need to send a flag into details screen to trigger policy submission
      verificationMethod === 'collect-details' && merge(this.screens['insurance-details'],{ submitRequest:!isMultiTenant }),
      // If multitenant lease need to add tenant coverage screen which will handle policy submission
      verificationMethod === 'collect-details' && isMultiTenant && this.screens['insurance-individuals'],
      this.screens['summary'],
    ].filter(v => v);

  }

  /* START OVERRIDES */
  getRecommendedScreen(taskData) {
    const activeRequest = this.helpers.getActiveRequest(taskData);
    // if already submitted show summary screen
    if(activeRequest && activeRequest.status !== 'draft') return this._flow[this._flow.length-1];
    return super.getRecommendedScreen(taskData);
  }
  /* END OVERRIDES */

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    const selectedPurchaseOption = taskDetails?.selected_purchase_option;
    let requestCopy = {};
    switch(request.status) {
      case 'cancelled':
        requestCopy = {
          summary: {
            flavor: `Your roommate canceled this policy submission on ${format.date(request.cancelled_at,'dateTime')}`,
          }
        };
        break;
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'Review the requested changes below and submit an updated policy.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted insurance policy. You will be notified when your submission is reviewed.',
          },
          history: {
            title: "Roommate's policy pending approval.",
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: !taskDetails ? (
              'Your insurance policy has been taken care of.'
            ) :
            selectedPurchaseOption?.content?.summary?.approved?.flavor ? (
              <ResidentTagRenderer content={selectedPurchaseOption.content.summary.approved.flavor}/>
            ) : requiresApproval ? (
              'The insurance policy you provided has been reviewed and approved.'
            ) : (
              'The insurance policy you provided has been submitted. Your property will contact you if there are any issues.'
            )
          },
        };
        break;
      case 'override':
        requestCopy = {
          summary: {
            flavor: 'Your insurance policy has been taken care of.'
          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* TASK SPECIFIC */
  // helper for determining custom flow decisions based on selected purchase option
  // shared between definition (above) for determining which screens to show
  // and the purchase option screen to ensure we redirect and submit when appropriate
  getPurchaseOptionFlowImplications(purchaseOption) {
    const verificationMethod = get(purchaseOption,'verification_method');
    const providers = get(purchaseOption,'providers') || [];
    // if the selected purchase option has exactly one provider, it should be the defaultProvider
    const defaultProvider = providers.length === 1 && providers[0];
    // if there is a default provider link and the provider has no content html redirect to the provider immediately.
    const shouldRedirect = defaultProvider && !get(defaultProvider,'content.html');
    // if there is are any providers and we aren't autoredirecting from the RIPO screen, we need to show the providers screen
    const showProviderSelection = providers.length > 0 && !shouldRedirect;
    // if RIPO selection should go immediately to summary screen, need to submit the policy request
    const shouldSubmitRequest = !showProviderSelection && verificationMethod === 'no-verification';

    return {
      verificationMethod,
      defaultProvider,
      shouldSubmitRequest,
      shouldRedirect,
      showProviderSelection,
    };

  }
  /* END TASK SPECIFIC */

}
