import React from 'react';
import Template from "react-dynamic-html";
import { merge } from 'lodash';

import { Icon } from '../../../sondheim/components/Icon';

export const TagRenderer = ({ content, tags={}, as='div', className }) => {
  const defaultTags = {
    check: <Icon library='code' symbol='Done-circle' color='green' />,
    warn: <Icon library='code' symbol='Warning-1-circle' color='yellow' />,
  };
  return content && (
    <Template
      className={className}
      string={String(content)}
      values={merge(defaultTags,tags)}
      as={as}
    />
  );
};
