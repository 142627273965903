import { merge } from 'lodash';

import * as create from './create';
import * as createRequest from './createRequest';
import * as get from './get';
import * as submitRequest from './submitRequest'
import * as update from './update'
import * as updateRequest from './updateRequest'

export const reducers = [
  create.reducer,
  createRequest.reducer,
  get.reducer,
  submitRequest.reducer,
  update.reducer,
  updateRequest.reducer,
];

export const initialState = merge(
  create.initialState,
  createRequest.initialState,
  get.initialState,
  submitRequest.initialState,
  update.initialState,
  updateRequest.initialState,
);
