import {
  ChooseMoved,
  Confirmation,
  ConfirmUserProvided,
  LongDistance,
  MovingPlan,
  UhaulSolutions,
  ViewMovingCompanyAds,
} from './components';

import { screens as bookMoversScreens } from '../book-movers/screens';


export const screens = {

  'moving-method': {
    slug: 'moving-method',
    label: 'Moving plan',
    title: 'How are you planning to move your belongings?',
    subtitle: `Whether transporting furniture or just a few personal items, make sure you select the best option for your needs.`,
    isCompleted: (taskable) => taskable?.selected_moving_method != null,
    component: MovingPlan,
    maxWidth: '808px',
  },

  'diy-solutions': {
    slug: 'diy-solutions',
    label: 'Doing it myself',
    title: 'U-Haul recommended moving solutions',
    subtitle: 'Through an exclusive partnership with U-Haul, explore the largest selection of flexible, cost-effective moving options tailored to your move.',
    isCompleted: (taskable) => taskable?.completed_at != null,
    component: UhaulSolutions,
    maxWidth: 1060,
  },

  'moving-provider': {
    slug: 'moving-provider',
    label: 'Moving provider',
    title: 'Great! Provide a few details to see rates through our partner Moved.',
    subtitle: 'This should take two minutes or less.',
    maxWidth: 700,
    component: ChooseMoved,
    isCompleted: (taskable) => taskable?.interested_in_moved === false || (taskable?.interested_in_moved === true && taskable?.mover_booking_id)
  },

  'moving-quotes': {
    ...bookMoversScreens['moving-quotes'],
    showOptOut: true, // this is overridden to allow opting for diy after seeing quotes
  },

  'moving-companies': {
    slug: 'moving-companies',
    component: ViewMovingCompanyAds,
    label: 'Moving companies',
    title: 'No worries! If you prefer to research yourself, below are recommended movers for your building.',
    subtitle: `Select companies you’re interested in receiving quotes from. By
      doing so, you agree to be introduced to them via email. Your information
      will never be shared otherwise, or with any companies you do not select.`,
    isCompleted: (taskable) => taskable?.selected_moving_company_ads && taskable?.completed_at,
  },

  'long-distance': {
    slug: 'long-distance',
    component: LongDistance,
    label: 'Moving options',
    title: 'It looks like you are moving long distance!',
    subtitle: `If you are interested in having a Moved concierge reach out to you with best-in-class moving quotes, provide your contact info below. Don't worry - this won't be shared with anyone but your Moved concierge.`,
    maxWidth: 1060,
    isCompleted: (taskable) => taskable?.completed_at != null || taskable?.mover_booking?.contact_email != null,
  },

  'confirmation': {
    slug: 'confirmation',
    component: Confirmation,
    label: 'Confirmation',
    backRestricted: true,
    isCompleted: (taskable) => taskable?.completed_at != null,
  },

  'confirmation-user-provided': {
    slug: 'confirmation',
    component: ConfirmUserProvided,
    label: 'Confirmation',
    backRestricted: true,
    isCompleted: (taskable) => taskable?.completed_at != null,
  },

};
