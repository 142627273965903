import { format } from '@moved/services';
import { base as baseHelpers } from '@moved/product';

import { useBuilding } from '../../../../common/actions/selectors';
import { useMove } from '../../../../moves/actions/selectors';
import { useActiveMoveStep } from '../../../../dashboard/contexts';

import { screens as sharedScreens } from '../screens';
import * as defaults from './defaults';

export class BaseTask {
  constructor({ routes=[], screens={}, actions={}, selectors={} }) {
    // expose statics to the instance
    this.slug = this.constructor.slug;
    this.label = this.constructor.label;
    this.icon = this.constructor.icon;
    this.helpers = this.constructor.helpers || {};
    this.config = this.constructor.config || {};

    // Extensible attributes
    this.routes = [ ...routes ];
    this.screens = { ...sharedScreens, ...screens };
    this.actions = { ...actions };
    this.selectors = {
      useActiveMoveStep,
      useBuilding,
      useMove,
      ...selectors
    };

    // Local Data
    this._flow = [];
    this._flatFlow = [];

  }

  // Statics
  static slug = '';
  static label = 'BaseTask';
  static icon = 'Info';
  static lockedMessage = 'Complete the tasks above before reviewing this information.';
  static helpers = baseHelpers;
  static config = {
    supportsArtifacts: false,
  };

  _flowBuilder(taskDetails) {
    throw new Error(`${this.name} task flow is missing a flowBuilder method`);
  }

  _flattenFlow(flow) {
    return defaults.flattenFlow(flow);
  }

  get flow() {
    return this._flow;
  }

  get flatFlow() {
    return this._flatFlow;
  }

  updateFlow(taskDetails) {
    this._flow = this._flowBuilder(taskDetails) ?? [];
    this._flatFlow = this._flattenFlow(this._flow);
    return this._flow;
  }

  // Route to the task flow base
  getBaseRoute(taskId) {
    return defaults.getBaseRoute(taskId, this);
  }

  // Route to a specific screen
  getScreenRoute(screen, taskDetails) {
    return defaults.getScreenRoute(screen, taskDetails.id);
  }

  // Return the to starting screen
  getStartOverScreen(taskDetails) {
    this.updateFlow(taskDetails); // ensure flow is up-to-date
    return this.flatFlow[0];
  }

  // Route back to starting screen
  getStartOverRoute(taskDetails) {
    return this.getScreenRoute(this.getStartOverScreen(taskDetails), taskDetails);
  }

  // Find the most appropriate next screen based on current taskable data
  getRecommendedScreen(taskDetails) {
    return defaults.getRecommendedScreen(taskDetails, this);
  }

  // Route to next screen based on task data
  getRecommendedRoute(taskDetails) {
    return this.getScreenRoute(this.getRecommendedScreen(taskDetails), taskDetails);
  }

  // Access control logic
  canAccessScreen(taskDetails, slug, context) {
    return defaults.canAccessScreen(taskDetails, slug, context, this);
  }

  // Status variable content
  getStatusCopy(request) {
    switch(request.status) {
      case 'cancelled':
        return {
          summary: {
            title: 'Canceled',
            flavor: `This submission was canceled on ${format.date(request.cancelled_at,'dateTime')}`,
            color: 'red',
          },
          history: {
            title: `Canceled on ${format.date(request.cancelled_at,'dateTime')}`,
            label: 'Canceled',
            color: 'red',
          },
        };
      case 'rejected':
        return {
          summary: {
            title: 'Declined',
            color:'red',
          },
          history: {
            title: `Declined on ${format.date(request.responded_at,'dateTime')}`,
            label: 'Declined',
            color: 'red',
          },
        };
      case 'pending-approval':
        return {
          summary: {
            title: 'Waiting for approval',
            color: 'brown',
          },
          history: {
            title: 'Submission pending approval',
            label: `Pending`,
            color: 'orange',
          },
        };
      case 'approved':
        return {
          summary: {
            title: "Congrats, you're all set!",
            color: 'green',
          },
          history: {
            title: request.responded_at
              ? `Approved on ${format.date(request.responded_at,'dateTime')}`
              : `Submitted on ${format.date(request.submitted_at,'dateTime')}`,
            label: `On hold`,
            color: 'orange',
          },
        };
      case 'override':
        return {
          summary: {
            title: `Congrats, you're all set!`,
            color: 'green',
          }
        };
      default:
        return { summary: {}, history: {} };
    }
  }

}
