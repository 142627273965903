import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_DOCUMENT_SUBMISSION',
  request: (id) => request.get(`/residents/document-submission-tasks/${id}`).then(r => get(r,'data.data.document_submission_task')),
  selector: (state, id) => get(state,`docSubmissions.${id}`),
  reducers: {
    success: (state, { response: docSubmission }) => {
      return {
        ...state,
        docSubmissions: {
          ...state.docSubmissions,
          [docSubmission.id]: docSubmission,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useDocSubmission,
  usePending: useDocSubmissionPending,
} = generate.redux(creator);

export const initialState = {
  docSubmissions: {},
};
