import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_BUILDING_RESERVATION_CANCEL_APPOINTMENT',
  request: (id, data={}) => request.post(`/building-reservation-tasks/${id}/cancel-reservation`, data).then(r => r.data.data.building_reservation_task),
  reducers: {
    success: (state, { response: reservation }) => {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          [reservation.id]: reservation,
        }
      };
    }
  }
});
