import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

import { Tooltip } from '../../../sondheim/components/Tooltip';
import { Icon } from '../../../sondheim/components/Icon';

import CSS from './ClipboardWrapper.module.scss';

const ClipboardTooltip = ({copied}) => (
  <span>
    { copied ? (<>
      <Icon symbol={'Done'} library={'code'} className={CSS.check} size={'16px'} /> Copied
    </>) : 'Click to copy' }
  </span>
);

export const ClipboardWrapper = ({ text, children }) => {
  // HOOKS
  const [copied, setCopied] = useState(false);

  if(!text) return null;

  return (
    <Tooltip
      onHide={() => setCopied(false)}
      tooltip={<ClipboardTooltip copied={copied}/>}
    >
      <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
        { typeof children === 'function' ? children(copied) : children }
      </CopyToClipboard>
    </Tooltip>
  );
};

ClipboardWrapper.propTypes = {
  /** Text to display and copy */
  text: PropTypes.string,
};
