import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_CABLE_INTERNET_PROVIDER_SERVICES',
  request: (id) => (
    request
      .get(`/residents/cable-internet-tasks/${id}/building-services`)
      .then(r => r.data.data.cable_internet_provider_services)
  ),
  selector: (state, id) => state?.cableInternetProviderServices?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        cableInternetProviderServices: {
          ...state.cableInternetProviderServices,
          [id]: response,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useCableInternetProviderServices,
  usePending: useCableInternetProviderServicesPending,
} = generate.redux(creator);

export const initialState = {
  cableInternetProviderServices: {},
};

