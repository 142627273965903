import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_SUBMIT_HAPPYCO_INSPECTION_TASK',
  request: (id) => request.post(`/residents/happyco-inspection-tasks/${id}/submit`).then(r => get(r,'data.data.happyco_inspection_task')),
  reducers: {
    success: (state, { response: happycoInspectionTask }) => ({
      ...state,
      happycoInspectionTasks: {
        ...state.happycoInspectionTasks,
        [happycoInspectionTask.id]: happycoInspectionTask,
      },
    }),
  },
};

export const {
  action,
  reducer,
  usePending: useSubmitHappycoInspectionTaskPending,
} = generate.redux(creator);
