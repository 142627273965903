import React from 'react';

export const CancellationDisclaimer = () => (
  <span>
    You can cancel or reschedule anytime up to 48 hours before the move with no
    charge. For long distance moves, 5 days notice is required. Canceling without
    proper notice will result in a $150 cancellation fee for local moves, or a
    $300 cancellation fee for long distance moves. If you cancel after the movers
    arrive at your pickup location on the move day, they will charge the minimum
    cost of the move.
  </span>
);
