import { get } from 'lodash';
import { format } from '@moved/services';

export const getOverrideFields = override => {
  if(!override) return [];
  return [
    {
      label: 'Resident selection',
      value: get(override,'renters_insurance_purchase_option.display_name'),
      icon: { symbol: 'Checkbox-on', library: 'code' },
    },
    get(override,'notes') && {
      label: 'Notes',
      value: get(override,'notes'),
      icon: { symbol: 'Chat-check', library: 'communication' },
    },
    {
      label: 'Completed by',
      value: format.fullname(get(override,'created_by_user')),
      icon: { symbol: 'User', library: 'general' },
    },
  ].filter(v=>v);
};
