import React from 'react';
import classNames from 'classnames';

import { CompleteCard } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle, CompleteScreenActions } from '../../shared';
import { PaymentSummaryCards } from './PaymentSummaryCards';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Payment.module.scss';

export const DepositOffline = ({ screen, taskDetails, taskDefinition, moveId, origin, onClose  }) => {
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task,CSS.container)}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={classNames(taskCSS.content,CSS.content)}>
          <div className={CSS.wrapper}>

            <CompleteCard title='Notice regarding payment' flavor={`${building.name} requires at least ${taskDetails?.submission_lead_time_in_days} days to process online payments`} detailsTitle={null}>
              <Snippet tag={'tasks.deposit.payment.offline_instructions'}>
                <p>
                  In order to ensure your security deposit is processed by your lease start date, you will need to contact the building site staff directly to facilitate payment prior to accessing your unit and any loading docks or elevators for your move in.
                </p>
              </Snippet>
            </CompleteCard>

          </div>

          <PaymentSummaryCards deposit={taskDetails} />

        </div>

        <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />

      </div>

    </div>
  );
};
