import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicForm, AtomSpinner } from '@moved/ui';
import { useNotify } from '@moved/services';

import { ScreenTitle } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/PetMedical.module.scss'

// Multi-file document upload screen component
export const PetMedical = ({ screen, nextScreen, taskDefinition, }) => {
  // HOOKS
  const { id, screenContext } = useParams();
  const dispatch = useDispatch();
  const Notify = useNotify();

  // REDUX
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));

  const taskable = taskDefinition.selectors.useTaskable(id);
  const activeRequest = taskDefinition.helpers.getActiveRequest(taskable);
  const activePet = taskDefinition.getActivePet(taskable, screenContext);
  const updatePending = taskDefinition.selectors.usePetRegistrationPending();
  const requestPending = taskDefinition.selectors.useUpdatePetRequestPending();
  const loading = taskDefinition.selectors.usePetRegistrationPending();
  const { settings } = taskable;

  // STATE
  const pending = updatePending || requestPending;

  const fields = [
    settings.license_number_enabled && {
      type: 'text',
      name: 'license_number',
      label: 'Pet License Number',
      required: 'This field is required',
      isTwoCol: true,
      value: get(activePet,'license_number'),
    },
    settings.license_documentation_enabled && {
      type: 'uploader',
      name: 'license_documentation_url',
      label: (<span className={CSS.pet_license_label}>Upload pet license documentation</span>),
      required: 'A photo or copy of pet license is required',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `pet-registration`,
      })),
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/heic': ['.heic'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc','.docx'],
        },
        useUniqueFilenames: true,
      },
      value: get(activePet,'license_documentation_url'),
    },
    settings.neutered_status_enabled && {
      type: 'itemList',
      name: 'is_spayed_or_neutered',
      label: (<span className={CSS.neutered_label}>Is this animal spayed or neutered?</span>),
      required: 'This field is required',
      isTwoCol: true,
      options: [
        { value: true, label: 'Yes', icon: { symbol: 'Check', library: 'navigation' }, },
        { value: false, label: 'No', icon: { symbol: 'Close', library: 'navigation' }, },
      ],
      value: get(activePet,'is_spayed_or_neutered'),
    },
    settings.neutered_date_enabled && {
      type: 'datePicker',
      name: 'spayed_or_neutered_date',
      label: 'Spayed/neutered date',
      required: true,
      value: get(activePet,'spayed_or_neutered_date'),
      custom: settings.neutered_status_enabled ? {
        hidden: {
          compare: { field:'is_spayed_or_neutered' },
          boolean: 'not',
          value: true,
        }
      } : null
    },
    settings.vaccine_records_enabled && {
      type: 'uploader',
      name: 'vaccine_file_urls',
      label: (<span className={CSS.vaccination_label}>Upload proof of vaccination</span>),
      required: 'Proof of vaccination is required',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `pet-registration`,
      })),
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'image/heic': ['.heic'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc','.docx'],
        },
        useUniqueFilenames: true,
        multiple: true,
        maxFiles: 6,
      },
      value: get(activePet,'vaccine_file_urls'),
    },
  ].filter(v => v);

  const _handleSubmit = formData => {
    if(pending) return false;

    const cleanData = {
      ...formData,
      spayed_or_neutered_date: (formData.spayed_or_neutered_date === '')
        ? null
        : formData.spayed_or_neutered_date,
    };

    dispatch(taskDefinition.actions.updatePet(activeRequest.id, activePet.id, cleanData))
      .then(nextScreen)
      .catch(err => {
        Notify.error();
      });
  };

  return (
    <div className={taskCSS.holder}>
      <div className={classNames(taskCSS.task)}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />

        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={taskCSS.screen_edit_form}>
              { !loading ? (
                <DynamicForm
                  id={`${screen.slug}-form`}
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={pending}
                />
              ) : (
                <AtomSpinner />
              )}
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${screen.slug}-form-submit`}
              tabIndex="0"
              role="button"
              className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
              disabled={pending}
            >
              Submit
            </label>
          </div>
        </div>

      </div>
    </div>
  );
};
