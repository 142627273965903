import { get } from 'lodash';
import { format } from '@moved/services';

export const getMoveOutDetailsFields = (details, options=[]) => {
  if(!details) return [];
  return [
    {
      label: 'Forwarding address',
      value: format.address(get(details,'forwarding_address.address')),
      icon: { symbol: 'Marker#1', library: 'map' },
    },
    {
      label: 'Reasoning',
      // the value is exposed directly in the partners/cx api taskable, but by id in the customer api,
      // so we either use the label provided, or look it up from the list of options if in customer summary
      value: get(details,'selected_move_out_reason_option_label') || get(options.find(reason => reason.id === get(details,'selected_move_out_reason_option_id')),'label'),
      icon: { symbol: 'Question-circle', library: 'code' },
    },
    {
      label: 'Feedback',
      value: get(details,'feedback'),
      icon: { symbol: 'Chat#2', library: 'communication' },
    },
      get(details,'net_promoter_score_enabled') &&  {
      label: 'Net score',
      value: get(details,'net_promoter_score'),
      icon: { symbol: 'Pen&ruler', library: 'design' },
    },
  ].filter(v=>v);
};

export const getSummaryFields = (taskable, options) => {
  return getMoveOutDetailsFields(taskable, options);
};
