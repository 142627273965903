import React, { useRef, useEffect, useState } from 'react';

export const VideoBG = ({ video, type='video/mp4', className }) => {
  const videoElement = useRef();
  const [showVideo, setShowVideo] = useState();
  const videoFadeLength = 2;
  useEffect(() => {
    const videoRef = videoElement.current;
    if(!videoRef) return;
    videoElement.current.load();
    let fadeOutTimer;
    const playVideo = () => {
      const videoLength = videoRef.duration;
      setShowVideo(true);
      videoRef.play()
        // async handler because https://developer.chrome.com/blog/play-request-was-interrupted/
        .then(() => {
          fadeOutTimer = setTimeout(() => setShowVideo(false),(videoLength-videoFadeLength)*1000);
        })
        .catch(() => {
          // if video can't play don't keep trying to start it.
          videoRef.removeEventListener('ended',playVideo,true);
        });
    };
    videoRef.addEventListener('loadeddata',playVideo,true);
    videoRef.addEventListener('ended',playVideo,true);
    return () => {
      videoRef.removeEventListener('loadeddata',playVideo,true);
      videoRef.removeEventListener('ended',playVideo,true);
      if(fadeOutTimer) clearTimeout(fadeOutTimer);
    };
  },[videoElement, video]);

  return video && (
    <video
      width="100%"
      height="100%"
      muted
      ref={videoElement}
      style={{
        opacity: showVideo ? 1 : 0,
        transition: `opacity ${videoFadeLength}s ease-in`,
      }}
      className={className}
    >
      <source src={video} type="video/mp4"/>
    </video>
  );
};
