import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'USER_GET_CLAIM_CODE',
  request: (code) => request.get(`/residents/tenant-events/${code}`).then(r => get(r,'data.data.tenant_event')),
};

export const {
  action,
  reducer,
  usePending: useClaimCodePending,
} = generate.redux(creator);

export const initialState = {};
