import React from 'react';

import { Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import CSS from './styles/GridRewardsModal.module.scss';

import GridRewardsDetails from './gridrewards_details.json';

const gridRewardsDetails = {...GridRewardsDetails};

const openGridRewards = () => {
  window.open('https://www.gridrewards.com/','_blank','noopener');
};

export const GridRewardsModal = () => (
  <div className={CSS.sizer}>
    <div className={CSS.left_side}>
      <div className={CSS.container}>
        <ResidentContentRenderer content={gridRewardsDetails} />
      </div>
      <div className={CSS.button}>
        <Button
          className={CSS.button}
          text='Visit GridRewards'
          icon={{library: 'navigation', symbol: 'Up-right'}}
          iconPosition='right'
          color='secondary'
          onClick={openGridRewards}
        />
      </div>
    </div>
    <div className={CSS.side_column} style={{
      backgroundImage: `url('https://moved-assets.s3.amazonaws.com/service-providers/gridrewards/gridrewards_side_image.png')`,
      backgroundColor: '#6943D1'
    }} />
  </div>
);
