import React from 'react';
import { useFormikContext } from 'formik';

import { Button } from './Button';

export const FormButton = ({ type, onClick, ...props }) => {
  const { submitForm, resetForm } = useFormikContext();
  const handleClick = () => {
    if(onClick) onClick();
    if(type === 'submit') submitForm();
    if(type === 'reset') resetForm();
  };
  return (
    <Button
      {...props}
      type={type}
      onClick={handleClick}
    />
  );
};
