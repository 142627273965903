import React from 'react';
import { get } from 'lodash';

import { useModal, useSnippets } from '@moved/services';

import { useActiveMoveStep } from '../../dashboard';
import { useBuilding } from '../../common/actions/selectors';
import { SnippetEditorModal } from './';

const openEditModal = (Modal, list, activeMoveStep) => Modal.open(<SnippetEditorModal list={list} activeMoveStep={activeMoveStep} />);

export const SnippetEditor = ({ className, children }) => {
  // Hooks
  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(get(activeMoveStep,'building.slug'));
  const Modal = useModal();
  const Snippets = useSnippets();

  if(!building) return null; // hide for marketplace

  return (
    <div
      className={className}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        openEditModal(Modal, Snippets.list, activeMoveStep);
      }}
    >
      {children}
    </div>
  );
};
