const clean = (urlString) => {
  if(!urlString) return;
  urlString = urlString.replace(' ',''); // remove whitespace
  if (!/^(?:f|ht)tps?:\/\//.test(urlString)) urlString = "https://" + urlString; // ensure protocol
  return urlString;
}

// This formatter returns the browser native URL object
// (after enforcing some cleanup on the url)
// and on fail it returns an empty object
export const url = (urlString) => {
  try {
    return new URL(clean(urlString));
  }
  catch(err) {
    return {};
  }
};

// This formatter is a convenience extension of the previous formatter
// it exposes a single attribute getter for URL.hostname, with additional
// simplification to remove `www.` if it exists.
export const simpleHostname = (urlString) => {
  let URL = url(urlString);
  let hostname = URL.hostname || '';
  if (hostname.indexOf('www.') > -1) hostname = hostname.split('www.')[1];
  return hostname;
}
