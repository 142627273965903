import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_ADDON_INTERESTS',
  request: (taskID, data) => request.post(`/move-tasks/${taskID}/addon-interests`, data).then(r => r.data.data.addon_interests),
  reducers: {
    success: (state, { response: addonInterests }) => {
      return {
        ...state,
        addon_interests: {
          ...state.addon_interests,
          [addonInterests.id]: addonInterests,
        },
      };
    }
  }
});
