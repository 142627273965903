import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { format } from '@moved/services';
import { CompleteCard, Icon } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle, CompleteScreenActions } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/Payment.module.scss';

export const DepositSummary = ({ screen, taskDetails, taskDefinition, moveId, origin, taskBaseRoute, onClose }) => {
  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <CompleteCard status={'approved'}
              flavor={
                taskDetails.balance ? (
                  <Snippet tag={'tasks.deposit.summary.partial'}>Your payment has been accepted.</Snippet>
                ) : (
                  <Snippet tag={'tasks.deposit.summary.full'}>Your security deposit has been paid in full!</Snippet>
                )
              }
            >
              <div className={CSS.attributes}>
                <div className={CSS.section}>
                  <div className={CSS.line_item}>
                    <div className={classNames(CSS.line_label, CSS.black)}>Total Amount</div>
                    <div className={classNames(CSS.line_value, CSS.black)}>{format.currency(taskDetails.total)}</div>
                  </div>
                </div>
                <div className={CSS.section}>
                  <div className={CSS.line_item}>
                    <div className={classNames(CSS.line_label, CSS.black)}>Payments</div>
                  </div>
                  { taskDetails.payments.map((payment) => (
                    <div className={CSS.line_item} key={payment.id}>
                      <span className={classNames(CSS.line_label, CSS.black)}>
                        <Icon size='20px' symbol='Check' className={CSS.line_icon} />
                        { (payment.firstname && payment.lastname) ? `${payment.firstname} ${payment.lastname}` : 'Name unspecified' }
                        <span className={CSS.line_subtext}>{format.date(payment.created_at,'dateShort')}</span>
                      </span>
                      <span className={classNames(CSS.line_value, CSS.black)}>{format.currency(payment.amount)}</span>
                    </div>
                  ))}
                </div>
                <div className={CSS.line_item}>
                  <h3 className={classNames(CSS.line_label, CSS.black)}>Remaining Balance</h3>
                  <h3 className={classNames(CSS.line_value, CSS.black)}>{format.currency(taskDetails.balance)}</h3>
                </div>
                { taskDetails.balance ? (
                  <div className={CSS.line_item}>
                    <Link
                      to={`${taskBaseRoute}${taskDefinition.getStartOverRoute(taskDetails)}`}
                      id={`${taskDefinition.slug}_${screen.slug}_payment_button`}
                      className={'btn-gray btn--full btn--small'}
                    >
                      Make another payment
                    </Link>
                  </div>
                ) : null}
              </div>
            </CompleteCard>
          </div>
        </div>

        <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />

      </div>

    </div>
  )
};
