import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_UHAUL_PRODUCTS',
  request: (id) => request.get(`/residents/moves/${id}/uhaul-product-ads`).then(r => r?.data?.data?.uhaul_product_ads),
  selector: (state, id) => state?.uhaulProducts?.[id],
  reducers: {
    success: (state, { response: uhaulProducts, params: [id] }) => {
      return {
        ...state,
        uhaulProducts: {
          ...state.uhaulProducts,
          [id]: uhaulProducts,
        },
      }
    }
  }
}

export const {
  action,
  reducer,
  useResource: useUhaulProducts,
  usePending: useUhaulProductsPending,
} = generate.redux(creator);

export const initialState = {
  uhaulProducts: {},
};
