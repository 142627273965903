import { merge } from 'lodash';
import { generate } from '@moved/services';

import * as common from '../features/common/actions/reducer';
import * as contact from '../features/contact/actions/reducer';
import * as marketplace from '../features/marketplace/actions/reducer';
import * as landingPages from '../features/landingPages/actions/reducer';
import * as moves from '../features/moves/actions/reducer';
import * as personalTasks from '../features/personalTasks/actions/reducer';
import * as snippets from '../features/snippets/actions/reducer';
import * as surveys from '../features/surveys/actions/reducer';
import * as tasks from '../features/tasks/actions/reducer';
import * as user from '../features/user/actions/reducer';

const initialState = merge(
  { requests: {} },
  common.initialState,
  contact.initialState,
  marketplace.initialState,
  landingPages.initialState,
  moves.initialState,
  personalTasks.initialState,
  snippets.initialState,
  surveys.initialState,
  tasks.initialState,
  user.initialState,
);

const reducers = [
  ...common.reducers,
  ...contact.reducers,
  ...marketplace.reducers,
  ...landingPages.reducers,
  ...moves.reducers,
  ...personalTasks.reducers,
  ...snippets.reducers,
  ...surveys.reducers,
  ...tasks.reducers,
  ...user.reducers,
];

export const reducer = generate.rootReducer(initialState, reducers);
