import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { Textarea } from '@moved/ui';

import { updateTask } from '../../actions/updateTask';

import CSS from './TaskNotes.module.scss';

export const TaskNotes = ({ taskDetails }) => {
  const dispatch = useDispatch();

  const [notes, setNotes] = useState(taskDetails.notes);

  const saveNotes = useDebouncedCallback(
    (updatedNotes) => dispatch(updateTask(taskDetails.id, { notes: updatedNotes })),
    500,
  );

  const updateNotes = (updated) => {
    setNotes(updated);
    saveNotes(updated);
  };

  return (
    <div className='stackVertical gap-12'>
      <div className='labelL contentPrimary paddingBottom-4 paddingTop-4'>
        Notes
      </div>
      <Textarea
        name='note'
        value={notes ?? ''}
        isControlled={true}
        onChange={({ note })=> updateNotes(note)}
        className={CSS.notes}
        placeholder='Write notes here...'
        autoResize={true}
      />
    </div>
  );
}
