import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { filter, union } from 'lodash';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { DynamicForm } from '@moved/ui';

import { Snippet } from '../../../../snippets';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import keyCSS from './styles/KeyPickup.module.scss';

export const KeySelectRoommates = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const updatePending = taskDefinition.selectors.useUpdateRequestPending();
  const createPending = taskDefinition.selectors.useCreateRequestPending();
  const pending = updatePending || createPending;

  const keyPickupTask = taskDefinition.selectors.useTaskable(id);
  const { roommate_keys = [] } = keyPickupTask;

  const approvedRequest = (keyPickupTask?.requests ?? []).find(request => request.status === 'approved');
  const draftRequest = keyPickupTask?.requests?.[0]?.status === 'draft' ? keyPickupTask.requests[0] : null;

  // Submit form, create or update depending
  const handleSubmit = data => {
    if (pending) return;

    if(approvedRequest?.tenant_key_ids) data.tenant_key_ids = union(approvedRequest.tenant_key_ids,data.tenant_key_ids);
    else data.tenant_key_ids.push(keyPickupTask.current_tenant_key.id);

    if(draftRequest) {
      dispatch(taskDefinition.actions.updateRequest(draftRequest.id, { ...data }))
        .then(nextScreen)
        .catch(err => Notify.error(format.error(err)));
    } else {
      dispatch(taskDefinition.actions.createRequest(id))
        .then((resp) => dispatch(taskDefinition.actions.updateRequest(resp?.requests?.[0]?.id, {
          ...data,
          ...(keyPickupTask?.building_calendar_options?.length === 1
              ? {'building_calendar_id': keyPickupTask?.building_calendar_options?.[0]?.id }
              : null
          ),
        })))
        .then(nextScreen)
        .catch(err => Notify.error(format.error(err)));
    }
  }

  const getCoveredTenants = (tenants,current) => format.commaList(
    filter(tenants,tenant => `${current.firstname} ${current.lastname}` !== `${tenant.firstname} ${tenant.lastname}`)
      .map(tenant => `${tenant.firstname} ${tenant.lastname}`)
  );

  // Add notebox to item if user is rescheduling and has additional covered_tenants in their approved request
  if(approvedRequest?.tenant_key_ids?.length > 1) {
    screen.noteBox = {
      title: 'Reminder',
      copy: `You will also be responsible for ${getCoveredTenants(approvedRequest?.covered_tenants,keyPickupTask?.current_tenant_key)} at your appointment, and they've been notified of this.`,
    };
  }

  // Render
  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <DynamicForm
              id='roommate-form'
              fields={[
                {
                  label: (
                    <Snippet tag='tasks.keys.roommates.select_roommates_label' description='Roommate select label'>
                      Select if you are also picking up keys for these residents.
                    </Snippet>
                  ),
                  type: 'title',
                },
                {
                  name: 'tenant_key_ids',
                  type: 'itemList',
                  value: draftRequest ? draftRequest.tenant_key_ids : [],
                  required: false,
                  isMulti: true,
                  className: keyCSS.roommates_form,
                  options: filter(
                      roommate_keys,
                      key => !key.key_pickup_appointment_id
                    ).map(key => {
                      return {
                        label: `${key.firstname} ${key.lastname}`,
                        value: parseInt(key.id),
                      }
                    })
                },
              ]}
              onSubmit={handleSubmit}
              disabled={pending}
            />

          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              id='roommate_form_button'
              htmlFor="roommate-form-submit"
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', 'btn--full', {'loading': pending})}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>
      </div>
    </div>
  )
};
