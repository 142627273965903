import { merge } from 'lodash';

import  * as sendMessage from './sendMessage';

export const reducers = [
  sendMessage.reducer,
];

export const initialState = merge(
  sendMessage.initialState,
);
