import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CONTACT_SEND_MESSAGE',
  request: (data={}) => request.post(`/residents/contact`, data),
};

export const {
  action,
  reducer,
  usePending: useSendMessagePending,
} = generate.redux(creator);

export const initialState = {};
