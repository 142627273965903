import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_KEY_PICKUP_TASK',
  request: (taskID,data={}) => request.post(`/move-tasks/${taskID}/key-pickup-tasks`, data).then(r => r.data.data.key_pickup_task),
  reducers: {
    success: (state, { response: keyPickupTask }) => {

      return {
        ...state,
        keyPickupTasks: {
          ...state.reservations,
          [keyPickupTask.id]: keyPickupTask,
        },
      };
    }
  }
});
