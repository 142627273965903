import React from 'react';

export const AVBTermsConditionsModal = (props) => {

  return (<>
    <h3>SECURITY DEPOSIT TERMS AND CONDITIONS</h3>
    <h5>
      Authorization for a One-Time Electronic Funds Transfer
    </h5>
    <p>
      By submitting this form, I give my electronic signature and authorize AvalonBay Communities, Inc. or its designee (“Avalon”) to initiate an electronic debit entry to the bank account I identify to Avalon (“Designated Account”) in the amount I select. I understand that Avalon may initiate the entry immediately and I will not be able to cancel it. If the entry is rejected or otherwise does not complete, I authorize Avalon to reattempt such entry up to three more times over the next ten (10) business days. I agree that the payment debited hereunder shall be the same as if it were made via an instrument personally signed by me.
    </p>
    <p>
      I represent and warrant to AvalonBay that:
    </p>
    <p>
      I have provided full and accurate bank account and routing information for the Designated Account;
    </p>
    <p>
      I am the sole account holder of the Designated Account or, if a joint account, I have authority to act alone with regard to authorizing payments from the Designated Account; and
    </p>
    <p>
      I have authority to provide this authorization.
    </p>
    <p>
      This authorization will remain in effect until successful completion of the authorized payment or Avalon’s decision to stop attempting such payment, whichever is earlier.
    </p>
    <p>
      I understand that any cancellation will become effective within three (3) business days of receipt by Avalon but no earlier than five (5) business days after the day the last, or any pending, transaction has cleared. Any charges assessed by my Bank or other financial institution on account of insufficient funds or incorrect enrollment information are my responsibility.
    </p>
    <p>
      I agree I am also responsible for any electronic funds transfer fees or similar fees which may be incurred or charged by my Bank. I agree to immediately notify Avalon, in writing, of any closure of the Designated Account. I also understand that my liability for unauthorized transfers is largely determined by how quickly I report such transfers; therefore, I also agree to immediately notify my financial institution and Avalon, in writing, if I suspect that someone has transferred or may transfer money from my Designated Account without authorization. I release Avalon and its agents from any and all liability as a result of any incorrect or unauthorized transfers, including, but not limited to, any consequential damages as a result of any incorrect or unauthorized transfer, except as may be related to the gross negligence of Avalon.
    </p>
    <p>
      I agree to indemnify, defend, and hold harmless Avalon, its officers, directors, employees, agents, successors, and assigns, from and against all claims as a result of my violation of or failure to comply with this authorization, including, but not limited to,the breach of any representation or warranty.
    </p>
    <p>
      I hereby acknowledge receipt of notice of my liability for any unauthorized electronic fund transfers, duty to promptly report such unauthorized transfers, charges for electronic funds transfers, the right to stop payment or pre-authorized electronic fund transfers, procedure to initiate such stop payment order, the right to receive documentation of electronic fund transfers, and the Bank’s liability pursuant to the Electronic Funds Transfer Act found at 15 U.S.C. § 1693, et seq., and as against Avalon, waive all rights thereunder.
    </p>
    <p>
      If you have questions regarding your authorization, please write to Avalon at:
    </p>
    <p>
      AvalonBay Communities, Inc.<br/>
      Attn: EFT Cancellation<br/>
      671 N. Glebe Road, Suite 800<br/>
      Arlington, VA 22203
    </p>
    <p>
      Please include your name, address, phone number, and account number in your correspondence.
    </p>
    <p>
      Last Revised: January 17, 2019.
    </p>
  </>);

};
