import React from 'react';
import { format } from '@moved/services';
import { StatusPill } from '@moved/ui';

export const getRequestFields = (request, includeStatusPill) => {
  if(!request || !request.start_time) return [];
  return [
    includeStatusPill && {
      icon: { symbol: 'Info-circle', library: 'code', },
      label: 'Status',
      value: <StatusPill status={request.status}/>,
    },
    {
      label: `${request.status === 'approved' ? 'Approved' : 'Requested'} move date`,
      value: format.date(request.start_time,'date'),
      icon: { symbol: 'Date', library: 'code', },
    },
    {
      label: `${request.status === 'approved' ? 'Approved' : 'Requested'} move time`,
      value: request.start_time ? `${format.date(request.start_time,'time')} - ${format.date(request.end_time,'time')}` : null,
      icon: { symbol: 'Reserve-move-date', library: 'task', },
    },
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ].filter(v=>v);
};
