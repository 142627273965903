import { format } from '@moved/services';

export const getArtifactFields = (artifact={}) => ([
  {
    label: 'Reservation date',
    value: format.date(artifact.start_time,'date'),
    icon: { symbol: 'Date', library: 'code', },
  },
  artifact.start_time && artifact.end_time && {
    label: 'Reservation time',
    value: artifact.start_time ? `${format.date(artifact.start_time,'time')} - ${format.date(artifact.end_time,'time')}` : null,
    icon: { symbol: 'Time', library: 'code', },
  },
  artifact.created_by_user && {
    label: 'Completed by',
    value: format.fullname(artifact.created_by_user),
    icon: { symbol: 'User', library: 'general' },
  },
].filter(v=>v));
