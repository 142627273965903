import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_BADGE_UPLOAD',
  request: (id) => request.get(`/residents/badge-upload-tasks/${id}`).then(r => get(r,'data.data.badge_upload_task')),
  selector: (state, id) => get(state,`badges.${id}`),
  reducers: {
    success: (state, { response: badge }) => {
      return {
        ...state,
        badges: {
          ...state.badges,
          [badge.id]: badge,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useBadgeUpload,
  usePending: useBadgeUploadPending,
} = generate.redux(creator);

export const initialState = {
  badges: {},
};
