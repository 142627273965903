import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';
import classNames from 'classnames';

import { s3, useNotify, useUser } from '@moved/services';
import { Uploader, Icon } from '@moved/ui';

import { ScreenTitle } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import coiCSS from './styles/CoiSubmit.module.scss';

const options = {
  accept: {
    'image/jpg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc','.docx'],
  },
  maxFiles: 1,
  multiple: false,
};

// COI Submit Screen component
export const Submit = ({ screen, nextScreen, taskDefinition }) => {
  const { id, taskType } = useParams();
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const Notify = useNotify();
  const dispatch = useDispatch();
  const { user } = useUser();

  const coiSubmission = taskDefinition.selectors.useTaskable(id);
  const activeRequest = taskDefinition.helpers.getActiveRequest(coiSubmission);
  const [downloaded, setDownloaded] = useState(false);
  const [file, setFile] = useState(false);
  const [pending, setPending] = useState(false);

  const handleNext = e => {
    e.preventDefault();

    if(!file || pending) return;
    setPending(true);

    dispatch(getS3UploadUrl(building.id, {
      filename: s3.getUniqueFilename(file.name, user),
      http_content_type: file.type,
      subdirectory: `coi`,
    }))
      .then(({ signed_request, url }) => s3.putFile(file, signed_request).then(() => url))
      .then(fileUrl => {
        if (activeRequest) {
          return dispatch(taskDefinition.actions.updateRequest( // the return here ensures the next `then` in the chain must wait for this new promise to also resolve
            activeRequest.id,
            { file_url: fileUrl }
          ));
        } else {
          return dispatch(taskDefinition.actions.createRequest( // the return here ensures the next `then` in the chain must wait for this new promise to also resolve
            id,
            { file_url: fileUrl }
          ));
        }
      })
      .then(resp => dispatch(taskDefinition.actions.submitRequest(resp.requests[0].id)))
      .then(nextScreen)
      .catch(err => {
        Notify.error();
        setPending(false);
      });
  }

  const handleSelect = files => setFile(files[0]);
  const handleReject = files => Notify.error('Oops! The file does not meet the file type requirement.');

  return (
    <div className={taskCSS.holder}>

      <div className={classNames(taskCSS.task,coiCSS.coi_container)}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div style={{maxWidth:'600px'}}>
            <h4>You do not need to complete a COI if the following situations apply:</h4>
            <ul>
              <li>You are booking with a pre-approved company via Moved</li>
              <li>Moving yourself</li>
            </ul>
            <h4>You are required to complete the COI if you are hiring your own moving company.</h4>
            <p>
              Your building has specific terms and coverage requirements outlined
              in the document below. Download the document and send to your
              moving company. Once they complete the form, return and complete step 2
              to submit your filled out COI for approval.
            </p>
            <div className={'mb-20'}>
              <span className={'h6 mr-5'}>Step 1.</span>&nbsp;<span className={'bold'}>Download COI requirements</span>
            </div>
            <div className={'mb-50'}>
              <a href={`${process.env.REACT_APP_S3_ASSET_URL}/buildings/${building.slug}/coi.pdf`}
                onClick={() => setDownloaded(true)}
                id={`${taskType}_${screen.slug}_download_button`}
                className={classNames('btn-white btn--full btn--small',
                  {[coiCSS.downloaded]: downloaded},
                  {'btn--shadow': !downloaded} )}
                target="_blank" rel="noopener noreferrer">
                { downloaded ? (
                  <>
                    <Icon size="16px" symbol={'Check'} color={'green'} className={coiCSS.icon+' complete'} />
                    Successfully downloaded
                  </>
                ) : (
                  <>
                    <Icon size="16px" symbol={'Download'} className={coiCSS.icon} />
                    Download document
                  </>
                )}
              </a>
            </div>
            <div className={'mb-20'}>
              <span className={'h6 mr-5'}>Step 2.</span>&nbsp;<span className={'bold'}>Upload completed COI</span>
            </div>
            <div className={'mb-20'}>
              <Uploader id={`${taskType}_${screen.slug}_upload`} dzOptions={options} onSelect={handleSelect} onReject={handleReject} />
            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <button
              id={`${taskType}_${screen.slug}_upload_button`}
              className={classNames('btn-primary btn--full',{'loading':pending})}
              onClick={handleNext}
              disabled={!file}
            >
              Submit
            </button>
          </div>
        </div>


      </div>

    </div>
  );
};
