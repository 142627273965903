import * as create from './create';
import * as get from './get';
import * as update from './update';

import * as cancelRequest from './cancelRequest';
import * as createRequest from './createRequest';
import * as submitRequest from './submitRequest';
import * as updateRequest from './updateRequest';

import * as cancelAppointment from './cancelAppointment';

import * as getCalendar from './getCalendar';

export const reducers = [
  create.reducer,
  get.reducer,
  update.reducer,
  // Appointment Request
  cancelRequest.reducer,
  createRequest.reducer,
  updateRequest.reducer,
  submitRequest.reducer,
  // Appointment
  cancelAppointment.reducer,
  // Calender
  getCalendar.reducer,
];

export const initialState = {
  requests: {
    TASKS_GET_BUILDING_CALENDAR_PENDING: true,
    TASKS_GET_RESERVATION_PENDING: true,
  },
  reservations: {},
  buildingCalendar: [],
};
