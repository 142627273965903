import React from 'react';
import { get } from 'lodash';

export const getSummaryFields = (taskable) => {
  if(!taskable) return [];
  return [
    {
      label: (<>Additional&nbsp;offers</>),
      value: get(taskable,'selections').map(selection => selection.label).join(', ') || 'Not interested',
      icon: { symbol: 'Router#2', library: 'devices' },
    },
  ].filter(v=>v);
};
