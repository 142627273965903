import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import classNames from 'classnames';

import { useModal, useNotify, format } from '@moved/services';
import { Icon, StyledForm, CardSetupField, DynamicField } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

import { BookBreakdown } from './BookBreakdown';
import { LastMinuteNotice } from './LastMinuteNotice';
import { PastDeadlineWarning } from './PastDeadlineWarning';

import CSS from './styles/BookPayment.module.scss';

export const BookPayment = ({ screen, nextScreen, taskDetails, taskDefinition, moveId, goToScreen }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const stripeCard = useRef(); // to be assigned to CardSetupField component
  const dispatch = useDispatch();

  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const { discounts = [] } = taskDefinition.selectors.useMove(moveId);

  // STATE
  const [pending, setPending] = useState(false);

  // local data
  const validation = Yup.object({
    nameOnCard: Yup.string().required('Required'),
    contact_phone: Yup.string().required('Required'),
  });
  const initialValues = {
    nameOnCard: '',
    contact_phone: moverBooking?.contact_phone ?? '',
  };
  const phoneField = {
    label: 'Phone Number',
    name: 'contact_phone',
    type: 'tel',
  };

  const handleSubmit = ({ contact_phone }) => {
    if(pending || !stripeCard.current) return;
    if(!taskDefinition.canBookDate(moverBooking?.requested_pickup_date)) {
      return Modal.open(<PastDeadlineWarning />, {
        hideCloseButton: true,
        onClose:() => goToScreen({ slug:'date' }),
      })
    }
    setPending(true);
    stripeCard.current.confirmCard()
      .then(({setupIntent}) => {
        if(!setupIntent) return; // stripe input card validation error
        return dispatch(taskDefinition.actions.updateMoverBooking(moverBooking?.id, { contact_phone }))
          .then(() => dispatch(taskDefinition.actions.book(moverBooking?.id, { stripe_payment_method_id: setupIntent.payment_method })))
          .then(updatedMoverBooking => taskDefinition.updateMoverBookingForTask(updatedMoverBooking, taskDetails))
          .then(nextScreen);
      })
      .catch(error => {
        Notify.error(
          format.error(error,false,format.stripeError), // map stripe errors
          { autoHideDuration:6000 }, // slightly longer than normal display times
        );
      })
      .finally(() => setPending(false));
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <div className='stackHorizontal gap-48 stackVertical--mobile'>
          <div className='flex-auto'>

            <LastMinuteNotice moverBooking={moverBooking} />

            <StyledForm
              id="payment-form"
              onSubmit={handleSubmit}
              formStyle={'underline'}
              initialValues={initialValues}
              validation={validation}
              disabled={pending}
            >
              {form => (<>
                <DynamicField input={{ type: 'title', label: 'Card details', }} />
                <CardSetupField form={form} ref={stripeCard} />
                <DynamicField input={{
                  name: 'ui.contact_blurb',
                  content: (
                    <>
                      <div className='labelL contentPrimary marginTop-48 marginBottom-8'>Contact info</div>
                      <p>The moving company needs to have this on file so the crew can contact you on the day of the move.</p>
                    </>
                  ),
                  type: 'content',
                }} />
                <DynamicField form={form} input={phoneField} formStyle={'underline'} />
              </>)}
            </StyledForm>

          </div>
          <div className={CSS.breakdown}>
            <BookBreakdown
              moverBooking={moverBooking}
              discounts={discounts}
            />
          </div>

        </div>
      </ScreenContent>
      <ScreenActions>
        <div className='stackVertical gap-20 items-center'>
          <div className='stackHorizontal items-center'>
            <Icon symbol={'Lock'} size={'14px'} className={CSS.lock} />
            <span className='labelS contentSecondary'>We’ve partnered with <a href="https://stripe.com" target="_blank" rel="noopener noreferrer" className='contentPrimary'>Stripe</a> to ensure secure payment.</span>
          </div>

          <label
            id='payment-form-button'
            htmlFor="payment-form-submit"
            tabIndex="0"
            role="button"
            className={classNames('btn-primary btn--full',{'loading':pending})}
            disabled={pending}
          >
            Authorize card
          </label>
        </div>
      </ScreenActions>
    </ScreenLayout>
  );
}
