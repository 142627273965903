import { get, isNil } from 'lodash';
import { format } from '@moved/services';

export const getArtifactFields = (artifact) => {
  return [
    {
      label: 'Provider',
      value: get(artifact,'utility_provider.name'),
      icon: { symbol: 'Building', library: 'home' },
    },
    {
      label: 'Account number',
      value: get(artifact,'account_number'),
      icon: { symbol: 'File', library: 'files' },
    },
    {
      label: 'Confirmation number',
      value: get(artifact,'confirmation_number'),
      icon: { symbol: 'File-done', library: 'files' },
    },
    get(artifact,'file_url') && {
      label: 'Submitted documents',
      value: (
        <span>
          <a href={get(artifact,'file_url')} target="_blank" rel="noopener noreferrer">View file</a>
        </span>
      ),
      icon: { symbol: 'Uploaded-file', library: 'files' },
    },
    !isNil(get(artifact,'consent_to_share_data_with_program_provider')) && {
      label: 'Get GridRewards notifications',
      value: get(artifact,'consent_to_share_data_with_program_provider') ? 'Yes' : 'No',
      icon: { symbol: 'Done-circle', library: 'code' }
    },
    !isNil(get(artifact,'consent_to_share_data_with_property')) && {
      label: 'Share utility data for carbon emissions tracking',
      value: get(artifact,'consent_to_share_data_with_property') ? 'Yes' : 'No',
      icon: { symbol: 'Done-circle', library: 'code' }
    },
    {
      label: 'Provided by',
      value: get(artifact,'provided_by') ? format.fullname(artifact.provided_by) : null,
      icon: { symbol: 'User', library: 'general' },
    },
  ].filter(v=>v);

};
