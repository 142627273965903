import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'

import { format, useModal, useNotify } from '@moved/services';
import { ConfirmationModal, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

export const Complete = ({ screen, taskDefinition, taskDetails, taskSummary, origin, goToScreen, onClose }) => {
  // HOOKS
  const { taskId, moveId } = useParams();
  const dispatch = useDispatch();
  const Notify = useNotify();
  const Modal = useModal();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);
  const hasOverride = taskDefinition.helpers.getOverrides(taskSummary).length > 0;

  // Reset handler functions
  const confirmReset = () => {
    if(activeRequest?.status === 'rejected') return handleReset();
    else Modal.open(
      <ConfirmationModal
        title='Resubmit COI'
        copy={'Are you sure? This will replace your existing COI submission.'}
        confirmText='Continue'
        cancelText='Never mind'
        onConfirm={handleReset}
      />,
      { sondheim: true },
    );
  };
  const handleReset = () => {
    const resetTaskPromise = taskDetails?.id ? Promise.resolve(taskDetails) :
      dispatch(taskDefinition.actions.create(taskId))
    return resetTaskPromise
      .then(newTaskDetails => dispatch(taskDefinition.actions.createRequest(newTaskDetails.id)))
      // refresh the move because reset affects MT on the move
      .then(r => { dispatch(getMove(moveId)); return r; })
      .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch((error) => Notify.error(format.error(error)))
  };

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={[
        !hasOverride && { // overrides can't be reset
          value: (
            <Button
              size='small'
              color='secondary'
              onClick={confirmReset}
              text={taskDetails ? 'Submit new COI' : 'Submit a COI'}
              disabled={taskDefinition.selectors.useCreateCoiRequestPending()}
            />
          )
        }
      ].filter(Boolean)}
    />
  );
};
