import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { merge } from 'lodash';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { ResidentSupportEmailLink } from '@moved/product';
import { Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';
import { useSnippet } from '../../../../snippets/hooks/useSnippet';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

import CSS from './styles/Instructions.module.scss';

export const Instructions = ({ screen, nextScreen, taskDefinition, taskDetails }) => {
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();

  const { actions, helpers, selectors } = taskDefinition;

  const pending = selectors.useSubmitPetscreeningVerificationTaskPending();
  const moveStep = selectors.useActiveMoveStep();
  const building = selectors.useBuilding(moveStep?.building?.slug);

  const { content, title, subtitle, cta_text, side_column_image_url } = taskDetails?.instruction_content || {};
  const profile = helpers.getActiveRequest(taskDetails);

  const submit = () => {
    // if already submitted, just navigate forward
    if(taskDetails.submitted_at) return nextScreen();
    // otherwise submit and navigate forward
    dispatch(actions.submit(id))
      .then(nextScreen)
      .catch(error => Notify.error(format.error(error)));
  };

  const linkToPetscreening = () => {
    dispatch(actions.click(profile?.id))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => Notify.error(format.error(err)));
  };

  const noteBox = {
    title: 'Attention all Residents: Your Digital pet profile is required!',
    copy: `We require all applicants and residents to complete one of our three profile types: \n No Pet/Animal, Household Pet or Assistance Animal.`,
    color: 'orange',
    icon: {
      library: 'code',
      symbol: 'Warning-2',
    }
  };

  const startScreeningSnippet = useSnippet('tasks.petscreening.instructions.cta_text', 'PetScreening linkout button text');

  return (
    <ScreenLayout>
      <ScreenTitle screen={merge({}, screen, { title, subtitle, noteBox })} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <div className={CSS.content_wrapper}>
          <div className={CSS.instructions}>
            <ResidentContentRenderer content={content}
              tags={{
                due_date: profile?.due_date ?
                  format.date(profile?.due_date,'date') :
                  'TBD',
              }}
            />
            { profile ? (
              <>
                <Button
                  color='primary'
                  size='large'
                  className={classNames('marginTop-20',CSS.petscreeningButton)}
                  onClick={linkToPetscreening}
                  icon={{ symbol: 'Up-right', library: 'navigation' }}
                  iconPosition='right'
                  text={startScreeningSnippet ?? 'Start Screening'}
                />
              </>
            ) : (
              <div className='marginTop-28 marginBottom-28 labelM contentError'>
                Moved is unable to access your unique invitation link from PetScreening to start the screening process. Please contact Moved customer support at <ResidentSupportEmailLink />.
              </div>
            )}

            <div className={classNames('marginTop-20 labelM contentSecondary')}>
              This process is managed by <img className={classNames('marginLeft-6 marginRight-6', CSS.logo)} src={`${process.env.REACT_APP_S3_ASSET_URL}/service-providers/petscreening/petscreening-logo.png`} alt="PetScreening"/> on behalf of {building.name}
            </div>
          </div>

          { side_column_image_url && (
            <div className={CSS.side_column} style={{ backgroundImage: `url(${side_column_image_url})`}} />
          )}

        </div>
      </ScreenContent>

      <ScreenActions>
        <Button
          text={cta_text || 'I have completed my PetScreening'}
          onClick={submit}
          disabled={!profile || pending}
        />
      </ScreenActions>

    </ScreenLayout>
  );
}
