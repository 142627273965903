import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

export const getRequestFields = request => [
  {
    label: 'Submitted COI',
    value: get(request,'file_url') && (<a href={get(request,'file_url')} target="_blank" rel="noopener noreferrer">View File</a>),
    icon: { symbol: 'Uploaded-file', library: 'files' },
  },
  {
    label: 'Date received',
    value: format.date(get(request,'submitted_at')),
    icon: { symbol: 'Time', library: 'code' },
  },
  {
    label: 'Reason for decline',
    value: request?.notes,
    icon: { symbol: 'File', library: 'files' },
  },
];
