import { addonInterests as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class AddonInterests extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useAddonInterests,
        ...selectors,
      }
    });
  }

  static label = 'Addon Interests';
  static slug = 'addon-interests';
  static icon = 'Sushi';
  static helpers = helpers;

  _flowBuilder(taskData) {
    return [
      this.screens['offers'],
      this.screens['complete'],
    ];
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
