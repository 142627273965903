import React from 'react';

import { useModal } from '@moved/services';
import { ModalContent, ModalTitle, ModalActions, Button } from '@moved/ui';

import { ResidentContentRenderer } from '../../../../common';

export const KeyPickupInstructionsModal = ({instructions}) => {
  const { close } = useModal();
  return (
    <>
      <ModalTitle>Location instructions</ModalTitle>
      <ModalContent>
        <ResidentContentRenderer content={instructions} />
      </ModalContent>
      <ModalActions>
        <Button
          onClick={e => close(false)}
          text='I understand'
        />
      </ModalActions>
    </>
  );
};
