import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_BOOK_MOVER_BOOKING',
  request: (id, data) => request.post(`/mover-bookings/${id}/book`, data).then(r => r?.data?.data?.mover_booking),
  reducers: {
    success: (state, { response: booking }) => ({
      ...state,
      moverBookings: {
        ...state.moverBookings,
        [booking.id]: booking,
      },
    }),
  }
}

export const {
  action,
  reducer,
  usePending: useBookMoverBookingPending,
} = generate.redux(creator);
