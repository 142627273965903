import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useMove } from '../../moves/actions/selectors';
import { getRecommendedDashboardRoute } from '../helpers';

export const DashboardRedirect = () => {
  // HOOKS
  const { moveId } = useParams();
  const move = useMove(moveId);

  return <Redirect to={{ pathname:getRecommendedDashboardRoute(move) }}/>;
};
