import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'

import { useModal, useNotify, format } from '@moved/services';
import { ConfirmationModal, Button } from '@moved/ui';

import { getMove } from '../../../../moves/actions';
import { SummaryScreen } from '../../shared/components/SummaryScreen';

import CSS from './styles/Summary.module.scss';

export const Summary = ({ screen, taskDefinition, origin, goToScreen, onClose }) => {
  const { id, moveId } = useParams();
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();

  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);
  const reservation = taskDefinition.selectors.useTaskable(id);
  const pending = taskDefinition.selectors.useCreateRequestPending();

  const confirmResetReservation = () => {
    return Modal.open(
      <ConfirmationModal
        title={'Edit appointment'}
        copy={'Note: Your existing appointment will be held for you while you edit. It will be canceled and replaced with a new appointment, only once the new appointment has been submitted and confirmed by the property.'}
        confirmText={'Proceed with editing'}
        cancelText={'Never mind'}
        onConfirm={rescheduleReservation}
      />,
      { sondheim: true },
    );
  };

  const rescheduleReservation = () => {
    return dispatch(taskDefinition.actions.createRequest(id))
      // refresh the move because reset affects MT on the move
      .then(r => dispatch(getMove(moveId)).then(() => r))
      .then((resp) => goToScreen(taskDefinition.getStartOverScreen(resp)))
      .catch(error => Notify.error(format.error(error)));
  }

  const extraAttributes = [
    building?.settings?.building_reservation_rescheduling && {
      value: (
        <div className={CSS.reschedule}>
          <Button
            size='small'
            color='secondary'
            text='Request a different time'
            onClick={() =>reservation?.reservation?.status === 'approved' ? confirmResetReservation() : rescheduleReservation()}
            disabled={pending}
          />
        </div>
      )
    },
  ].filter(Boolean);

  return (
    <SummaryScreen
      origin={origin}
      screen={screen}
      moveId={moveId}
      onClose={onClose}
      extraAttributes={extraAttributes}
    />
  )
};
