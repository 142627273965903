import { get } from 'lodash';

import { generate, request } from '@moved/services';

const creator = {
  namespace: 'MOVES_CREATE_MOVE',
  request: (userId) => request.post(`/residents/users/${userId}/moves`).then(r => get(r,'data.data.move')),
};

export const {
  action,
  reducer,
  usePending: useCreateMovePending,
} = generate.redux(creator);

export const initialState = {};
