import { get } from 'lodash';

import * as screenComponents from './components';

export const screens = {

  'deposit-payment': {
    slug: 'payment',
    component: screenComponents.DepositPayment,
    label: 'Payment info',
    title: 'Pay security deposit',
    subtitle: 'Pay your security deposit and any upfront fees before completing other required tasks.',
    isCompleted: (data) => {
      const { payments = [], lease, balance } = data;
      const myPayments = payments.filter(payment => !lease || payment.tenant_id === lease.active_tenant.id);
      return balance === 0 || // already paid in full
        (
          get(data, 'lease.roommates', []).length && // has a lease with roommates
          myPayments.length // has made a payment
        );
    },
  },

  'deposit-summary': {
    slug: 'summary',
    component: screenComponents.DepositSummary,
    label: 'Summary',
    backRestricted: (data) => get(data,'balance') === 0,
    isCompleted: (data) => get(data,'balance') === 0,
  },

  'deposit-offline': {
    slug: 'payment',
    component: screenComponents.DepositOffline,
    label: 'Payment options',
    title: 'Pay security deposit',
    subtitle: 'The deadline to pay online has passed.',
    isCompleted: () => true,
  },

};
