import { generate, request } from '@moved/services';

const creator = {
  namespace: 'SURVEYS_CREATE_SATISFACTION_RESPONSE',
  request: (token, data) => request.post(`/residents/satisfaction-survey-responses/${token}`, data).then(r => r.data.data.satisfaction_survey_response),
  reducers: {
    success: (state, { response: request, params: [token] }) => {
      return {
        ...state,
          satisfactionResponses: {
          ...state.satisfactionResponses,
          [token]: request,
        },
      };
    },
  }
};

export const {
  action,
  reducer,
  usePending: useCreateSatisfactionResponsePending,
} = generate.redux(creator);

export const initialState = {};
