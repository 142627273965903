import React from 'react';

import { TaskListItem } from './TaskListItem';
import { ProgressBar } from './ProgressBar';

import CSS from './styles/TaskList.module.scss';

export const TaskList = ({ id, tasks, title, subtitle, message, progress = true, }) => {

  if(!tasks || tasks.length === 0) return null;

  return (
    <div id={id} className={CSS.task_area}>
      <div className={CSS.title_bar}>
        <div>
          { title && (<h3>{title}</h3>) }
          { subtitle && (<p>{subtitle}</p>) }
        </div>
        { progress && (<ProgressBar message={message} items={tasks} />) }
      </div>
      <div className={CSS.list}>
        { tasks.map(task => (
          <TaskListItem key={task.id} task={task} />
        ))}
      </div>
    </div>
  );
}
