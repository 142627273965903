import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { ChecklistEditor } from '@moved/ui';

import { updateTask } from '../../actions/updateTask';


export const TaskSubtasks = ({ taskDetails }) => {
  const dispatch = useDispatch();

  const [subtasks, setSubtasks] = useState(
    taskDetails?.subtasks?.map(({ title, ...task}) => ({
      ...task,
      text: title,
      isCompleted: task.completed_at != null,
    }))
  );

  const saveSubtasks = useDebouncedCallback(
    (updatedSubtasks) => {
      const data = {
        subtasks: updatedSubtasks
          .map(({ text, completed_at, created_at }) => ({
            title: text,
            completed_at,
            created_at,
          }))
          // NOTE: we need to filter tasks without text because reloading empty
          // taskItems causes the whole task list to render a default blank list
          .filter(task => task.title),
      };
      // on first render an "empty" subtask was added, but won't be saved, so avoid request
      if(data?.subtasks?.length === 0 && taskDetails?.subtasks?.length === 0) return;
      dispatch(updateTask(taskDetails.id, data));
    },
    500,
  );

  const updateSubtasks = (updated) => {
    const updatedSubtasks = updated.map(({ id, text, isCompleted }) => {
      const existingTask = subtasks.find(task => task.id === id);
      return {
        id,
        text,
        isCompleted,
        completed_at: isCompleted ? existingTask?.completed_at ?? (new Date()).toISOString() : null,
        created_at: existingTask?.created_at ?? (new Date()).toISOString(),
      };
    });
    setSubtasks(updatedSubtasks);
    saveSubtasks(updatedSubtasks);
  };

  return (
    <div className='stackVertical gap-12'>
      <div className='labelL contentPrimary paddingBottom-4 paddingTop-4'>
        Subtasks
      </div>
      <ChecklistEditor
        initialContent={subtasks}
        onChange={updateSubtasks}
        className='margin-0'
      />
    </div>
  );
}
