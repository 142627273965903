// import { request } from '@moved/services';
import { get } from 'lodash';
import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CORE_GET_BUILDING',
  request: (buildingSlug) => request.get(`/buildings/${buildingSlug}`).then(r => r.data.data),
  selector: (state, buildingSlug) => get(state,`buildings.${buildingSlug}`),
  cache: true,
  reducers: {
    success: (state, { response: building }) => ({
      ...state,
      buildings: {
        ...state.buildings,
        [building.slug]:building,
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useBuilding,
  usePending: useBuildingPending,
} = generate.redux(creator);

export const initialState = {
  buildings: {},
}
