import React from 'react';
import classNames from 'classnames';

import { Collapsible } from '@moved/ui';

import CSS from './styles/TrainDesktop.module.scss';

const getScreenKey = (screen) => (screen?.unknown && 'unknown') ?? `${screen?.slug ?? 'category'}-${screen?.context ?? ''}`;

export const TrainDesktop = ({
  taskDetails,
  taskDefinition,
  activeScreen,
  goToScreen,
}) => {

  // used to determine which top level screen is active
  const activeIndex = taskDefinition.flow?.findIndex?.(
    screen => activeScreen?.context ?
      screen.context === activeScreen.context :
      screen.slug === activeScreen.slug
  );
  // used to determine which nested screen is active
  const activeNestedIndex = taskDefinition.flow[activeIndex]?.screens ?
    taskDefinition.flow[activeIndex]?.screens?.findIndex?.(({ slug }) => slug === activeScreen.slug) :
    0;
  // used to determine if any of the screen numbers should be replaced with a question mark
  const mysteryIndex = taskDefinition.flow?.findIndex?.(screen => screen.unknown);

  // Calculate the height of the progress bar (not a fan, but don't want to refactor the whole thing)
  const activeProgressBarHeight = ((activeIndex + 1) * 76) + ((activeNestedIndex) * 36) - 16;

  // Convenience values for comparing to the recommended screen for styling
  const recommendedScreen = taskDefinition.getRecommendedScreen(taskDetails);
  const recommendedIndex = taskDefinition.flatFlow.findIndex?.(s => getScreenKey(s) === getScreenKey(recommendedScreen));

  const handleClick = (screen) => {
    if(screen.context && screen.screens) return handleClick(screen.screens[0]);
    if(!taskDefinition.canAccessScreen(taskDetails,screen.slug,screen.context)) return;
    goToScreen(screen);
  }

  const isScreenClickable = (screen) => {
    if(screen.unknown) return false;
    const screenIndex = taskDefinition.flatFlow.findIndex(s => getScreenKey(s) === getScreenKey(screen));
    const canAccess = taskDefinition.canAccessScreen(taskDetails,screen.slug,screen.context);
    return (canAccess && (screenIndex <= recommendedIndex || screenIndex === activeIndex));
  };

  const isScreenComplete = (screen) => {
    if(screen.unknown) return false;
    const screenIndex = taskDefinition.flatFlow.findIndex(s => getScreenKey(s) === getScreenKey(screen));
    return screen?.isCompleted?.(taskDetails,taskDefinition,screen.context) ?? (screenIndex < recommendedIndex || screenIndex < activeIndex);
  };

  return (
    <div className={classNames('stackVertical justify-center items-center',CSS.train)}>
      <div className={CSS.track}>
        { taskDefinition.flow.map((screen, index) => (
          <React.Fragment key={getScreenKey(screen)}>
            { screen.unknown ? (
              <span className={CSS.dotted} />
            ) : (
              <>
                <span
                  onClick={() => index !== activeIndex && handleClick(screen)}
                  className={classNames(CSS.stop, {
                    [CSS.clickable]: isScreenClickable(screen.screens ? screen.screens.at(0) : screen),
                    [CSS.completed]: isScreenComplete(screen) || screen.screens != null,
                    [CSS.current]: index === activeIndex,
                  })}
                >
                  <span className={CSS.number}>{mysteryIndex !== -1 && index > mysteryIndex ? '?' : index + 1}</span>
                  <span className={CSS.text}>{screen.label}</span>
                </span>
                { screen.screens && (
                  <Collapsible open={index === activeIndex}>
                    { screen.screens.map(nestedScreen => (
                      <span
                        key={getScreenKey(nestedScreen)}
                        onClick={() => handleClick(nestedScreen)}
                        className={classNames(CSS.subStep, {
                          [CSS.subStepActive]: isScreenClickable(nestedScreen)
                        })}
                      >
                        <span className={CSS.dot} />
                        <span className={CSS.text}>{nestedScreen.label}</span>
                      </span>
                    ))}
                  </Collapsible>
                )}
              </>
            )}
          </React.Fragment>
        ))}

        <div className={CSS.lines} style={{ height: activeProgressBarHeight }}>
          { taskDefinition.flow.map((screen, index) => {
            if(screen.unknown) return (
              <span key={'unknown'+index} className={CSS.unknown} />
            );
            if(screen.screens) return (
              <React.Fragment key={screen.context}>
                <span className={classNames(CSS.circle,  { [CSS.active_circle]: index >= activeIndex })} />
                { activeIndex === index && screen.screens.map(subScreen => (
                  <span key={subScreen.slug} className={CSS.subCircle} />
                ))}
              </React.Fragment>
            );
            return (
              <span key={screen.slug+''+screen.context} className={classNames(CSS.circle,  { [CSS.active_circle]: index >= activeIndex })} />
            );
          })}
        </div>

      </div>
    </div>
  );
};
