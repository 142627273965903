import React from 'react';

import { ProxyModeBadge, RouteTree } from '@moved/product';

import { routeTree } from '../routes';

import CSS from '../styles/App.module.scss';

export const App = () => (
  <div className={CSS.consumer_app}>
    <RouteTree routeTree={routeTree} defaultDomains={['customer']} />
    <ProxyModeBadge />
    <div id='customer-contact-form'/>
  </div>
);
