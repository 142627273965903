import {
  Schedule,
  Summary,
} from './components';

export const screens = {

  'schedule': {
    slug: 'schedule',
    component: Schedule,
    label: 'Schedule',
    title: 'Schedule a date & time to conduct your pre-move-out inspection',
    subtitle: 'A representative from property management will take a tour of your apartment with you to prepare for move-out.',
    maxWidth: 804,
    isCompleted: (taskable, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(taskable);
      return activeRequest ?
        activeRequest?.status !== 'draft' :
        taskable?.reservation?.status === 'approved';
    },
  },

  'summary': {
    slug: 'summary',
    component: Summary,
    label: "Summary",
    backRestricted: true,
    isCompleted: (taskable, { helpers }) => {
      const activeRequest = helpers.getActiveRequest(taskable);
      return activeRequest?.status === 'approved';
    },
  },

};
