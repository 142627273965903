import { merge } from 'lodash';

import * as book from './book';
import * as createMoverBooking from './createMoverBooking';
import * as getMoverBooking from './getMoverBooking';
import * as getRates from './getRates';
import * as getSoldOutDates from './getSoldOutDates';
import * as getSpecialList from './getSpecialList';
import * as longDistanceEmail from './longDistanceEmail';
import * as retryPayment from './retryPayment';
import * as retryPreauth from './retryPreauth';
import * as updateMoverBooking from './updateMoverBooking';

export const reducers = [
  book.reducer,
  createMoverBooking.reducer,
  getMoverBooking.reducer,
  getRates.reducer,
  getSoldOutDates.reducer,
  getSpecialList.reducer,
  longDistanceEmail.reducer,
  retryPayment.reducer,
  retryPreauth.reducer,
  updateMoverBooking.reducer,
];

export const initialState = merge(
  createMoverBooking.initialState,
  getMoverBooking.initialState,
  getRates.initialState,
  getSoldOutDates.initialState,
  getSpecialList.initialState,
);
