import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_CREATE_SECURITY_DEPOSIT',
  request: (taskID, data={}) => request.post(`/move-tasks/${taskID}/security-deposits`, data).then(r => r.data.data.security_deposit),
  reducers: {
    success: (state, { response: securityDeposit }) => {
      return {
        ...state,
        securityDeposits: {
          ...state.securityDeposits,
          [securityDeposit.id]: securityDeposit,
        },
      }
    }
  }
});
