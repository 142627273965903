import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CANCEL_KEY_PICKUP_REQUEST',
  request: (id, data={}) => request.post(`residents/key-pickup-appointment-requests/${id}/cancel`, data).then(r => r.data.data.key_pickup_task),
  reducers: {
    success: (state, { response: keyPickupTask }) => {
      return {
        ...state,
        keyPickupTasks: {
          ...state.keyPickupTasks,
          [keyPickupTask.id]: keyPickupTask,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCancelRequestPending,
} = generate.redux(creator);
