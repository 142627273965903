import React from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { LoaderOverlay, Card, Button } from '@moved/ui';

import { getMarketplaceAdRedirect } from '../actions';
import { useMarketplaceAdRedirectPending } from '../actions/selectors';

import CSS from './styles/MarketplaceAd.module.scss';

export const MarketplaceAd = ({ ad, moveId, origin, className }) => {
  const dispatch = useDispatch();
  const redirectPending = useMarketplaceAdRedirectPending();

  const handleClick = () => {
    if(redirectPending) return;
    dispatch(getMarketplaceAdRedirect({
      move_id: moveId,
      partner_marketplace_ad_id: ad.id,
      origin,
    })).then(redirect => {
      window.open(decodeURIComponent(redirect.url));
    });
  };

  return (
    <Card
      onClick={() => handleClick(ad)}
      className={classNames('stackVertical gap-12',CSS.ad_block,className)}
    >
      { redirectPending && (<LoaderOverlay />)}
      <div className={CSS.graphic_block} style={{backgroundImage:`url('${ad.background_image_url}')`}}>
        <img className={CSS.logo} src={ad.logo_url} alt={ad.brand_name}/>
        <div className={CSS.overlay}>
          <Button
            icon={{ library: 'navigation', symbol: 'Up-right' }}
            text='Access offer'
            size='large'
            color='secondary'
            iconPosition='right'
          />
        </div>
      </div>
      <div className={classNames('stackVertical gap-4',CSS.content_area)}>
        <div className='labelM contentSecondary'>{ ad.brand_name }</div>
        <div className='headingS contentPrimary'>{ ad.headline }</div>
        { !isEmpty(ad.content) && (
          <div className={CSS.block_content_area}></div>
        )}
      </div>
    </Card>
  );
};
