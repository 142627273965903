import React from 'react';
import { get } from 'lodash';

import { StatusPill } from '@moved/ui';

import { getRequestFields } from './getRequestFields';

// TODO: revisit this as inherited from reserve task
export const getActiveReservation = (taskable) => {
  return get(taskable,'reservation.status') === 'approved' ? get(taskable,'reservation') :
    get(taskable,'requests').find(request => request.status === 'approved');
};

export const getSummaryFields = (taskable) => {

  const approvedReservation = getActiveReservation(taskable);
  const activeRequest = get(taskable,'requests[0]');

  if(['pending-approval','rejected','draft'].includes(get(activeRequest,'status')) && approvedReservation) {
    return [
      ...getRequestFields(activeRequest,true),
      {
        icon: { symbol: 'Info-circle', library: 'code', },
        label: 'Approved request status',
        value: <StatusPill status={'on-hold'}/>,
      },
      ...getRequestFields(approvedReservation),
    ];
  }
  return getRequestFields(approvedReservation || activeRequest, true);
};
