import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_RENTER_INSURANCE_REQUEST',
  request: (id, data={}) => request.patch(`residents/renters-insurance-policy-requests/${id}`, data).then(r => r.data.data.renters_insurance_task),
  reducers: {
    success: (state, { response: insuranceTask }) => ({
      ...state,
      renterInsurances: {
        ...state.renterInsurances,
        [insuranceTask.id]: insuranceTask,
      },
    }),
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateRenterInsuranceRequestPending,
} = generate.redux(creator);

export const initialState = {};
