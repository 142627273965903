import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get, find } from 'lodash';

import { useModal, useNotify, useUser, format } from '@moved/services';
import { DynamicForm, Notebox  } from '@moved/ui';

import { ScreenLayout, ScreenTitle, ScreenContent, ScreenActions } from '../../shared';
import { getS3UploadUrl } from '../../shared/actions';
import { GridRewardsModal } from './GridRewardsModal';
import { ProviderDetails } from './ProviderDetails';

import CSS from './styles/SelectProvider.module.scss';

export const AccountDetails = ({ screen, taskDefinition, nextScreen, previousScreen }) => {
  const { id, taskType } = useParams();
  const Modal = useModal();
  const { user } = useUser();
  const Notify = useNotify();
  const dispatch = useDispatch();
  // redux
  const activeMoveStep = taskDefinition.selectors.useActiveMoveStep();
  const building = taskDefinition.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const utilitySetup = taskDefinition.selectors.useUtilitySetup(id);
  const updatePending = taskDefinition.selectors.useUpdateUtilityRequestPending();
  const submitPending = taskDefinition.selectors.useSubmitUtilityRequestPending();
  const pending = updatePending || submitPending;

  const activeRequest = taskDefinition.helpers.getActiveRequest(utilitySetup);
  const activeRequestIsMyRequest = get(activeRequest,'submitted_by.user_id') === user.id;
  const providerID = get(activeRequest,'utility_provider.id');
  const provider = find(utilitySetup.utility_providers, provider => provider.id === providerID);

  const {
    account_number_required,
    confirmation_number_required,
    gridrewards_enabled,
    gridrewards_fullscreen,
    publicgrid_enabled,
    upload_required,
  } = get(utilitySetup,'settings') || {};
  const isLastScreen = !(gridrewards_enabled && gridrewards_fullscreen);


  const { utility_providers: providers } = utilitySetup;

  const openGridRewardsModal = (e) => {
    e.preventDefault();
    Modal.open(
      <GridRewardsModal />,
      { customStyles: { maxWidth:'1000px', padding: 0 }}
    );
  };

  const fields = [
    {
      label: `Enter your ${get(provider,'name')} account information below`,
      type: 'title',
    },
    account_number_required && {
      name: 'account_number',
      label: 'Account number',
      type: 'text',
      value: get(activeRequest,'account_number'),
      remoteValidation: { url: `/residents/utility-providers/${providerID}/verify-account-number`, msg: 'Invalid account number format' },
      required: 'You must provide an account number',
    },
    confirmation_number_required && {
      name: 'confirmation_number',
      label: 'Confirmation number',
      type: 'text',
      value: get(activeRequest,'confirmation_number'),
      required: 'You must provide a confirmation number',
    },
    upload_required && {
      label: 'Utilities proof image',
      type: 'uploader',
      name: 'file_url',
      s3UploadRequest: (fileData) => dispatch(getS3UploadUrl(building.id,{
        ...fileData,
        subdirectory: `utilities`,
      })),
      value: get(activeRequest,'file_url'),
      required: true,
      options: {
        accept: {
          'image/jpg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
        },
        useUniqueFilenames: true,
        multiple: false,
        maxFiles: 1,
      }
    },
    gridrewards_enabled && !gridrewards_fullscreen && {
      name: 'consent_to_share_data_with_program_provider',
      label: (
        <>
          Enroll in GridRewards, get notifications - earn money for using less energy during peak demand hours<br />
          <a href="https://admin.logicalbuildings.com/tos" target="_blank" rel="noopener noreferrer">See Terms</a> | <span className={CSS.gridrewards_link} onClick={openGridRewardsModal}>What is GridRewards?</span>
        </>
      ),
      type: 'checkbox',
      value: true,
      className: CSS.consent,
    },
    gridrewards_enabled && !gridrewards_fullscreen && {
      name: 'consent_to_share_data_with_property',
      label: (
        <>
          I authorize Logical Buildings to access my utility data and share such utility data with {building.name} for carbon emissions tracking and regulatory benchmarking purposes. <a href="https://logicalbuildings.com/" target="_blank" rel="noopener noreferrer">Learn about Logical Buildings</a>
        </>
      ),
      type: 'checkbox',
      value: true,
      className: CSS.consent,
    },
  ].filter(v=>v);

  const _handleNext = data => {
    if(pending) return false;
    // Create request if none exists or draft request belongs to roommate
    const createOrUpdateRequest = (!activeRequest || !activeRequestIsMyRequest) ?
      dispatch(taskDefinition.actions.createRequest(id, { ...data, utility_provider_id: providerID })) :
      dispatch(taskDefinition.actions.updateRequest(activeRequest.id, data));
    createOrUpdateRequest
      // submit the request if this is the last screen
      .then(resp => isLastScreen ?
        dispatch(taskDefinition.actions.submitRequest(get(resp,'requests[0].id'))) :
        resp
      )
      .then(() => nextScreen())
      .catch(error => Notify.error(format.error(error)));
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <div className={CSS.container}>

          { providers?.length > 1 && !publicgrid_enabled && (
            <Notebox
              icon={{ library:'task', symbol:'Proof-of-renters' }}
              heading='View Provider Information'
              color='green'
              body={(
                <span>
                  <span className='faux-link' onClick={() => previousScreen()}>Click here</span> to view the full list of your provider options.
                </span>
              )}
            />
          )}

          <ProviderDetails provider={provider} />

          <DynamicForm id={`${taskType}-form`}
            className={CSS.form}
            fields={fields}
            formStyle={'underline'}
            onSubmit={_handleNext}
            disabled={pending}
          />
        </div>
      </ScreenContent>
      <ScreenActions>
        <label
          htmlFor={`${taskType}-form-submit`}
          tabIndex="0"
          role="button"
          className={classNames('btn-primary', 'btn--full', {'loading': pending})}
          disabled={pending}
        >
          { isLastScreen ? 'Submit' : 'Next' }
        </label>
      </ScreenActions>
    </ScreenLayout>
  )
};
