import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';

import camera from './assets/camera.png';
import piggybank from './assets/piggybank.png';
import user from './assets/user.png';
import wallet from './assets/wallet.png';

import CSS from './styles/MarketplaceLeader.module.scss';

export const MarketplaceLeader = ({ move }) => {
  // don't show this element if move is not elegible (via partner setting feature flag)
  const moveSteps = get(move,'move_steps',[]);
  const isMarketplaceEnabled = moveSteps.find(step => get(step,'building.settings.marketplace_enabled'));
  return isMarketplaceEnabled ? (
    <div className={CSS.container}>
      <img src={camera} className={classNames(CSS.icon,CSS.camera)} alt="Camera" />
      <img src={piggybank} className={classNames(CSS.icon,CSS.piggybank)} alt="Piggybank" />
      <img src={user} className={classNames(CSS.icon,CSS.user)} alt="User" />
      <img src={wallet} className={classNames(CSS.icon,CSS.wallet)} alt="Wallet" />
      <div className={CSS.content}>
        <h2 className={CSS.title}>Exclusive Offers</h2>
        <p className={CSS.description}>Need something for your home? Discover offers we've curated specifically for residents.</p>
        <Link to={`/moves/${move.id}/marketplace`}>
          <button className='btn-ghost'>
            View Deals
          </button>
        </Link>
      </div>
    </div>
  ) : null;
};
