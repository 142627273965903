import * as create from './create';
import * as createRequest from './createRequest';
import * as get from './get';
import * as submitRequest from './submitRequest';
import * as updateRequest from './updateRequest';

export const reducers = [
  create.reducer,
  createRequest.reducer,
  get.reducer,
  submitRequest.reducer,
  updateRequest.reducer,
];

export const initialState = {
  coiSubmissions: {},
};
