import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_COMPLETE_ORIENTATION',
  request: (id, data) => request.patch(`/orientation-progress/${id}`, data).then(r => r.data.data.user_orientation_progress),
  reducers: {
    success: (state, { response: orientationContent }) => {
      return {
        ...state,
        orientation: {
          ...state.orientation,
          [orientationContent.id]: orientationContent,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCompletePending,
} = generate.redux(creator);
