import { getActiveRequest } from '../base/getActiveRequest';
import { getRequestFields } from './getRequestFields';

export const getSummaryFields = (taskable) => {
  const activeRequest = getActiveRequest(taskable);
  return [
    {
      label: 'Do you have any pets?',
      value: taskable?.has_pets ? 'Yes' : 'No',
      icon: { symbol: 'Paw', library: 'animals' },
    },
    ...getRequestFields(activeRequest)
  ];
};
