import React from 'react';
import Emoji from "react-emoji-render";

import CSS from './styles/LevelHeader.module.scss';

export const LevelHeader = ({icon, displayName, description}) => (
  <div className={CSS.box}>
    <div className={CSS.content}>
      <div className={CSS.description}>{ description }</div>
      <div className={CSS.title}>
        {icon && (<i><Emoji text={`:${icon}:`}/></i>)}
        <h1>{ displayName }</h1>
      </div>

    </div>
  </div>
);
