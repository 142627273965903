import { SurveyLanding } from './shared/components';
import { withBuilding } from '../common/';

import satisfactionRoutes from './satisfaction/routes';

const routes = [{
  path: '/:buildingSlug/surveys',
  name: 'Surveys',
  component: withBuilding(SurveyLanding),
  allowGuest: true,
  children: [
    ...satisfactionRoutes,
  ]
}];

export default routes;
