import { BuildingMoveTaskFlow } from '../../components/BuildingMoveTaskFlow';

// unrestricted (in terms of what are valid slugs) variant to this route for this
// task type since these are configurable via admin tools
export const routes = [
  {
    path: `/:screenSlug`,
    name: 'taskFlowScreen',
    component: BuildingMoveTaskFlow,
  },
];
