import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_UPDATE_TASK',
  request: (id, data) => request.patch(`/residents/personal-tasks/${id}`,data)
    .then(r => r?.data?.data?.personal_task),
  reducers: {
    success: (state, { response: personalTask }) => ({
      ...state,
      personalTasks: {
        ...state.personalTasks,
        [personalTask.id]: personalTask,
      },
    }),
  },
};

export const {
  reducer,
  action: updateTask,
  usePending: useUpdateTaskPending,
} = generate.redux(creator);

export const initialState = {
  personalTasks: {},
};
