import { base } from '../base';

import { getActiveRequest } from './getActiveRequest';
import { getOverrideFields } from './getOverrideFields';
import { getOverrides } from './getOverrides';
import { getRequestFields } from './getRequestFields';
import { getSummaryFields } from './getSummaryFields';

export const orientation = {
  ...base,
  getActiveRequest,
  getOverrideFields,
  getOverrides,
  getRequestFields,
  getSummaryFields,
};
