import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_MOVE_OUT_DETAILS',
  request: (id) => request.get(`/move-out-details-tasks/${id}`).then(r => get(r,'data.data.move_out_details_task')),
  selector: (state, id) => get(state,`moveOutDetails.${id}`),
  reducers: {
    success: (state, { response: details }) => {
      return {
        ...state,
        moveOutDetails: {
          ...state.moveOutDetails,
          [details.id]: details,
        },
      };
    }
  }
};

export const {
  action,
  reducer,
  useResource: useMoveOutDetails,
  usePending: useMoveOutDetailsPending,
} = generate.redux(creator);

export const initialState = {
  moveOutDetails: {},
};
