import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get, cloneDeep } from 'lodash';

import { useNotify, useUser, format } from '@moved/services';
import { DynamicForm, EmptyContent } from '@moved/ui';

import { ScreenTitle } from '../../shared';
import { ProviderName, ProviderDescription, ProviderLinks } from './ProviderDetails';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/SelectProvider.module.scss';

export const SelectProvider = ({ screen, nextScreen, taskDefinition }) => {
  const { user } = useUser();
  const { id, taskType, } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const utilitySetup = taskDefinition.selectors.useUtilitySetup(id);
  const pending = taskDefinition.selectors.useUtilitySetupPending();

  const { utility_providers: providers } = utilitySetup;

  const activeRequest = taskDefinition.helpers.getActiveRequest(utilitySetup);
  const providerID = get(activeRequest,'utility_provider.id');

  const activeRequestIsMyRequest = get(activeRequest,'submitted_by.user_id') === user.id;

  const fields = [
    {
      name: 'utility_provider_id',
      type: 'itemList',
      options: providers.map(provider => ({
        value: provider.id,
        label: <ProviderName provider={provider} />,
        sublabel: <ProviderDescription provider={provider} />,
        rightLabel: <ProviderLinks provider={provider} />,
      })),
      value: providerID ? providerID : (providers.length === 1 ? providers[0].id : null),
      required: `You must choose one of these ${utilitySetup.utility_category.toLowerCase()} providers`,
    },
  ];

  const _handleNext = data => {
    if(pending) return false;
    // Create request if none exists or draft request belongs to roommate
    const createOrUpdateRequest = (!activeRequest || !activeRequestIsMyRequest) ?
      dispatch(taskDefinition.actions.createRequest(id,data)) :
      dispatch(taskDefinition.actions.updateRequest(activeRequest.id, data));
    createOrUpdateRequest
      .then(() => nextScreen())
      .catch(error => Notify.error(format.error(error)));
  };

  let localScreen = cloneDeep(screen);

  localScreen.title = `Set up your ${get(utilitySetup,'utility_category').toLowerCase()} provider`

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={localScreen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={localScreen.maxWidth && {maxWidth: localScreen.maxWidth}}>

            <div className={CSS.container}>

              { providers.length > 0 ? (
                <DynamicForm id={`${taskType}-form`}
                  className={CSS.form}
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleNext}
                  disabled={pending ? true : false}
                />
              ) : (
                <EmptyContent message={(
                  <>
                    <b>No providers have been configured for this property.</b><br />
                    Use the contact form in the bottom right corner to let us know.
                  </>
                )}/>
              )}
            </div>

          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor={`${taskType}-form-submit`}
              tabIndex="0"
              role="button"
              className={classNames('btn-primary', 'btn--full', {'loading': pending})}
              disabled={pending ? true : false}
            >
              Submit
            </label>
          </div>
        </div>
      </div>
    </div>
  )
};
