import { useParams } from 'react-router-dom';
import { merge } from 'lodash';

import { useUser } from '@moved/services';
import { tagContextBuilder } from '@moved/product';

import { useActiveMoveStep } from '../../dashboard';
import { useMove } from '../../moves/actions/selectors';
import { useBuilding } from '../actions/selectors';

export const useResidentTags = (tags={}) => {
  const { moveId } = useParams();
  const { user } = useUser();
  const move = useMove(moveId);
  const moveStep = useActiveMoveStep();
  const building = useBuilding(moveStep?.building?.slug);
  return tagContextBuilder({
    move,
    moveStep,
    building,
    lease: moveStep?.lease,
    tenant: merge({}, moveStep?.tenant, user),
  });
};
