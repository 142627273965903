import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { get } from 'lodash';

import { DynamicForm } from '@moved/ui';
import { useNotify, format } from '@moved/services';

import { ResidentTagRenderer } from '../../../../common';
import { ScreenTitle } from '../../shared';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/LiabilityOnly.module.scss';

export const PurchaseOptions = ({ screen, nextScreen, taskDefinition }) => {
  const { id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const taskDetails = taskDefinition.selectors.useRenterInsurance(id);
  const [pending, setPending] = useState();

  const activeRequest = taskDefinition.helpers.getActiveRequest(taskDetails);

  const purchaseOptions = get(taskDetails,'purchase_options',[]);

  const fields = [
    {
      type: 'itemList',
      name: 'selected_purchase_option_id',
      required: 'You must make an insurance selection',
      value: get(taskDetails,'selected_purchase_option.id'),
      options: purchaseOptions.map((option,index) => ({
        label: option.display_name,
        sublabel: get(option,'content.subtitle'),
        content: get(option,'content.description_html') && (
          <ResidentTagRenderer content={format.semiSafeHTML(get(option,'content.description_html'))}/>
        ),
        value: option.id,
        icon: { symbol: get(option,'content.icon') },
        large: true,
      })),
    },
  ];

  // Task and update handler to be run on submit or after request creation
  const handleTaskRequestUpdate = (updatedTaskDetails, selectedPurchaseOption) => {
    const {
      defaultProvider,
      shouldSubmitRequest,
      shouldRedirect,
    } = taskDefinition.getPurchaseOptionFlowImplications(selectedPurchaseOption);
    const currentRequest = activeRequest || taskDefinition.helpers.getActiveRequest(updatedTaskDetails);

    // -- Update task and then update request --//
    return dispatch(taskDefinition.actions.update(id, { selected_purchase_option_id: selectedPurchaseOption.id}))
      .then(updatedTask => {
        const providerName = get(defaultProvider,'name') || get(selectedPurchaseOption,'display_name');
        // If default provider (or immediately submitting) set the policy provider to the defaultProvider name, or return previous response
        return (defaultProvider || shouldSubmitRequest) ?
          dispatch(taskDefinition.actions.updateRequest(currentRequest.id, { insurance_provider: providerName })) :
          updatedTask;
      })
      .then(updatedTask => {
        // if selected purchase option should redirect, get the link and open in a new tab, or return previous response
        return shouldRedirect ?
          dispatch(taskDefinition.actions.getProviderLink(id, get(defaultProvider, 'id')))
            .then(link => {
              window.open(decodeURIComponent(link));
              return updatedTask;
            }) :
          updatedTask;
      })
      .then(updatedTask => {
        // if selected purchase option should submit do submit, or return previous response
        return shouldSubmitRequest ?
          dispatch(taskDefinition.actions.submitRequest(currentRequest.id)) :
          updatedTask;
      })
      .then(nextScreen)
      .catch(err => {
        setPending(false);
        Notify.error(format.error(err));
      });
  };

  //Submit form function
  const _handleSubmit = ({ selected_purchase_option_id }) => {
    if(pending) return;
    setPending(true);
    const selectedPurchaseOption = purchaseOptions.find(option => option.id === parseInt(selected_purchase_option_id));

    if(!activeRequest) {
      dispatch(taskDefinition.actions.createRequest(id))
        .then(updatedTask => handleTaskRequestUpdate(updatedTask, selectedPurchaseOption));
    } else {
      handleTaskRequestUpdate(taskDetails, selectedPurchaseOption);
    }
  };

  return (
    <div className={taskCSS.holder}>

      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>
            <div className={CSS.content}>

              <div className={CSS.form}>
                <DynamicForm id='liability-only-form'
                  fields={fields}
                  formStyle={'underline'}
                  onSubmit={_handleSubmit}
                  disabled={pending}
                />
              </div>

            </div>
          </div>
        </div>

        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <label
              htmlFor="liability-only-form-submit"
              tabIndex="0"
              role="button"
              className={'btn-primary btn--full' + (pending  ? ' loading' : '')}
              disabled={pending}
            >
              Next
            </label>
          </div>
        </div>

      </div>

    </div>
  );
}
