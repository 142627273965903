import React, { useEffect } from 'react';
import { useParams, useLocation, matchPath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';

import { format, useNotify } from '@moved/services';
import { AtomSpinner } from '@moved/ui';

import { useBuilding } from '../../../common/actions/selectors';
import { SurveyProgress } from '../../shared/components';
import { getSatisfactionRequest } from '../actions';
import { useSatisfactionRequest, useSatisfactionRequestPending } from '../actions/selectors';
import CSS from './styles/SatisfactionSurvey.module.scss';

export const SatisfactionSurvey = ({ children }) => {
  const { buildingSlug, token, level } = useParams();
  const Notify = useNotify();
  const { pathname } = useLocation();
  const building = useBuilding(buildingSlug);
  // redux
  const dispatch = useDispatch();
  const surveyRequest = useSatisfactionRequest(token);
  const pending = useSatisfactionRequestPending();

  // Gets the current step from the URL params
  const match = matchPath(pathname, { path: "*/satisfaction/:token/:level/:activeScreen" });
  const activeStep = get(match,'params.activeScreen') || level;

  useEffect(() => {
    dispatch(getSatisfactionRequest(token))
    .catch(error => Notify.error(format.error(error)))
  },[token]); // eslint-disable-line

  let screens = [];

  switch(level) {
    case 'satisfied':
      screens = [
        'satisfied',
        get(surveyRequest,'settings.review_site_links',[]).length > 0 && 'feedback',
        'confirmation',
      ].filter(v => v);
    break;
    case 'neutral':
      screens = [
        'neutral',
        'confirmation',
      ];
    break;
    case 'not-satisfied':
      screens = [
        'not-satisfied',
        'confirmation',
      ];
    break;
    default:
  }

  if(pending) return <AtomSpinner className={CSS.spinner} />;
  return (
    <>
      <Helmet>
        <title>{`${get(building, 'settings.display_name','')} Satisfaction Survey : Moved`}</title>
      </Helmet>
      <SurveyProgress steps={screens} current={activeStep} />
      {children}
    </>
  );
};
