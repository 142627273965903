import React from 'react';

import { CreditCardLogo } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';
import { LastMinuteNotice } from './LastMinuteNotice';
import { BookBreakdown } from './BookBreakdown';
import { SummaryFields } from './SummaryFields';

import CSS from './styles/MoveSummary.module.scss';

export const MoveSummary = ({ screen, nextScreen, taskDetails, taskDefinition, moveId }) => {
  const moverBooking = taskDefinition.getMoverBooking(taskDetails);
  const { discounts = [] } = taskDefinition.selectors.useMove(moveId);

  const _handleSubmit = (e) => {
    e.preventDefault();
    return nextScreen();
  };

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <div className={CSS.content}>

          <div className={CSS.summary}>

            <LastMinuteNotice moverBooking={moverBooking} />

            <div className={CSS.info_content}>
              <SummaryFields moverBooking={moverBooking} />
            </div>
          </div>

          <BookBreakdown
            moverBooking={moverBooking}
            discounts={discounts}
          />

        </div> {/* CSS.content */}
      </ScreenContent>
      <ScreenActions>
        <div className='stackVertical gap-20 items-center'>
          <div className='stackHorizontal gap-8'>
            <CreditCardLogo symbol={'visa'} />
            <CreditCardLogo symbol={'mastercard'} />
            <CreditCardLogo symbol={'amex'} />
            <CreditCardLogo symbol={'apple-pay'} />
            <CreditCardLogo symbol={'discover'} />
          </div>
          <button
            id='quote_form_button'
            className={'btn-primary btn--full'}
            onClick={_handleSubmit}
            type='submit'
          >
            Next
          </button>
        </div>
      </ScreenActions>
    </ScreenLayout>
  );
}
