/*
*  Accordion
*
*   Props:
*     panels (Array): each panel in the array should follow this object structure:
*       {
*         label: text, jsx, or component to render on the title bar for this item,
*         content: text, jsx, component, or function accepting (isOpen, togglePanels(index1,index2,...))
*           that returns a component to be the content of the collapsible region for this panel,
*         class: (optional) string class to add to this panel wrapper,
*         initial: (optional) boolean whether this panel starts open or closed (default false/closed),
*       }
*
*     options (object): override defaults for accordion behavior
*       allowMultiple (boolean): default behavior limits to one open panel at a time, this flag allows any panel
*         to be open or closed independently.
*
*     className (string): adds additional class to accordion wrapper element
*
*     ...props: allows passing through arbitrary props for extensibility
*/
import React, { useState, useCallback } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { merge } from 'lodash';

import { Collapsible } from '../Collapsible';
import { Icon } from '../../../sondheim/components/Icon';

import CSS from './Accordion.module.scss';

const defaultOptions = {
  allowMultiple: false,
};

export const Accordion = ({ panels=[], className, options, ...props}) => {
  // state
  const [openStates, setOpenStates] = useState(panels.map(panel => panel.initial || false)); // create array of true/false isOpen values for each panel
  const opts = merge(defaultOptions, options);

  // internal functions
  const toggleOpen = useCallback((...index) => {
    setOpenStates(openStates.map((isOpen,i) => {
      if(index.includes(i)) return !isOpen;
      else return opts.allowMultiple ? isOpen : false;
    }));
  },[openStates, opts]);

  return (
    <div className={classNames(CSS.accordion, className)} {...props}>
      { panels.map((panel,index) => (
        <div key={index} className={classNames(CSS.panel, panel.class)}>
          <div className={classNames(CSS.panel_header, panel.headerClass, {[CSS.open]:openStates[index]})} onClick={() => toggleOpen(index)}>
            <span className={CSS.panel_label}>{ panel.label }</span>
            <Icon size='20px' library='navigation' symbol='Chevron-right' className={CSS.panel_arrow} />
          </div>
          <Collapsible open={openStates[index]}>
            {(isOpen) => typeof panel.content === "function" ? panel.content(isOpen, toggleOpen) : panel.content }
          </Collapsible>
        </div>
      ))}
    </div>
  );
}

Accordion.propTypes = {
  panels: PropTypes.array,
  options: PropTypes.object,
  className: PropTypes.string,
};
