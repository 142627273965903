import React from 'react';
import { AtomSpinner } from '@moved/ui';

import { ScreenLayout } from './ScreenLayout';

export const ScreenLoading = () => {
  return (
    <ScreenLayout>
      <AtomSpinner />
    </ScreenLayout>
  );
}
