import React from 'react';
import { get } from 'lodash';

import { format, useMobile } from '@moved/services';
import { Icon, PopOver } from '@moved/ui';

import CSS from './styles/ViewQuotes.module.scss';

// Calculate new minimum after removing all discounts
const _calcReduction = (min, discounts) => {
  const reducer = (accumulator, discount) => {
    switch(discount.type) {
      case 'cents':
        return accumulator - discount.cents;
      case 'percent':
        return accumulator - (min * discount.percent);
      default:
        return accumulator;
    };
  };
  return discounts.reduce(reducer,min);
};

export const RateDetails = ({ rate, discounts, task, isCancelable }) => {
  const activeMoveStep = task.selectors.useActiveMoveStep();
  const building = task.selectors.useBuilding(get(activeMoveStep,'building.slug'));
  const isMobile = useMobile();
  return (
    <div className={CSS.things_content}>

      <h3>Things to keep in mind</h3>

      { get(building, 'copy.mover_booking_rates_instructions') && (
        <p className={'mt-5'}>
          { get(building, 'copy.mover_booking_rates_instructions') }
        </p>
      )}

      <div className={CSS.things}>
        <dl className={CSS.row}>
          <dt className={CSS.label}><Icon symbol={'Info'} size={'20px'} className={'mr-15'} />Minimum cost</dt>
          <dd className={CSS.value}>
            { discounts.length > 0 ? (
              <>
                { format.currency(_calcReduction(rate.minimum,discounts)) }
                &nbsp;<span className={CSS.pre_coupon_rate}>{format.currency(rate.minimum)}</span>
              </>
            ) : (
              <>
                { format.currency(rate.minimum) }
              </>
            )}
            <PopOver
              placement="top-end"
              className="ml-5"
              stopPropagation={isMobile}
              trigger={isMobile ? 'click' : 'hover'}
              tooltip={(
                <p className={CSS.tooltip}>
                  The minimum cost is based on labor and arrival fee minimums
                  and/or other fixed costs determined by the moving company.
                </p>
              )}
            >
              <Icon symbol={'Info-circle'} library={'code'} size={'20px'} />
            </PopOver>
          </dd>
        </dl>
        <dl className={CSS.row}>
          <dt className={CSS.label}><Icon symbol={'Route'} library={'navigation'} size={'20px'} className={'mr-15'} />Arrival fee</dt>
          <dd className={CSS.value}>
            { format.currency(rate.hourly_rate) }
            <PopOver
              placement="top-end"
              className="ml-5"
              stopPropagation={isMobile}
              trigger={isMobile ? 'click' : 'hover'}
              tooltip={(
                <p className={CSS.tooltip}>
                  The <strong>arrival fee</strong> is a component of the minimum cost of the move and represents the time it takes for movers to travel to your pickup location at the beginning of the move, and from your destination location at the end of the move.<br/><br/>
                  The final cost will be based on the combination of <strong>arrival fee</strong> and <strong>labor time</strong>.<br/><br/>
                  <strong>Labor time</strong> starts once the movers arrive at the pickup address and ends once they are done unloading at the drop-off address, rounded up to the nearest half hour. Please note that the travel between the pickup and the destination is part of labor time. Any tolls or parking fees required to complete the move will be included as additional charges in the final price. Any delays cause by elevators, changes to the list of items to be moved, building restrictions, traffic, or anything else will be included in the total time of the move.
                </p>
              )}
            >
              <Icon symbol={'Info-circle'} library={'code'} size={'20px'} />
            </PopOver>
          </dd>
        </dl>


        { building && (
          <dl className={CSS.row}>
            <dt className={CSS.label}><Icon symbol={'File-done'} size={'20px'} className={'mr-15'} />Certificate of insurance</dt>
            <dd className={CSS.value}>Free of charge</dd>
          </dl>
        )}
        <dl className={CSS.row}>
          <dt className={CSS.label}><Icon symbol={'Lock-circle'} size={'20px'} className={'mr-15'} />Insurance</dt>
          <dd className={CSS.value}>$0.30 / pound</dd>
        </dl>
        { isCancelable && (
          <dl className={CSS.row}>
            <dt className={CSS.label}><Icon symbol={'Time-schedule'} size={'20px'} className={'mr-15'} />Cancellation policy</dt>
            <dd className={CSS.value}>FREE - 48 hours before move date</dd>
          </dl>
        )}
      </div>

    </div>
  );
};
