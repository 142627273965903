import React from 'react';
import { get, isEmpty } from 'lodash';

import { FieldList, StatusPill, Card } from '@moved/ui';
import { format } from '@moved/services';

import { Snippet } from '../../../../snippets';
import { useActiveBuildingMoveTask } from '../../../contexts/BuildingMoveTaskContext';

export const PastRequest = ({ request }) => {
  const { activeTaskDetails, activeTaskDefinition } = useActiveBuildingMoveTask();
  const taskType = activeTaskDefinition.slug;
  const attributes = activeTaskDefinition.helpers.getRequestFields(request);
  const statusCopy = activeTaskDefinition.getStatusCopy(request,activeTaskDetails);
  if(!attributes || isEmpty(get(statusCopy,'history'))) return null;
  return (
    <Card className='stackVertical-12 marginBottom-20'>
      <div className='stackHorizontal-12 justify-between items-start'>
        <div>
          <div className='labelL contentPrimary'>
            <Snippet tag={`tasks.${taskType}.summary.${request.status}.history-title`}>
              {get(statusCopy,'history.title')}
            </Snippet>
          </div>
          <div className='labelS contentSecondary'>
            {`Originally submitted on: ${format.date(request.submitted_at || request.created_at,'dateTime')}`}
          </div>
        </div>
        <StatusPill status={request.status} />
      </div>
      <FieldList list={attributes} emptyContent={false} />
    </Card>
  );
};
