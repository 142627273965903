import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { format, useNotify, useScroller } from '@moved/services';
import { Button, LoaderOverlay, ClipboardWrapper } from '@moved/ui';

import { Snippet } from '../../../snippets';
import { useBuilding } from '../../../common/actions/selectors';

import { submitSatisfactionResponse, trackReviewSiteClick } from '../actions';
import {
  useSatisfactionRequest,
  useSatisfactionResponse,
  useCreateSatisfactionResponsePending,
  useUpdateSatisfactionResponsePending,
  useSubmitSatisfactionResponsePending,
} from '../actions/selectors';
import CSS from './styles/SatisfactionFeedback.module.scss';

export const SatisfactionFeedback = () => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const Scroller = useScroller();
  const { buildingSlug, token, level } = useParams();
  const history = useHistory();
  const building = useBuilding(buildingSlug);
  const surveyResponse = useSatisfactionResponse(token);
  const surveyRequest = useSatisfactionRequest(token);

  const createPending = useCreateSatisfactionResponsePending();
  const updatePending = useUpdateSatisfactionResponsePending();
  const submitPending = useSubmitSatisfactionResponsePending();
  const pending = createPending || updatePending || submitPending;

  useEffect(() => {
    const responseLevel = get(surveyResponse,'satisfaction_level.label');
    if(surveyResponse.submitted_at) {
      return history.replace({
        pathname: `/${buildingSlug}/surveys/satisfaction/${token}/${responseLevel}/confirmation`,
      });
    } else if(responseLevel !== 'satisfied') {
      return history.replace({
        pathname: `/${buildingSlug}/surveys/satisfaction/${token}/${responseLevel || level}`,
      });
    } else if(level !== 'satisfied') {
      return history.replace({
        pathname: `/${buildingSlug}/surveys/satisfaction/${token}/satisfied/feedback`,
      });
    }
  },[surveyResponse]); // eslint-disable-line

  const handleRedirect = async (reviewSite) => {
    await dispatch(trackReviewSiteClick(token,reviewSite.site_type));
    window.open(decodeURIComponent(reviewSite.url),'_blank','noopener');
  };

  const handleSubmit = (postIntent) => {
    if(pending) return;
    dispatch(submitSatisfactionResponse(token, { satisfaction_level: get(surveyResponse,'satisfaction_level.label'), intends_to_post_public_review: postIntent }))
      .then(() => Scroller.ref.current.scrollTo({ top: 0, behavior: 'smooth', }))
      .catch(error => Notify.error(format.error(error)))
  };

  return (
    <>
      {pending && (<LoaderOverlay />)}
      <h1 className={'headingL marginBottom-4'}>
        <Snippet tag={'surveys.satisfaction.feedback.title'}>
          Glad you're loving it! Take 1 minute to post a review?
        </Snippet>
      </h1>
      <h3 className={'labelM contentSecondary marginBottom-20'}>
        <Snippet tag={'surveys.satisfaction.feedback.subTitle'}>
          We've made leaving a public review fast and easy–help us attract future great neighbors like you!
        </Snippet>
      </h3>

      <div className={CSS.response_box}>
        <p className={CSS.response}>
          {surveyResponse.response}
        </p>
        <div className={CSS.response_footer}>
          <ClipboardWrapper text={surveyResponse.response}>
            <Button
              color='tertiary'
              size='small'
              text='Copy to clipboard'
              icon={{ symbol: 'Duplicate', library: 'general', }}
              iconPosition='left'
            />
          </ClipboardWrapper>
        </div>
      </div>

      {get(surveyRequest,'settings.review_site_links',[]).length > 0 && (
        <div className={CSS.blurb}>
          <h2 className={'headingS marginBottom-12'}>
            <Snippet tag={'surveys.satisfaction.feedback.review'}>
              Leave a review of {building.name}–it's quick!
            </Snippet>
          </h2>
          <div className={CSS.review_links}>
            {get(surveyRequest,'settings.review_site_links',[]).map(reviewSite => (
              <div
                key={reviewSite.url}
                className={CSS.link}
                onClick={() => handleRedirect(reviewSite)}
              >
                <img src={reviewSite.logo_url} alt={reviewSite.site_type} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={CSS.actions}>
        <Button color='secondary' size='large' text='Skip this step' onClick={() => handleSubmit(false)} />
        <Button color='primary' size='large' text='Submit' className='btn--full' onClick={() => handleSubmit(true)} />
      </div>
    </>
  );
};
