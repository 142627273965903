import { merge } from 'lodash';

import * as cancelVehicleDetails from  './cancelVehicleDetails';
import * as create from './create';
import * as createVehicle from './createVehicle';
import * as createRequest from './createRequest';
import * as get from './get';
import * as getVehicleTypes from './getVehicleTypes';
import * as reset from './reset';
import * as submitRequest from './submitRequest';
import * as update from './update';
import * as updateVehicle from './updateVehicle';

export const reducers = [
  cancelVehicleDetails.reducer,
  create.reducer,
  createVehicle.reducer,
  createRequest.reducer,
  get.reducer,
  getVehicleTypes.reducer,
  reset.reducer,
  submitRequest.reducer,
  update.reducer,
  updateVehicle.reducer,
];

export const initialState = merge(
  cancelVehicleDetails.initialState,
  create.initialState,
  createVehicle.initialState,
  createRequest.initialState,
  get.initialState,
  getVehicleTypes.initialState,
  reset.initialState,
  submitRequest.initialState,
  update.initialState,
  updateVehicle.initialState,
);
