import React from 'react';
import classNames from 'classnames';

import { Icon } from '@moved/ui';

import CSS from './MoveStepTypeIcon.module.scss';

const typeToIconMap = {
  'move-in': {
    symbol: 'Sign-in',
    class: CSS.in,
  },
  'move-out': {
    symbol: 'Sign-out',
    class: CSS.out,
  }
};

export const MoveStepTypeIcon = ({ type, className, size }) => {
  const iconData = typeToIconMap[type];
  return iconData ? (
    <Icon
      symbol={iconData.symbol}
      library={'navigation'}
      className={classNames(CSS.direction_icon,iconData.class,className)}
      size={size}
    />
  ) : null;
};
