import { get } from 'lodash';

import { generate, request } from '@moved/services';

const creator = {
  namespace: 'MOVES_GET_MOVE_SUMMARIES',
  request: () => request.get(`/residents/move-summaries`).then(r => get(r,'data.data.move_summaries')),
  selector: (state) => get(state,'moveSummaries'),
  reducers: {
    success: (state, { response }) => ({
      ...state,
      moveSummaries: response,
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useMoveSummaries,
  usePending: useMoveSummariesPending,
} = generate.redux(creator);

export const initialState = {
  moveSummaries: [],
};
