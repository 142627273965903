import { merge } from 'lodash';

import { vehicleRegistration as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';
import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';
import { routes } from './routes';

export class VehicleRegistration extends BaseTask {

  constructor() {
    super({
      routes: routes,
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useVehicleRegistration,
        ...selectors,
      }
    });
  }

  static label = 'Vehicle Registration';
  static slug = 'vehicle-registration';
  static icon = 'Car';
  static helpers = helpers;

  _flowBuilder(taskable) {
    const request = taskable?.requests?.[0];
    const vehicles = request?.vehicles ?? [];

    switch(vehicles.length > 0) {
      case true:
        return [
          ...vehicles.map(vehicle => ({
            ...this.screens['vehicle-details'],
            context: vehicle.id,
            label: vehicle.make ? `${vehicle.make} ${vehicle.model}` : "New vehicle",
          })),
          this.screens['summary'],
          this.screens['confirmation'],
        ];
      default:
        return [
          this.screens['vehicle-details'],
          taskable?.has_vehicles == null && this.screens['mystery'],
          this.screens['confirmation'],
        ].filter(Boolean);
    };
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    const requiresApproval = taskDetails?.settings?.requires_approval;
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'Review the requested changes below and submit updated vehicle details.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted vehicle details. You will be notified when your submission is reviewed.',
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: requiresApproval ?
              'The vehicle details you submitted have been reviewed and approved.' :
              'Your vehicle registration details have been submitted.'
          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* START OVERRIDES */
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  getActiveVehicle(taskable, id) {
    const request = taskable?.requests?.[0];
    if(id) return request.vehicles.find(vehicle => vehicle.id === parseInt(id));
    return false;
  }
  /* END TASK SPECIFIC */

}
