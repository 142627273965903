import { ErrorContent, } from '../shared/components';
import {
  Confirmation,
  SatisfactionSurvey,
  SatisfactionForm,
  SatisfactionFeedback,
} from './components';

const routes = [{
  path: '/satisfaction/:token/:level(satisfied|neutral|not-satisfied)',
  name: 'SatisfactionSurvey',
  component: SatisfactionSurvey,
  allowGuest: true,
  children: [
    {
      path: '/',
      component: SatisfactionForm,
      allowGuest: true,
      exact: true,
    },
    {
      path: '/:activeScreen(feedback)',
      component: SatisfactionFeedback,
      allowGuest: true,
      exact: true,
    },
    {
      path: '/:activeScreen(confirmation)',
      component: Confirmation,
      allowGuest: true,
      exact: true,
    },
    {
      path: '*',
      name: 'Page not found',
      component: ErrorContent,
      allowGuest: true,
    },
  ],
},
{
  path: '*',
  name: 'Page not found',
  component: ErrorContent,
  allowGuest: true,
}];

export default routes;
