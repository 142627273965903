import { get } from 'lodash';
import { format } from '@moved/services';

// Get the primary policy holder
export const getPrimaryPolicyHolder = request => {
  return get(request,'tenants',[]).find(tenant => get(tenant,'is_primary_policyholder'));
};

// Get all covered tenants
const getCoveredTenants = request => {
  return get(request,'tenants',[]).filter(tenant => get(tenant,'is_covered'));
};

export const getRequestFields = request => {
  if(!request) return [];
  const primaryTenant = getPrimaryPolicyHolder(request);
  const coveredTenants = getCoveredTenants(request);
  return [
    {
      label: 'Provider',
      value: get(request,'insurance_provider'),
      icon: { symbol: 'Home', library: 'home' },
    },
    get(request,'liability_coverage_amount') && {
      label: 'Liability Coverage',
      value: format.currency(get(request,'liability_coverage_amount')),
      icon: { symbol: 'Shield-check', library: 'general' },
    },
    get(request,'policy_number') && {
      label: 'Policy number',
      value: get(request,'policy_number'),
      icon: { symbol: 'File', library: 'files' },
    },
    primaryTenant && {
      label: 'Primary policy holder',
      value: `${primaryTenant.firstname} ${primaryTenant.lastname}`,
      icon: { symbol: 'User', library: 'general' },
    },
    coveredTenants.length > 0 && {
      label: 'Covered residents',
      value: coveredTenants
        .map(tenant => `${tenant.firstname} ${tenant.lastname}`)
        .join(', '),
      icon: { symbol: 'Group', library: 'communication' },
    },
    get(request,'coverage_start') && {
      label: 'Start date',
      value: format.date(get(request,'coverage_start'),'date'),
      icon: { symbol: 'Date', library: 'code' },
    },
    get(request,'coverage_end') && {
      label: 'End date',
      value: format.date(get(request,'coverage_end'),'date'),
      icon: { symbol: 'Date-to', library: 'code' },
    },
    get(request,'file_urls.length') > 0 && {
      label: 'Submitted documents',
      value: format.fileList(get(request,'file_urls',[])),
      icon: { symbol: 'Uploaded-file', library: 'files' },
    },
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ].filter(v=>v);
};
