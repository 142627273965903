import { DashboardRedirect } from './components/DashboardRedirect';
import { Dashboard } from './components/Dashboard';
import { MoveStepContextProvider } from './contexts';
import { withContactForm } from '../contact';
import { four04Route } from '../common/routes';
import tasksRoutes from '../tasks/routes';

const routes = [
  {
    path: '/',
    name: 'DashboardRedirect',
    component: DashboardRedirect,
    exact: true,
  },
  {
    path: '/steps/:stepId(\\d+)',
    name: 'MoveStep',
    component: MoveStepContextProvider,
    transition: 'crossFade',
    transitions: {
      in: 'crossFade',
      out: 'crossFade',
    },
    viewKey: ({moveId}) => `dashboard-${moveId}`,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: withContactForm(Dashboard),
        exact: true,
        viewKey: ({moveId}) => `dashboard-${moveId}`,
        transition: 'scaleOut',
        transitions: {
          in: 'scaleOut',
          out: 'scale',
        },
      },
      ...tasksRoutes,
      four04Route,
    ]
  },
  four04Route,
];

export default routes;
