import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';

import { useNotify, format } from '@moved/services';
import { AtomSpinner, Icon, Notebox, Button } from '@moved/ui';

import { DayPicker, TimePicker, DayPickerItem, TimePickerItem, ScreenTitle } from '../../shared';
import { KeyPickupLocation } from './KeyPickupLocation';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';
import CSS from './styles/KeySelectDates.module.scss';

// helper component for daypicker tooltips
const DayTooltips = ({ day, keyPickupTask, calendar }) => {
  const isEarliestDate = moment(get(keyPickupTask, 'earliest_key_pickup_datetime')).isSame(day.date,'day');
  const isLeaseDate = !get(keyPickupTask, 'earliest_key_pickup_datetime') && get(keyPickupTask, 'lease.start_date') === day.date;
  const isCurrentAppointmentDate = get(keyPickupTask, 'appointment.pickup_date') === day.date
  return (
    <div className={CSS.tooltips_wrapper}>
      { isEarliestDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{get(calendar,'content.earliest_date_tooltip') || 'Your unit is available!'}</span>
        </div>
      )}
      { isLeaseDate && (
        <div className={classNames(CSS.tooltip,{[CSS.tooltip_overlap]: isCurrentAppointmentDate})}>
          <Icon symbol={'Info'} size={'20px'} className={CSS.tooltip_info} />
          <span className={CSS.tooltip_text}>{get(calendar,'content.lease_start_tooltip') || 'Your lease start date!'}</span>
        </div>
      )}
      { isCurrentAppointmentDate && (
        <div className={classNames(CSS.tooltip_green)}>
          <span className={CSS.tooltip_green_text}>{get(calendar,'content.current_reservation_tooltip') || 'Current reservation'}</span>
        </div>
      )}
    </div>
  );
};

export const KeySelectDates = ({ screen, nextScreen, taskDetails, taskDefinition, taskSummary }) => {
  const { taskType, id } = useParams();
  const Notify = useNotify();
  const dispatch = useDispatch();

  const keyPickupTask = taskDetails;

  const submitRequestPending = taskDefinition.selectors.useSubmitRequestPending();
  const createRequestPending = taskDefinition.selectors.useCreateRequestPending();
  const pendingSubmit = submitRequestPending || createRequestPending;

  const calendars = taskDefinition.selectors.useKeyCalendar();
  const calPending = taskDefinition.selectors.useKeyCalendarPending();

  const [keyPickupDate, setKeyPickupDate] = useState({});
  const [keyPickupTime, setKeyPickupTime] = useState('');

  const activeRequest = taskDefinition.helpers.getActiveRequest(keyPickupTask);
  const existingAppointments = taskDefinition.helpers.getArtifacts(taskSummary);
  const activeAppointment = existingAppointments.length > 0 && existingAppointments[0];

  const calendar = calendars && calendars.length ? calendars.find(calendar => calendar.id === (get(activeRequest,'building_calendar.id') || calendars[0].id)) : {};
  const availabilityCalendar = get(calendar, 'availability', []);
  const earliestKeyAvailability = get(keyPickupTask,'earliest_key_pickup_datetime')
    ? moment(get(keyPickupTask,'earliest_key_pickup_datetime'))
    : moment();

  // Load the initial calendar data
  useEffect(() => {
    dispatch(taskDefinition.actions.getCalendarOptions(id))
      .catch(error => Notify.error('Error loading calendar data'));

    if(!activeRequest) {
      dispatch(taskDefinition.actions.createRequest(id))
        .then((resp) => dispatch(taskDefinition.actions.updateRequest(resp.requests[0].id, {
          'tenant_key_ids': [ keyPickupTask.current_tenant_key.id],
          'building_calendar_id': calendar.id
        })))
        .catch(error => Notify.error('Error creating calendar request'));
    }
  },[]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(calendar)) setKeyPickupDate(getInitialSelectedDate());
  },[calendar]); // eslint-disable-line

  // Day picker helpers
  const daysList = availabilityCalendar
    // filter out all days before availability (but keep one day before for design layout)
    .filter(day => !earliestKeyAvailability.clone().subtract(1,'d').isAfter(day.date,'day'))
    .map(day => ({
      id: day.date,
      isSelected: keyPickupDate.date === day.date,
      isDisabled: earliestKeyAvailability.isAfter(day.date,'day'),
      availability: day,
      content: (<>
        <DayTooltips day={day} keyPickupTask={keyPickupTask} calendar={calendar} />
        <DayPickerItem day={day} />
      </>),
    }));

  const updateKeyPickupDate = date => {
    if(date.isDisabled) return;
    setKeyPickupDate(date.availability);
    if(!date.availability.timeslots.includes(keyPickupTime)) setKeyPickupTime('');
  }

  // Time picker helpers
  const isSlotDisabled = slot => {
    const slotStart = `${keyPickupDate.date}T${slot.start}`;
    return !slot.is_available || earliestKeyAvailability.isAfter(slotStart);
  };
  const timesList = get(keyPickupDate, 'timeslots', []).map((time) => ({
    id: time.start,
    isSelected: time === keyPickupTime,
    isDisabled: isSlotDisabled(time),
    slot: time,
    content: (
      <TimePickerItem
        time={format.date(`${keyPickupDate.date}T${time.start}`,'h:mmA')}
        reserved={!time.is_available} />
    ),
  }));

  const getInitialSelectedDate = () => {
    if(!availabilityCalendar.length) return {};
    let selection;
    // if taskable already has a date selected, reselect it
    if (keyPickupTask.date) selection = availabilityCalendar.find(day => day.date === keyPickupTask.date);
    // if no previous selection attempt to use earliest pickup date
    if (!selection && get(keyPickupTask, 'earliest_key_pickup_datetime')) {
      selection = availabilityCalendar.find(day => moment(get(keyPickupTask, 'earliest_key_pickup_datetime')).isSame(day.date,'day'));
    }
    // fallback options is to select the first available date
    if(!selection) selection = availabilityCalendar.find(day => day.is_available !== false);
    return selection;
  };

  // Check if form submission is disabled
  const isDisabled = () => {
    if (calPending || pendingSubmit) return true;
    else if (calendar && calendar.booking_type === 'all-day') return isEmpty(keyPickupDate);
    else if (isEmpty(keyPickupDate) || keyPickupTime === '') return true;
  }

  const updateKeyPickupTime = slot => {
    if(!isSlotDisabled(slot)) setKeyPickupTime(slot);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if(pendingSubmit) return null;

    const data = {
      pickup_date: keyPickupDate.date,
    }

    if(keyPickupTime !== '') {
      data.pickup_time = format.date(`${keyPickupDate.date}T${keyPickupTime.start}`,'h:mmA');
    }

    dispatch(taskDefinition.actions.updateRequest(activeRequest.id, data))
      .then(resp => dispatch(taskDefinition.actions.submitRequest(get(resp,'requests[0].id'))))
      .then(nextScreen)
      .catch(error => Notify.error( get(error, 'response.data.message') ));
  };

  const handleCancel = () => {
    return dispatch(taskDefinition.actions.cancelRequest(activeRequest.id))
    .then(nextScreen)
    .catch(error => {
      Notify.error(format.error(error));
    });
  };


  const lastDate = get(availabilityCalendar[availabilityCalendar.length - 1],'date');

  return (
    <div className={taskCSS.holder}>
      <div className={taskCSS.task}>
        <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
        <div className={taskCSS.content}>
          <div className={taskCSS.folder} style={screen.maxWidth && {maxWidth: screen.maxWidth}}>

            <KeyPickupLocation request={activeRequest}></KeyPickupLocation>

            <section className={CSS.picker_section}>
              { activeAppointment && (
                <Notebox
                  className={CSS.keep_appointment}
                  heading={`Current appointment: ${activeAppointment.pickup_time || 'All day'}, ${format.date(activeAppointment.pickup_date, 'dddd M/DD')}`}
                  body={(
                    <p>If there is no better time available for reschedule, you can keep this appointment.</p>
                  )}
                  color='blue'
                  icon={{ library:'code', symbol:'Info-circle' }}
                  actions={(
                    <Button color='secondary' text='Keep this appointment' onClick={handleCancel} />
                  )}
                />
              )}
              {(daysList.length >= 1 && !(daysList.length === 1 && daysList[0].isDisabled)) ? (
                <div className={CSS.picker_container}>
                  {
                    calPending || isEmpty(calendar) || !activeRequest ? (
                      <AtomSpinner/>
                    ) : (
                      <>
                        <DayPicker days={daysList} onSelect={updateKeyPickupDate} />
                        { calendar && calendar.booking_type === 'appointment-slot' &&
                          <TimePicker times={timesList} onSelect={(time) => updateKeyPickupTime(time.slot)} />
                        }
                      </>
                    )
                  }
                </div>
              ) : (
                <Notebox
                  heading={(
                    <span className={CSS.no_dates_title}>
                      {lastDate
                        ? `Appointments currently available up until ${format.date(lastDate)}.`
                        : `There is currently no calendar configured for this property.`
                      }
                    </span>
                  )}
                  body={(
                    <p className={CSS.no_dates}>Please check back in a few days to schedule your upcoming appointment.</p>
                  )}
                  color={lastDate ? 'blue' : 'orange'}
                  icon={lastDate ? { symbol:'Info' } : { library:'code', symbol:'Warning-2' }}
                />
              )}
            </section>
          </div>
        </div>
        <div className={taskCSS.navigate}>
          <div className={taskCSS.navigate_next}>
            <button
              id={`${taskType}-form-submit`}
              type='submit'
              className={classNames('btn-primary', 'btn--full', {'loading': pendingSubmit})}
              disabled={isDisabled()}
              onClick={handleSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};
