import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_SUBMIT_VEHICLE_REQUEST',
  request: (id, data={}) => request.post(`/vehicle-registration-requests/${id}/submit`, data).then(r => r.data.data.vehicle_registration_task),
  reducers: {
    success: (state, { response: vehicleRegistration }) => {
      return {
        ...state,
          vehicleRegistrations: {
          ...state.vehicleRegistrations,
          [vehicleRegistration.id]: vehicleRegistration,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  usePending: useSubmitVehicleRequestPending,
} = generate.redux(creator);

export const initialState = {};
