import React from 'react';
import classNames from 'classnames';

import { ScrollItem } from '../HorizontalScroll';

import CSS from './ScrollMenuItem.module.scss';

export const ScrollMenuItem = ({
  itemId,
  onSelect,
  scrollOnSelect,
  isSelected,
  isDisabled,
  className,
  children,
}) => (
  <ScrollItem
    id={itemId}
    onSelect={onSelect}
    scrollOnSelect={scrollOnSelect}
    className={classNames(
      CSS.option,
      className,
      {
        [CSS.selected]: isSelected,
        [CSS.disabled]: isDisabled,
      }
    )}
  >
    <div className={CSS.option_content}>
      { children }
    </div>
  </ScrollItem>
);
