import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_UPDATE_DOC_REQUEST',
  request: (taskID, id, data={}) => request.post(`/document-submission-tasks/${taskID}/requests/${id}/files`, data).then(r => r.data.data.document_submission_task),
  reducers: {
    success: (state, { response: docSubmission }) => {
      return {
        ...state,
        docSubmissions: {
          ...state.docSubmissions,
          [docSubmission.id]: docSubmission,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useUpdateDocRequestPending,
} = generate.redux(creator);

export const initialState = {};
