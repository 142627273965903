import { base } from '../base';

import { getArtifactFields } from './getArtifactFields';
import { getArtifacts } from './getArtifacts';
import { getOverrideFields } from './getOverrideFields';
import { getOverrides } from './getOverrides';

import { getRequestFields } from './getRequestFields';

export const coi = {
  ...base,
  getArtifactFields,
  getArtifacts,
  getOverrideFields,
  getOverrides,
  getRequestFields,
};
