import { merge } from 'lodash';

import { utilitySetup as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';

export class UtilitySetup extends BaseTask {
  constructor() {
    super({
      screens: screens,
      actions: actions,
      selectors: {
        useTaskable: selectors.useUtilitySetup,
        ...selectors,
      },
    });
  }

  static label = 'Utility Setup';
  static slug = 'utility-setup';
  static icon = 'Fire';
  static helpers = helpers;
  static config = {
    supportsArtifacts: true,
  };

  _flowBuilder(taskData) {
    const enablePublicGridEmbed = taskData?.settings?.publicgrid_enabled;
    const activeRequest = this.helpers.getActiveRequest(taskData);
    const showGridrewardsFullscreen = (
      taskData?.settings?.gridrewards_enabled &&
      taskData?.settings?.gridrewards_fullscreen
    );
    if(taskData?.utility_category === 'Electric' && enablePublicGridEmbed) {
      return [
        this.screens['public-grid'],
        activeRequest?.public_grid_opt_in == null ? this.screens['mystery'] :
          activeRequest?.public_grid_opt_in === false && this.screens['account-details'],
        this.screens['summary'],
      ].filter(Boolean);
    } else {
      return [
        this.screens['select-provider'],
        this.screens['account-details'],
        showGridrewardsFullscreen && this.screens['gridrewards'],
        this.screens['summary'],
      ].filter(Boolean);
    }
  }

  getStatusCopy(request, taskDetails) {
    const defaultCopy = super.getStatusCopy(request);
    let requestCopy = {};
    switch(request.status) {
      case 'rejected':
        requestCopy = {
          summary: {
            flavor: 'Review the requested changes below and submit updated account information.',
          },
        };
        break;
      case 'pending-approval':
        requestCopy = {
          summary: {
            flavor: 'Property Management has been notified of your submitted account information. You will be notified when your submission is reviewed.',
          },
          history:{
            title: `Account information pending approval.`,
          },
        };
        break;
      case 'approved':
        requestCopy = {
          summary: {
            flavor: !taskDetails ?
              'Your account information has been taken care of.' :
              'The submitted account information has been reviewed and approved.',
          },
        };
        break;
      default:
        requestCopy = {};
    }
    return merge({},defaultCopy,requestCopy);
  }

  /* START OVERRIDES */
  getRecommendedScreen(taskData) {
    const activeRequest = this.helpers.getActiveRequest(taskData);
    // if already submitted show summary screen
    if(activeRequest && activeRequest?.status !== 'draft') return this._flow[this._flow.length-1];
    return super.getRecommendedScreen(taskData);
  }
  canAccessScreen(taskData, screenSlug) {
    // allow skipping to summary screen if has non-draft request
    const activeRequest = this.helpers.getActiveRequest(taskData);
    if(screenSlug === 'summary' && (activeRequest?.status ?? 'draft') !== 'draft') return true;
    return super.canAccessScreen(taskData, screenSlug);
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  /* END TASK SPECIFIC */

}
