/*
*  Opinionated styling for HorizontalScroll Component use in the application when selecting
*/

import React from 'react';
import classNames from 'classnames';

import { HorizontalScroll, ScrollNext, ScrollPrevious } from '../HorizontalScroll';
import { Icon } from '../../../sondheim/components/Icon';

import CSS from './ScrollMenu.module.scss';

const LeftArrow = () => (
  <ScrollPrevious className={classNames(CSS.arrow_holder, CSS.left)} disabledClassName={CSS.disabled}>
    <Icon symbol='Chevron-left' library='navigation' className={classNames(CSS.arrow, CSS.left)} />
  </ScrollPrevious>
);
const RightArrow = () => (
  <ScrollNext className={classNames(CSS.arrow_holder, CSS.right)} disabledClassName={CSS.disabled}>
    <Icon symbol='Chevron-right' library='navigation' className={classNames(CSS.arrow, CSS.right)} />
  </ScrollNext>
);

export const ScrollMenu = ({ scrollOnMount = false, separatorClassName, children }) => (
  <HorizontalScroll
    LeftArrow={LeftArrow}
    RightArrow={RightArrow}
    wrapperClassName={CSS.wrapper}
    scrollContainerClassName={CSS.container}
    itemClassName={CSS.item}
    separatorClassName={classNames(CSS.separator, separatorClassName)}
    scrollOnMount={scrollOnMount}
  >
    { children }
  </HorizontalScroll>
);
