import React, { createContext, useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DragAndDropItemContext = createContext();

export const useDragAndDropItem = () => useContext(DragAndDropItemContext);

export const DragAndDropItem = ({ id, index, className, activeClassName, children }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
        <div
          ref={innerRef}
          {...draggableProps}
          className={classNames(className,{ [activeClassName]: isDragging })}
        >
          <DragAndDropItemContext.Provider value={{ dragHandleProps, isDragging }}>
            { children }
          </DragAndDropItemContext.Provider>
        </div>
      )}
    </Draggable>
  );
}

DragAndDropItem.propTypes = {
  /** Unique identifier for this drop item (will be included in the dragEnd callback data) */
  id: PropTypes.string.isRequired,
  /** Required to pass in the index in the list of draggable items */
  index: PropTypes.number.isRequired,
  /** ClassName to be applied to the wrapper */
  className: PropTypes.string,
  /** ClassName to be applied to the wrapper if the item is currently being dragged */
  activeClassName: PropTypes.string,
  /** Drop area children (should include a flat list of drag items) */
  children: PropTypes.node,
};
