import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';

// Show a preview of a string without cutting off mid-word.
// Includes a CTA to toggle the complete text.
export const TruncateText = ({
  className,
  maxLength,
  text
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedText = useMemo(() => {
    if(text?.length <= maxLength) return text;
    const words = text?.trim().split(' ') ?? [];
    let textPreview = '';
    for (let i = 0; i < words.length; i++) {
      const str = [textPreview, words[i]].join(' ');
      if (str.length > maxLength) {
        textPreview += '...';
        break;
      }
      textPreview = str;
    }
    return textPreview;
  },[text, maxLength]);

  return (
    <p className={className}>
      {isExpanded ? text : truncatedText}
      {text?.length > maxLength && (
        <span
          className='contentAccent marginLeft-4'
          style={{ cursor: 'pointer' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </span>
      )}
    </p>
  );
};

TruncateText.propTypes = {
  /** (Optional) Customize the appearance of the text */
  className: PropTypes.string,
  /**
   * The max number of characters shown in the abbreviated state.
   * If the limit is exceeded mid-word, the word is omitted.
   * */
  maxLength: PropTypes.number.isRequired,
  /** The complete text to be rendered */
  text: PropTypes.string.isRequired,
};
