import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_CREATE_RENTER_INSURANCE_REQUEST',
  request: (id, data={}) => request.post(`/residents/renters-insurance-tasks/${id}/requests`, data).then(r => r.data.data.renters_insurance_task),
  reducers: {
    success: (state, { response: insuranceTask }) => {
      return {
        ...state,
        renterInsurances: {
          ...state.renterInsurances,
          [insuranceTask.id]: insuranceTask,
        }
      };
    }
  }
};

export const {
  action,
  reducer,
  usePending: useCreateRequestPending,
} = generate.redux(creator);
