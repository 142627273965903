import { base } from '../base';

import { getArtifacts } from './getArtifacts';
import { getArtifactFields } from './getArtifactFields';
import { getRequestFields } from './getRequestFields';

export const keys = {
  ...base,
  getArtifacts,
  getArtifactFields,
  getRequestFields,
};
