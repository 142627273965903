import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_PETSCREENING_VERIFICATION_TASK',
  request: (id) => request.get(`/residents/petscreening-verification-tasks/${id}`).then(r => r?.data?.data?.petscreening_verification_task),
  selector: (state, id) => state?.petscreeningVerificationTasks?.[id],
  reducers: {
    success: (state, { response: petscreeningVerificationTask }) => ({
      ...state,
      petscreeningVerificationTasks: {
        ...state.petscreeningVerificationTasks,
        [petscreeningVerificationTask.id]: petscreeningVerificationTask,
      },
    }),
  }
};

export const {
  action,
  reducer,
  useResource: usePetscreeningVerificationTask,
  usePending: usePetscreeningVerificationTaskPending,
} = generate.redux(creator);
