import * as create from './create';
import * as get from './get';
import * as complete from './complete';
import * as createRequest from './createRequest';
import * as submitRequest from './submitRequest';
import * as updateRequest from './updateRequest';


export const reducers = [
  create.reducer,
  get.reducer,
  complete.reducer,
  createRequest.reducer,
  submitRequest.reducer,
  updateRequest.reducer,
];

export const initialState = {
  orientation: {},
};
