import React from 'react';
import { get } from 'lodash';

import { LogoBlock } from '../LogoBlock';
import { PromoColumn } from './PromoColumn';

import CSS from './LayoutPromo.module.scss';

// Convenience component to map building branding values
export const LayoutBuildingPromo = ({ building={}, ...props }) => {
  const name = get(building, 'settings.display_name') || get(building,'name');
  return (
    <LayoutBrandedPromo
      name={name}
      logo={building.logo_url}
      splashBG={building.welcome_image_url}
      {...props}
    />
  );
}

// Convenience component to map partner branding values
export const LayoutPartnerPromo = ({ partner={}, ...props }) => {
  return (
    <LayoutBrandedPromo
      name={partner.name}
      logo={partner.logo_url}
      splashBG={partner.welcome_image_url}
      {...props}
    />
  );
};

// General component to manually specify branding values
export const LayoutBrandedPromo = ({ name, logo, splashBG, mobileHeader, children }) => {
  return (
    <div className={CSS.wrapper}>
      <PromoColumn name={name} logo={logo} splashBG={splashBG} />
      <section className={CSS.content_scroller}>
        <div className={CSS.content}>
          <div className={CSS.mobile_header}>
            <LogoBlock logo={logo} name={name} icon />
            { mobileHeader }
          </div>
          { children }
        </div>
      </section>
    </div>
  );
}

// Shared component that automatically uses the appropriate DRY components above
export const LayoutPromo = ({ partner, building, ...props }) => {
  return building ? <LayoutBuildingPromo building={building} {...props} /> :
    partner ? <LayoutPartnerPromo partner={partner} {...props} /> :
    <LayoutBrandedPromo {...props} />;
};
