import { isNil } from 'lodash';

import { getActiveRequest } from '../base/getActiveRequest';
import { getRequestFields } from './getRequestFields';

export const getSummaryFields = (taskable) => {
  if(!isNil(taskable.has_vehicles) && !taskable.has_vehicles) {
    return [
      {
        label: 'Do you have vehicles?',
        value: 'No',
        icon: {
          symbol: 'Car',
          library: 'vehicles',
        },
      },
    ];
  }
  const activeRequest = getActiveRequest(taskable);
  return getRequestFields(activeRequest);
};
