import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { useUser, userService } from '@moved/services';
import { Icon } from '@moved/ui';

import CSS from '../styles/ClaimAccount.module.scss';

export const ClaimAccountInvalid = ({ title, subtitle, building }) => {

  const { isAuthenticated, user } = useUser();
  const history = useHistory();

  const submit = () => {
    return isAuthenticated ? userService.redirect(user,history) : history.push(`/signin/${building.slug}`);
  };

  return (
    <>
      <div className={CSS.content}>
        <div className={CSS.flex_wrapper}>
          <h1 className={CSS.title}>{title}</h1>
          <h3 className={CSS.subtitle}>{subtitle}</h3>
        </div>
      </div>
      <div className={CSS.footer}>
        <label
          role="button"
          className={classNames(CSS.button)}
          tabIndex="0"
          onClick={submit}
        >
          { isAuthenticated ? 'My Dashboard' : 'Sign in' }
          <Icon symbol={'Arrow-right'} library={'navigation'} size={'24px'} color={'white'} className={CSS.arrow} />
        </label>
      </div>
    </>
  );

};
