import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_RENTER_INSURANCE',
  request: (id) => request.get(`/residents/renters-insurance-tasks/${id}`).then(r => r.data.data.renters_insurance_task),
  selector: (state, id) => get(state,`renterInsurances.${id}`),
  reducers: {
    success: (state, { response: insuranceTask }) => ({
      ...state,
      renterInsurances: {
        ...state.renterInsurances,
        [insuranceTask.id]: insuranceTask,
      }
    }),
  }
};

export const {
  action,
  reducer,
  useResource: useRenterInsurance,
  usePending: useRenterInsurancePending,
} = generate.redux(creator);

export const initialState = {
  renterInsurances: {},
};
