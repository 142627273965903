import React from 'react';
import PropTypes from 'prop-types';
import { Formik as FormikProvider, Form as FormikForm, useFormikContext } from 'formik';
import { useUpdateEffect, usePrevious } from 'react-use';
import { isEmpty } from 'lodash';

// Helper that has forkmik context and can watch for values changes and trigger the change event handler
const FormikEffect = ({ onChange }) => {
  const { values } = useFormikContext();
  const prevValues = usePrevious(values);

  // trigger the change handler function unless initial mount or initial definition of values
  useUpdateEffect(() => {
    if(!isEmpty(prevValues)) onChange(values);
  }, [values, onChange]);
  return null;
};

export const Form = ({
  name,
  initialValues={},
  validation,
  onSubmit,
  onChange,
  onReset,
  className,
  children,
}) => {
  return (
    <FormikProvider
      initialValues={initialValues}
      onSubmit={onSubmit}
      onReset={onReset}
      validationSchema={validation}
    >
      <FormikForm id={name} name={name} className={className}>
        { onChange && (<FormikEffect onChange={onChange} />)}
        { children }
      </FormikForm>
    </FormikProvider>
  );
}

Form.propTypes = {
  /** Name to use for the form */
  name: PropTypes.string.isRequired,
  /** Initial values to use for this form (name value pairs) */
  initialValues: PropTypes.object,
  /** Form validation schema (Yup) */
  validation: PropTypes.object,
  /** onSubmit handler function */
  onSubmit: PropTypes.func,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** onReset handler function */
  onReset: PropTypes.func,
  /** class name to add to the form */
  className: PropTypes.string,
  /** any children to render within this form */
  children: PropTypes.node,
};
