import { generate, request } from '@moved/services';

export const { action, reducer } = generate.redux({
  namespace: 'TASKS_UPDATE_UTILITY_SETUP_TASK',
  request: (id, data) => request.patch(`/utility-setup-tasks/${id}`, data).then(r => r.data.data.utility_setup_task),
  reducers: {
    success: (state, { response: utilitySetup }) => {
      return {
        ...state,
        utilitySetups: {
          ...state.utilitySetups,
          [utilitySetup.id]: utilitySetup,
        },
      }
    }
  }
});
