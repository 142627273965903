import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CORE_GET_SNIPPETS',
  request: (id, params={}) => request.get(`/buildings/${id}/snippets`, { params, }).then(r => r.data.data.snippets),
  selector: (state) => {
    const { snippets } = state;
    return snippets;
  },
  reducers: {
    success: (state, { response: snippets, params: [id] }) => ({
      ...state,
      snippets: {
        ...snippets
      },
    }),
  },
};

export const {
  action,
  reducer,
  useResource: useSnippets,
  usePending: useSnippetsPending,
} = generate.redux(creator);

export const initialState = {
  snippets: {},
};
