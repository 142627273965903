import moment from 'moment';
import { bookMovers as helpers } from '@moved/product';

import { BaseTask } from '../shared/components/BaseTask';

import * as actions from './actions';
import * as selectors from './actions/selectors';
import { screens } from './screens';
import { routes } from './routes';

export class BookMovers extends BaseTask {
  constructor(overrides) {
    super({
      routes: [ ...routes, ...(overrides?.routes ?? []) ],
      screens: { ...screens, ...(overrides?.screens ?? {}) },
      actions: { ...actions, ...(overrides?.actions ?? {}) },
      selectors: {
        ...selectors,
        useTaskable: selectors.useMoverBooking,
        ...(overrides?.selectors ?? {})
      },
    });
    this.isStorage = false;
  }

  static label = 'Confirm your moving plan';
  static slug = 'book-movers';
  static icon = 'Loader';
  static helpers = helpers;

  _flowBuilder(moverBooking, moverBookingTask) {
    const enablePBLFlow = moverBookingTask?.settings?.anonymous_ads_enabled;
    if(enablePBLFlow) {
      // unknown geography
      if((moverBooking?.stops ?? []).length < 2) return [
        this.screens['addresses'],
        this.screens['mystery'],
        this.screens['confirmation'],
      ].filter(v=>v);

      // full long distance flow
      if(moverBooking?.long_distance) return [
        this.screens['addresses'],
        this.screens['date'],
        this.screens['long-distance'],
        this.isBooked(moverBooking) ?
          this.screens['confirmation-book-movers'] :
          moverBookingTask?.completed_at ?
            this.screens['confirmation-user-provided'] : // this should only be for moving-plan
            this.screens['confirmation-long-distance'],
      ].filter(v=>v);

      // full non-core-geo flow
      if(moverBooking.eligible_for_rates === false) return [
        this.screens['addresses'],
        this.screens['confirmation-non-core-geo'],
      ];

      // full local move flow (provide vendor lead)
      return [
        this.screens['addresses'],
        this.screens['moving-company-preference'],
        moverBookingTask?.selected_moving_company_ads?.length ?
          this.screens['confirmation-vendor-lead'] : // this should only be for moving-plan
          this.screens['confirmation-user-provided'],
      ];

    }
    else {
      // unknown geography
      if((moverBooking?.stops ?? []).length < 2) return [
        this.screens['date'],
        this.screens['stops'],
        this.screens['mystery'],
        this.screens['confirmation'],
      ].filter(v=>v);

      // full long distance flow
      if(moverBooking?.long_distance) return [
        this.screens['date'],
        this.screens['stops'],
        this.screens['long-distance'],
        this.isBooked(moverBooking) ?
          this.screens['confirmation-book-movers'] :
          moverBookingTask?.completed_at ?
            this.screens['confirmation-user-provided'] :
            this.screens['confirmation-long-distance'],
      ].filter(v=>v);

      // full non-core-geo flow
      if(moverBooking.eligible_for_rates === false) return [
        this.screens['date'],
        this.screens['stops'],
        this.screens['confirmation-non-core-geo'],
      ];

      // full local move flow (hourly rate quotes)
      return [
        this.screens['date'],
        // TODO: instead of time preference, include the elevator reservation if scheduled in the job offer
        this.screens['stops'],
        !this.isStorage && this.screens['move-size'],
        this.screens['special'],
        this.screens['moving-quotes'],
        this.screens['moving-summary'],
        this.screens['agreements'],
        this.screens['moving-payment'],
        this.screens['confirmation-book-movers'],
      ].filter(Boolean);
    }
  }

  /* START OVERRIDES */
  getRecommendedScreen(taskData) {
    // if booked show confirmation screen
    const moverBooking = this.getMoverBooking(taskData);
    if(this.isBooked(moverBooking)) return this.flatFlow[this.flatFlow.length-1];
    return super.getRecommendedScreen(taskData);
  }

  canAccessScreen(taskData, slug) {
    // allow skipping to confirmation screen if booked manually (even if skipping required screens)
    const moverBooking = this.getMoverBooking(taskData);
    if(slug === 'confirmation' && this.isBooked(moverBooking)) return true;
    return super.canAccessScreen(taskData, slug);
  }
  /* END OVERRIDES */

  /* TASK SPECIFIC */
  // These seemingly useless function allowss MovingPlan task to extend and define its own getters and setters
  // and have a shared interface with this task that works for both
  getMoverBooking(moverBooking) {
    return moverBooking;
  }
  updateMoverBookingForTask(moverBooking) {
    return moverBooking;
  }

  isBooked(moverBooking) {
    return moverBooking?.booked_service_order != null;
  }

  canBookDate(date) {
    const deadlineToBook = moment(date).subtract(1,'d').set({h:14});
    return moment().isBefore(deadlineToBook);
  }

  isCancellationFree(moverBooking) {
    const moveDate = moverBooking?.requested_pickup_date;
    const lastCancellationDate = moveDate ? moment(moveDate).subtract(3,'days') : false;
    return lastCancellationDate ? moment().isSameOrBefore(lastCancellationDate,'day') : false;
  }

  static getMoverBookingStatus(moverBooking) {
    if(!moverBooking) return 'not-started';
    if(
      moverBooking?.booked_transaction || // complete and booked
      ((moverBooking?.stops ?? []).length > 1 && !moverBooking?.eligible_for_rates) || // complete and outside geography
      ((moverBooking?.stops ?? []).length > 1 && moverBooking?.long_distance && moverBooking?.contact_email) // complete and long distance
    ) return 'complete';
    else return 'in-progress';
  };
  /* END TASK SPECIFIC */

}
