import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'TASKS_GET_SECURITY_DEPOSIT',
  request: (id) => request.get(`/security-deposits/${id}`).then(r => r.data.data.security_deposit),
  selector: (state, id) => get(state,`securityDeposits.${id}`),
  reducers: {
    success: (state, { response: securityDeposit }) => {
      return {
        ...state,
        securityDeposits: {
          ...state.securityDeposits,
          [securityDeposit.id]: securityDeposit,
        },
      }
    }
  }
};

export const {
  action,
  reducer,
  useResource: useSecurityDeposit,
  usePending: useSecurityDepositPending,
} = generate.redux(creator);
