import React from 'react';
import classNames from 'classnames';

import { LogoBlock } from '../LogoBlock';

import CSS from './LayoutPromo.module.scss';

// General component to manually specify branding values
export const PromoColumn = ({ name, logo, splashBG, className }) => {
  const welcome_image_url = splashBG || `${process.env.REACT_APP_S3_ASSET_URL}/partners/default/splash-portrait.jpg`; // the fallback here is for consumer app
  return (
    <section
      className={classNames(CSS.promo,className)}
      style={{backgroundImage: `url(${welcome_image_url})`}}
    >
      <LogoBlock logo={logo} name={name} invert />
    </section>
  );
}
