import { generate, request } from '@moved/services';

const creator = {
  namespace: 'TASKS_GET_MOVER_BOOKING_TASK',
  request: (id) => request.get(`/mover-booking-tasks/${id}`).then(r => r?.data?.data?.mover_booking_task),
  selector: (state, id) => state?.moverBookingTasks?.[id],
  reducers: {
    success: (state, { response }) => {
      const { mover_booking, ...bookingTask } = response;
      return {
        ...state,
        moverBookingTasks: {
          ...state.moverBookingTasks,
          [bookingTask.id]: {
            ...bookingTask,
            mover_booking_id: mover_booking?.id,
          },
        },
        moverBookings: {
          ...state.moverBookings,
          ...(mover_booking ? { [mover_booking?.id]: mover_booking } : {}),
        }
      };
    },
  },
};

export const {
  action,
  reducer,
  useResource: useMoverBookingTask,
  usePending: useMoverBookingTaskPending,
} = generate.redux(creator);

export const initialState = {
  moverBookingTasks: {},
};
