import React from 'react';
import classNames from 'classnames';
import { get, merge } from 'lodash';

import { tools } from './tools/config';
import { renderers } from './tools/renderers';

import CSS from './ContentEditor.module.scss';

const ErrorBlock = (block) => (
  <h3>Unsupported Block Type: {get(block,'type','unspecified')}</h3>
);

const ContentBlock = ({ block, tags }) => {
  const tool = tools()[block.type];
  const BlockDisplay = renderers[block.type];
  return (
    <div className={classNames(CSS.block,'ce-block')}>
      <div className="ce-block__content">
        { (tool && BlockDisplay) ? <BlockDisplay data={block.data} tool={tool} tags={tags} /> : <ErrorBlock block={block}/> }
      </div>
    </div>
  );
};

export const ContentRenderer = ({ content, className, tags={} }) => {
  const contentTags = { content_editor_id: get(content,'id') };
  return (
    <div className={classNames(CSS.renderer, className)}>
      { get(content,'blocks',[]).map((block,index) => (
        <ContentBlock block={block} tags={merge({},contentTags,tags)} key={block.id || index} />
      ))}
    </div>
  );
};
